import React from 'react';
import { Box, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

interface ElegantDividerProps {
  color?: string;
}

export const ElegantDivider: React.FC<ElegantDividerProps> = ({ color }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        height: '120px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <motion.div
        initial={{ width: 0 }}
        whileInView={{ width: '100%' }}
        viewport={{ once: true }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        style={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '1px',
            background: `linear-gradient(90deg, 
              transparent 0%, 
              ${color || theme.palette.primary.main} 20%, 
              ${color || theme.palette.primary.main} 80%, 
              transparent 100%
            )`,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            border: `2px solid ${color || theme.palette.primary.main}`,
            backgroundColor: 'background.default',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&::before': {
              content: '""',
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: color || theme.palette.primary.main,
            }
          }}
        />
      </motion.div>
    </Box>
  );
}; 