import React from 'react';
import { Box, Container, Typography, IconButton, useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLanguage } from '../../contexts/LanguageContext';
import { privacyContent } from './PrivacyContent';

const Privacy: React.FC = () => {
  const { language } = useLanguage();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleBack = () => {
    window.location.href = '/';
  };

  const isArabicPath = window.location.pathname.includes('privacy-ar');
  const content = isArabicPath ? privacyContent[language].ar : privacyContent[language].en;

  return (
    <Box sx={{ 
      minHeight: '100vh',
      backgroundColor: 'background.default',
      direction: isArabicPath ? 'rtl' : 'ltr',
      position: 'relative',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'radial-gradient(circle at 20% 20%, rgba(255, 75, 145, 0.1) 0%, rgba(108, 34, 166, 0.1) 100%)',
        opacity: 0.5,
        zIndex: 0,
      }
    }}>
      <Container 
        maxWidth="md" 
        sx={{ 
          py: 4,
          position: 'relative',
          zIndex: 1,
        }}
      >
        <IconButton 
          onClick={handleBack}
          sx={{ 
            mb: 2,
            transform: isArabicPath ? 'rotate(180deg)' : 'none',
            color: 'primary.main',
            '&:hover': {
              backgroundColor: 'rgba(255, 75, 145, 0.1)',
            }
          }}
          aria-label="back"
        >
          <ArrowBackIcon />
        </IconButton>
        
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom 
          align="center" 
          sx={{ 
            mb: 4,
            fontSize: isMobile ? '1.5rem' : '2rem',
            fontWeight: 600,
            color: 'primary.main',
            fontFamily: isArabicPath ? '"Noto Kufi Arabic", sans-serif' : 'Poppins, sans-serif',
          }}
        >
          {isArabicPath ? 'سياسة الخصوصية' : 'Privacy Policy'}
        </Typography>
        
        <Box sx={{ 
          maxHeight: 'calc(100vh - 200px)', 
          overflowY: 'auto',
          px: { xs: 2, sm: 3 },
          py: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: 2,
          boxShadow: '0 4px 24px rgba(0, 0, 0, 0.06)',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255, 75, 145, 0.2)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(0,0,0,.05)',
            borderRadius: '4px',
          }
        }}>
          <Typography
            variant="body1"
            component="div"
            sx={{
              whiteSpace: 'pre-wrap',
              textAlign: isArabicPath ? 'right' : 'left',
              fontFamily: isArabicPath ? '"Noto Kufi Arabic", sans-serif' : 'Poppins, sans-serif',
              '& h1, & h2, & h3': {
                fontWeight: 600,
                mt: 3,
                mb: 2,
                color: 'primary.main',
                fontFamily: 'inherit',
              },
              '& p': {
                mb: 2,
                lineHeight: 1.7,
              },
              fontSize: isMobile ? '0.875rem' : '1rem',
            }}
          >
            {content.split('\n').map((line: string, index: number) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Privacy; 