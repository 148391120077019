export const termsContentAr = `الشروط والأحكام
آخر تحديث: 14/01/2025

1. المقدمة
مرحبًا بك في Mahra، التي تديرها شركة Mahra.io (والمشار إليها في ما يلي بـ"نحن" أو "لدينا" أو "الشركة")، وهي شركة مقرها المملكة المتحدة. تحكم هذه الشروط والأحكام ("الشروط والأحكام" أو "الاتفاقية") وصولك إلى خدمة التعارف بالذكاء الاصطناعي ("الخدمة") واستخدامها.
باستخدامك لخدمة Mahra، فإنك تقرّ بأنك قرأت وفهمت وتوافق على الالتزام بهذه الشروط والأحكام. وفي حال عدم موافقتك، يتعيّن عليك عدم استخدام الخدمة.

2. الأهلية
الحد الأدنى للعمر: يجب أن تكون قد أكملت 18 عامًا على الأقل (أو بلغت سن الرشد في بلدك) لاستخدام Mahra. وبإنشائك لحساب، فإنك تضمن أنك تستوفي شرط العمر المذكور.
سلامة المعلومات: تتعهّد بتقديم معلومات صحيحة وحديثة وكاملة أثناء عملية التسجيل. كما تتحمّل المسؤولية الكاملة عن الحفاظ على سرية بيانات تسجيل الدخول الخاصة بك وأي أنشطة تتم عبر حسابك.
3. وصف الخدمة
تعتمد Mahra على تقنيات الذكاء الاصطناعي لتسهيل التعارف بين الأفراد الباحثين عن علاقات رومانسية أو نية للزواج. لا نقدّم أي ضمان بشأن دقة أو فائدة التوافقات التي يقترحها الذكاء الاصطناعي. قد تختلف النتائج من شخصٍ لآخر ولا نضمن تحقيق نتيجة محددة.

4. سلوك المستخدم
الاستخدام المشروع: تتعهّد باستخدام Mahra لأغراض مشروعة فقط ووفقًا لجميع القوانين واللوائح المحلية والدولية المعمول بها.
سلوك محظور: يُحظر عليك القيام بما يلي:
نشر أو توزيع أي محتوى فيه تحرّش أو تشهير أو إساءة أو فُحش أو تهديد أو عنصرية أو أي محتوى مرفوض.
انتحال شخصية أي فرد أو هيئة أو تقديم معلومات مغلوطة حول هويتك أو صفتك.
جمع أو تخزين بيانات شخصية لمستخدمين آخرين دون موافقتهم الصريحة.
استخدام الخدمة لأغراض تجارية غير مصرّح بها أو في عمليات احتيالية.
تحميل أو نقل فيروسات أو أي شيفرات ضارة أو القيام بأي فعل من شأنه تعطيل أمان الخدمة أو أدائها.
5. محتوى المستخدم والتفاعلات
مسؤولية المحتوى: أنت المسؤول الوحيد عن المحتوى الذي تقدّمه، بما يشمل الرسائل والتفاصيل الشخصية والصور أو غيرها من المواد ("محتوى المستخدم"). ولا تتحمّل Mahra.io أدنى مسؤولية عن محتوى المستخدم.
التفاعلات خارج المنصة: يقتصر دور Mahra على تسهيل فرص التعارف فحسب؛ أنت المسؤول الوحيد عن أي تواصل أو لقاءات مع المستخدمين الآخرين، سواء عبر الإنترنت أو على أرض الواقع. لا تقوم Mahra.io بالتحقق من خلفيات المستخدمين أو التحقّق من هوياتهم، وتُخلي مسؤوليتها من أي تجاوزات أو نزاعات تنشأ بين المستخدمين.
6. الملكية الفكرية
محتوى الخدمة: جميع المحتويات والميزات والوظائف الخاصة بـMahra (باستثناء محتوى المستخدم) مملوكة لشركة Mahra.io ومحمية بقوانين حقوق الملكية الفكرية في المملكة المتحدة والمعاهدات الدولية.
الترخيص: وفقًا لالتزامك بهذه الشروط والأحكام، تمنحك Mahra.io ترخيصًا محدودًا وغير حصري وقابلاً للإلغاء لاستخدام الخدمة لأغراض شخصية وغير تجارية.
7. الخصوصية وحماية البيانات
يرجى الاطلاع على سياسة الخصوصية لمعرفة كيفية جمع بياناتك الشخصية واستخدامها وحمايتها. إنّ استخدامك لخدمة Mahra يعني موافقتك على ممارساتنا المتعلقة بالبيانات المبيّنة في سياسة الخصوصية.

8. الدفعات والاشتراكات (إن وجدت)
الرسوم: قد تتطلب بعض ميزات Mahra رسوم اشتراك أو دفعات لمرة واحدة ("الخدمات المدفوعة"). بمجرّد اشتراكك، فإنك توافق على دفع جميع الرسوم المعلنة عند التسجيل أو الشراء.
الاسترداد: باستثناء الحالات التي يفرضها القانون البريطاني، فإن جميع الدفعات غير قابلة للاسترداد. ونحتفظ بحق تعديل الأسعار أو شروط الاشتراك في أي وقت دون إشعار مسبق.
9. إخلاء المسؤولية عن الضمانات
على حالها: تُقدَّم خدمة Mahra "كما هي" و"حسب توافرها" بدون أي ضمانات صريحة أو ضمنية. تخلي Mahra.io مسؤوليتها عن أي ضمانات ضمنية تتعلق بقابلية التسويق أو الملاءمة لغرض معيّن أو عدم انتهاك الحقوق.
سلوك المستخدم: لا تضمن Mahra.io سلوك أي مستخدم في الخدمة. أنت تتحمّل وحدك المخاطر المرتبطة باستخدام الخدمة والتواصل مع الآخرين.
عدم ضمان النتائج: لا تضمن Mahra.io تحقيق أي نتيجة محددة فيما يتعلق بالعلاقات العاطفية أو الخطوبة أو الزواج.
10. حدود المسؤولية
إلى الحد الذي يسمح به القانون، تخلي Mahra.io ومسؤولوها وموظفوها ووكلاؤها مسؤوليتهم عن أي أضرار غير مباشرة أو عارضة أو خاصة أو تبعية أو تأديبية تنشأ عن استخدامك لخدمة Mahra. يشمل ذلك – دون حصر – أي إصابة شخصية أو ضيق نفسي أو خسارة أرباح أو غيرها من الأضرار التي قد تنشأ نتيجة تفاعلك مع المستخدمين الآخرين.

11. التعويض
أنت توافق على تعويض Mahra.io ومسؤوليها وموظفيها ووكلائها عن أي مطالبات أو التزامات أو خسائر أو نفقات (بما في ذلك أتعاب المحاماة) التي تنشأ عن:

استخدامك لخدمة Mahra.
خرقك لهذه الشروط والأحكام.
انتهاكك لأي قانون أو حقوق طرف ثالث.
أي محتوى مستخدم تقدمه.
12. الروابط والخدمات الخارجية
قد تشتمل Mahra على روابط لمواقع أو خدمات تابعة لأطراف ثالثة. لا تتحمل Mahra.io مسؤولية تلك المواقع أو الخدمات أو محتوياتها. إنّ وصولك لهذه الروابط يتم على مسؤوليتك الخاصة.

13. التعديل والإيقاف
يجوز لـMahra.io تعديل أي جزء من الخدمة أو إيقافه في أي وقت دون إشعار مسبق. كما نحتفظ بالحق في تعديل أو استبدال أي جزء من هذه الشروط والأحكام، وسيتم نشر النسخة المحدثة على منصة Mahra. يُعتبر استمرار استخدامك للخدمة موافقةً منك على أي تعديلات.

14. القانون الحاكم والاختصاص القضائي
تخضع هذه الشروط والأحكام، وكذلك أي نزاع أو مطالبة تنشأ عنها أو تتعلق باستخدام Mahra، لقوانين إنجلترا وويلز. وتختص محاكم إنجلترا وويلز حصريًا بالبت في أي نزاع ينشأ عن هذه الشروط والأحكام أو يتعلق بها.

15. التواصل معنا
في حال وجود أي استفسارات أو ملاحظات حول هذه الشروط والأحكام، يرجى التواصل معنا عبر:
البريد الإلكتروني: [support@mahra.io]
العنوان: Mahra.io, UK

باستخدامك لخدمة Mahra أو بإنشائك حسابًا، فإنك تقرّ بأنك قرأت هذه الشروط والأحكام وفهمتها وتوافق على الالتزام بها.`; 