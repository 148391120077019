import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Box, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLanguage } from '../../contexts/LanguageContext';
import { motion, AnimatePresence } from 'framer-motion';

interface ScrollableFeaturesProps {
  children: React.ReactNode;
}

export const ScrollableFeatures: React.FC<ScrollableFeaturesProps> = ({ children }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { language } = useLanguage();
  const [canScrollForward, setCanScrollForward] = useState(true);
  const [canScrollBack, setCanScrollBack] = useState(false);

  const checkScroll = useCallback(() => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      const isRTL = language === 'ar';
      
      if (isRTL) {
        setCanScrollForward(Math.abs(scrollLeft) < scrollWidth - clientWidth - 10);
        setCanScrollBack(Math.abs(scrollLeft) > 10);
      } else {
        setCanScrollForward(scrollLeft < scrollWidth - clientWidth - 10);
        setCanScrollBack(scrollLeft > 10);
      }
    }
  }, [language]);

  useEffect(() => {
    checkScroll();
  }, [language, checkScroll]);

  const scroll = (direction: 'forward' | 'back') => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      const isRTL = language === 'ar';
      
      const scrollValue = direction === 'forward' ? scrollAmount : -scrollAmount;
      const actualScroll = isRTL ? -scrollValue : scrollValue;

      scrollContainerRef.current.scrollBy({
        left: actualScroll,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <AnimatePresence>
        {canScrollBack && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <IconButton
              onClick={() => scroll('back')}
              sx={{
                position: 'absolute',
                [language === 'ar' ? 'right' : 'left']: { xs: -16, md: -20 },
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 2,
                backgroundColor: 'background.paper',
                boxShadow: 2,
                '&:hover': {
                  backgroundColor: 'background.paper',
                },
              }}
            >
              {language === 'ar' ? <ArrowForwardIcon /> : <ArrowBackIcon />}
            </IconButton>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {canScrollForward && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <IconButton
              onClick={() => scroll('forward')}
              sx={{
                position: 'absolute',
                [language === 'ar' ? 'left' : 'right']: { xs: -16, md: -20 },
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 2,
                backgroundColor: 'background.paper',
                boxShadow: 2,
                '&:hover': {
                  backgroundColor: 'background.paper',
                },
              }}
            >
              {language === 'ar' ? <ArrowBackIcon /> : <ArrowForwardIcon />}
            </IconButton>
          </motion.div>
        )}
      </AnimatePresence>

      <Box
        ref={scrollContainerRef}
        onScroll={checkScroll}
        sx={{
          display: 'flex',
          gap: 3,
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          direction: language === 'ar' ? 'rtl' : 'ltr',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          scrollbarWidth: 'none',
          px: 1,
          py: 2,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}; 