import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';

interface WaveSeparatorProps {
  position: 'top' | 'bottom';
  backgroundColor: string;
  foregroundColor: string;
  pattern?: 'wave' | 'curve' | 'triangle';
  height?: number;
  opacity?: number;
  flip?: boolean;
}

export const WaveSeparator: React.FC<WaveSeparatorProps> = ({
  position,
  backgroundColor,
  foregroundColor,
  pattern = 'wave',
  height = 80,
  opacity = 1,
  flip = false
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const actualHeight = isMobile ? height * 0.6 : height;

  const getPath = () => {
    switch (pattern) {
      case 'curve':
        return position === 'top' 
          ? "M0,64L48,69.3C96,75,192,85,288,90.7C384,96,480,96,576,90.7C672,85,768,75,864,69.3C960,64,1056,64,1152,69.3C1248,75,1344,85,1392,90.7L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          : "M0,0L48,5.3C96,11,192,21,288,26.7C384,32,480,32,576,26.7C672,21,768,11,864,5.3C960,0,1056,0,1152,5.3C1248,11,1344,21,1392,26.7L1440,32L1440,64L1392,64C1344,64,1248,64,1152,64C1056,64,960,64,864,64C768,64,672,64,576,64C480,64,384,64,288,64C192,64,96,64,48,64L0,64Z";
      case 'triangle':
        return position === 'top'
          ? "M0,0L48,5.3C96,11,192,21,288,21.3C384,21,480,11,576,16C672,21,768,43,864,48C960,53,1056,43,1152,37.3C1248,32,1344,32,1392,32L1440,32L1440,64L0,64Z"
          : "M0,0L48,5.3C96,11,192,21,288,21.3C384,21,480,11,576,16C672,21,768,43,864,48C960,53,1056,43,1152,37.3C1248,32,1344,32,1392,32L1440,32L1440,64L0,64Z";
      default:
        return position === 'top'
          ? "M0,32L34.3,37.3C68.6,43,137,53,206,53.3C274.3,53,343,43,411,37.3C480,32,549,32,617,37.3C685.7,43,754,53,823,53.3C891.4,53,960,43,1029,37.3C1097.1,32,1166,32,1234,37.3C1302.9,43,1371,53,1406,53.3L1440,53L1440,0L0,0Z"
          : "M0,0L34.3,5.3C68.6,11,137,21,206,21.3C274.3,21,343,11,411,16C480,21,549,43,617,48C685.7,53,754,43,823,37.3C891.4,32,960,32,1029,37.3C1097.1,43,1166,53,1234,53.3C1302.9,53,1371,43,1406,37.3L1440,32L1440,64L0,64Z";
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        [position]: 0,
        left: 0,
        right: 0,
        height: `${actualHeight}px`,
        overflow: 'hidden',
        transform: `${position === 'top' ? 'translateY(-1px)' : 'translateY(1px)'} ${flip ? 'scaleX(-1)' : ''}`,
        backgroundColor: 'transparent',
      }}
    >
      <svg
        viewBox="0 0 1440 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          [position]: 0,
        }}
      >
        <path
          d={getPath()}
          fill={foregroundColor}
          fillOpacity={opacity}
        />
        <path
          d={getPath()}
          fill={backgroundColor}
          fillOpacity={opacity * 0.3}
          style={{
            transform: 'translateY(2px) scale(1.05, 1.1)',
          }}
        />
      </svg>
    </Box>
  );
}; 