export const cookiesContentAr = `سياسة ملفات تعريف الارتباط (الكوكيز)
آخر تحديث: 14/01/2025

1. المقدمة
تستخدم Mahra، التي تديرها شركة Mahra.io بالمملكة المتحدة، ملفات تعريف الارتباط ("الكوكيز") وتقنيات مشابهة لتحسين تجربة المستخدم وتحليل الأداء وتقديم محتوى مخصص. توضح هذه السياسة كيفية استخدامنا للكوكيز وسبب ذلك، إضافةً إلى كيفية إدارتها من قبلك.

2. ما هي الكوكيز؟
الكوكيز هي ملفات نصية صغيرة تُخزّن على جهازك عندما تزور مواقع ويب معينة. نستخدم أيضًا تقنيات أخرى مثل البكسل وإشارات الويب ومعرّفات الأجهزة المحمولة لجمع البيانات التحليلية والارتقاء بخدماتنا.

3. أنواع الكوكيز التي نستخدمها
الكوكيز الضرورية: ضرورية لتشغيل Mahra الأساسي (مثل تذكر حالة تسجيل الدخول).
كوكيز الأداء والتحليلات: تساعدنا على فهم سلوك المستخدمين وتطوير ميزات الخدمة.
كوكيز الوظائف: تذكر تفضيلاتك مثل اللغة أو المنطقة لتقديم تجربة مخصصة.
كوكيز الإعلانات/الاستهداف (إن وُجدت): تراقب أنشطة التصفح لتقديم إعلانات أو محتوى يناسب اهتماماتك.
4. كيفية استخدامنا للكوكيز
المصادقة: للحفاظ على تسجيل دخولك وضمان أمان جلستك.
التخصيص: لتخزين تفضيلاتك وتقديم تجربة مستخدم مخصصة.
التحليلات: لتحليل سلوك المستخدمين وقياس فعالية الميزات الجديدة أو الحملات التسويقية.
الإعلانات (إن وُجدت): لعرض إعلانات ذات صلة باهتماماتك وتقييم أدائها.
5. ملفات تعريف الارتباط الخاصة بالأطراف الثالثة
قد نسمح لمقدّمي خدمات موثوق بهم بوضع ملفات تعريف الارتباط في خدماتنا لأغراض مثل التحليل أو الإعلانات. تخضع بياناتهم وسياساتهم الخاصة لمعالجات منفصلة، ونشجعك على مراجعة سياسات الخصوصية الخاصة بهم.

6. إدارة الكوكيز
تسمح معظم متصفحات الويب بالتحكّم في إعدادات الكوكيز أو إيقافها. ومع ذلك، قد يؤثر ذلك سلبًا على بعض ميزات Mahra وقدرتك على الاستفادة الكاملة من الخدمة.

7. تعديلات على سياسة الكوكيز
قد نقوم بتحديث هذه السياسة من وقت لآخر لتعكس التغييرات في ممارساتنا أو لأسباب قانونية. سنعلن عن السياسة الجديدة على Mahra، ويُعد استمرارك في استخدام الخدمة بعد التعديلات موافقةً منك على السياسة المحدثة.

8. التواصل معنا
إذا كانت لديك أي أسئلة حول كيفية استخدامنا للكوكيز، يُرجى التواصل معنا عبر:
البريد الإلكتروني: [support@mahra.io]
العنوان: Mahra.io, UK

إخلاء المسؤولية
هذه الوثائق (الشروط والأحكام، سياسة الخصوصية، وسياسة ملفات تعريف الارتباط) مُقدَّمة للأغراض المعلوماتية فقط ولا تُعَدّ استشارة قانونية. لا تقدّم Mahra.io أي ضمان حول الامتثال القانوني الكامل. يجب استشارة محامٍ مختص للتحقق من توافق منصتكم مع جميع المتطلبات القانونية المعمول بها.
`; 