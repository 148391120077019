import React, { useState, useEffect } from 'react';
import { Box, IconButton, Stack, Typography, Button, CircularProgress, Slider, Switch, Chip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ChatIcon from '@mui/icons-material/Chat';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { useLanguage } from '../../contexts/LanguageContext';
import { motion, AnimatePresence } from 'framer-motion';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import TuneIcon from '@mui/icons-material/Tune';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EditIcon from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface ScreenContent {
  id: string;
  content: React.ReactNode;
}

interface PreferenceItem {
  enabled: boolean;
  label: string;
}

interface Preferences {
  personality: PreferenceItem;
  values: PreferenceItem;
  lifestyle: PreferenceItem;
  interests: PreferenceItem;
}

const InteractiveHint = () => {
  const { language } = useLanguage();
  const [isVisible, setIsVisible] = useState(true);

  // Use layout effect to prevent flickering
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 6000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
          style={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
            zIndex: 20,
            transform: 'translateZ(0)', // Force GPU acceleration
            backfaceVisibility: 'hidden', // Prevent flickering
          }}
        >
          {/* Main Tap Animation */}
          <motion.div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
              transform: 'translateZ(0)', // Force GPU acceleration
            }}
          >
            <motion.div
              initial={false}
              animate={{
                y: [-10, 0, -10],
                scale: [1, 1.02, 1],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
                repeatType: "reverse"
              }}
              style={{
                willChange: 'transform',
                transform: 'translateZ(0)',
              }}
            >
              <TouchAppIcon 
                sx={{ 
                  fontSize: '3.5rem',
                  color: 'rgba(255,75,145,0.8)',
                  filter: 'drop-shadow(0 4px 8px rgba(255,75,145,0.3))',
                }} 
              />
            </motion.div>

            <Typography
              sx={{
                fontSize: '1.4rem',
                fontWeight: 700,
                background: 'linear-gradient(135deg, #FF4B91 0%, #FF6B91 100%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textShadow: '0 4px 8px rgba(255,75,145,0.2)',
                textAlign: 'center',
                transform: 'translateZ(0)',
              }}
            >
              {language === 'ar' ? 'انقر للاستكشاف' : 'Tap to Explore'}
            </Typography>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const LoadingAnimation = () => (
  <Box sx={{ 
    position: 'absolute',
    inset: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(135deg, rgba(255,75,145,0.05), rgba(255,107,145,0.02))',
    overflow: 'hidden',
  }}>
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
    >
      <Box sx={{ position: 'relative', mb: 3 }}>
        {/* Outer ring animation */}
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
          style={{
            position: 'absolute',
            inset: -8,
            borderRadius: '50%',
            border: '2px solid rgba(255,75,145,0.1)',
            borderTopColor: '#FF4B91',
          }}
        />
        
        <CircularProgress 
          size={60} 
          thickness={4} 
          sx={{ color: '#FF4B91' }} 
        />
        
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <motion.div
            animate={{ scale: [1, 1.2, 1] }}
            transition={{ duration: 1.5, repeat: Infinity }}
          >
            <AutoAwesomeIcon sx={{ color: '#FF4B91', fontSize: '1.4rem' }} />
          </motion.div>
        </Box>
      </Box>

      <motion.div
        animate={{ opacity: [0.5, 1, 0.5] }}
        transition={{ duration: 1.5, repeat: Infinity }}
      >
        <Typography sx={{ 
          fontSize: '0.9rem',
          color: '#FF4B91',
          textAlign: 'center',
          fontWeight: 500,
        }}>
          AI is finding your perfect match...
        </Typography>
        <Typography sx={{ 
          fontSize: '0.75rem',
          color: 'text.secondary',
          textAlign: 'center',
          mt: 0.5,
        }}>
          Analyzing preferences and compatibility
        </Typography>
      </motion.div>
    </motion.div>
  </Box>
);

const PreviewLabel = () => {
  const { language } = useLanguage();
  return (
    <Box
      sx={{
        position: 'absolute',
        left: { 
          xs: '50%',
          sm: language === 'ar' ? 'auto' : -120 
        },
        right: { 
          xs: 'auto', 
          sm: language === 'ar' ? -80 : 'auto' 
        },
        top: { xs: -48, sm: '50%' },
        transform: { 
          xs: 'translateX(-43%)',
          sm: language === 'ar' ? 'translateY(-50%) rotate(90deg)' : 'translateY(-50%) rotate(-90deg)'
        },
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        background: 'linear-gradient(45deg, #FF4B91, #FF6B91)',
        borderRadius: '12px',
        padding: '8px 16px',
        boxShadow: '0 4px 12px rgba(255,75,145,0.2)',
        zIndex: 10,
      }}
    >
      <SmartphoneIcon sx={{ 
        fontSize: '1rem',
        color: 'white',
        display: { xs: 'none', sm: 'block' }
      }} />
      <Typography sx={{ 
        color: 'white',
        fontSize: '0.85rem',
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.5px',
        whiteSpace: 'nowrap',
      }}>
        {language === 'ar' ? 'معاينة تفاعلية' : 'Interactive Preview'}
      </Typography>
      <Box
        sx={{
          display: { xs: 'none', sm: 'block' },
          width: 6,
          height: 6,
          borderRadius: '50%',
          background: 'rgba(255,255,255,0.5)',
          animation: 'pulse 2s infinite',
          '@keyframes pulse': {
            '0%': { opacity: 0.5 },
            '50%': { opacity: 1 },
            '100%': { opacity: 0.5 },
          }
        }}
      />
    </Box>
  );
};

const ModernPhoneFrame: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isHintVisible, setIsHintVisible] = useState(true);

  return (
    <Box sx={{ 
      position: 'relative',
      ml: { xs: '-5px', sm: 0 },
      WebkitTapHighlightColor: 'transparent',
      WebkitTouchCallout: 'none',
      WebkitUserSelect: 'none',
      KhtmlUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
      backfaceVisibility: 'hidden',
      WebkitBackfaceVisibility: 'hidden',
      '& *': {
        WebkitTapHighlightColor: 'transparent',
        WebkitTouchCallout: 'none',
        userSelect: 'none',
      }
    }}>
      <PreviewLabel />
      <Box
        onClick={() => setIsHintVisible(false)}
        sx={{
          position: 'relative',
          width: { 
            xs: '280px',
            sm: '260px',
            md: '280px'
          },
          height: { 
            xs: '560px',
            sm: '520px',
            md: '560px'
          },
          transform: 'perspective(2000px) translateZ(0)',
          transformStyle: 'preserve-3d',
          transformOrigin: 'center center',
          cursor: 'pointer',
          mx: 'auto',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          '& svg': {
            shapeRendering: 'crispEdges',
          },
          '& img': {
            imageRendering: '-webkit-optimize-contrast',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            inset: '-20px -15px -30px -15px',
            background: 'radial-gradient(circle at 50% 50%, rgba(255,75,145,0.1), transparent 70%)',
            filter: 'blur(20px)',
            zIndex: -1,
          },
          '&:hover': {
            '& .phone-content': {
              transform: 'none',
            },
          },
        }}
      >
        {isHintVisible && <InteractiveHint />}
        
        <svg
          viewBox="0 0 390 844"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            filter: 'drop-shadow(0 20px 40px rgba(0,0,0,0.2))',
          }}
        >
          <defs>
            <linearGradient id="frameGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#FFFFFF" />
              <stop offset="50%" stopColor="#F8F9FA" />
              <stop offset="100%" stopColor="#F1F3F5" />
            </linearGradient>
            
            <linearGradient id="frameEdge" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#E9ECEF" />
              <stop offset="50%" stopColor="#DEE2E6" />
              <stop offset="100%" stopColor="#E9ECEF" />
            </linearGradient>

            <linearGradient id="cameraRing" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#FAFAFA" />
              <stop offset="100%" stopColor="#F5F5F5" />
            </linearGradient>

            <filter id="phoneShadow">
              <feDropShadow dx="0" dy="4" stdDeviation="8" floodOpacity="0.15"/>
              <feDropShadow dx="0" dy="1" stdDeviation="2" floodOpacity="0.1"/>
            </filter>
          </defs>

          <path
            d="M44 0h302c24.301 0 44 19.699 44 44v756c0 24.301-19.699 44-44 44H44c-24.301 0-44-19.699-44-44V44C0 19.699 19.699 0 44 0z"
            fill="url(#frameGradient)"
            filter="url(#phoneShadow)"
          />

          <path
            d="M44 1h302c23.748 0 43 19.252 43 43v756c0 23.748-19.252 43-43 43H44c-23.748 0-43-19.252-43-43V44C1 20.252 20.252 1 44 1z"
            stroke="rgba(255,255,255,0.8)"
            strokeWidth="0.5"
          />

          <path
            d="M16 44c0-15.464 12.536-28 28-28h302c15.464 0 28 12.536 28 28v756c0 15.464-12.536 28-28 28H44c-15.464 0-28-12.536-28-28V44z"
            fill="#FFFFFF"
            stroke="rgba(0,0,0,0.05)"
            strokeWidth="0.5"
          />

          <g>
            <path
              d="M147 12h96c11.046 0 20 8.954 20 20v4c0 11.046-8.954 20-20 20h-96c-11.046 0-20-8.954-20-20v-4c0-11.046 8.954-20 20-20z"
              fill="#2A2A2A"
            />
            <path
              d="M147 13h96c10.493 0 19 8.507 19 19v4c0 10.493-8.507 19-19 19h-96c-10.493 0-19-8.507-19-19v-4c0-10.493 8.507-19 19-19z"
              stroke="rgba(255,255,255,0.1)"
              strokeWidth="0.5"
            />
          </g>

          <g>
            <circle cx="195" cy="34" r="8" fill="#F8F9FA" />
            <circle cx="195" cy="34" r="7.5" stroke="rgba(0,0,0,0.03)" strokeWidth="0.5" />
            
            <circle cx="195" cy="34" r="6" fill="#F5F5F5" />
            <circle cx="195" cy="34" r="5" fill="#F8F9FA" />
            <circle cx="195" cy="34" r="4" fill="#FAFAFA" />
            <circle cx="195" cy="34" r="3" fill="#FFFFFF" />
            
            <circle cx="195" cy="34" r="2" fill="#FFFFFF" />
            <circle cx="195" cy="34" r="1" fill="#FAFAFA" />
            <circle cx="193.5" cy="32.5" r="0.5" fill="#FFFFFF" fillOpacity="1" />
          </g>

          <g>
            <circle cx="170" cy="34" r="3" fill="#F8F9FA" />
            <circle cx="170" cy="34" r="2" fill="#FAFAFA" />
            <circle cx="170" cy="34" r="1" fill="#FFFFFF" />
            
            <circle cx="220" cy="34" r="3" fill="#F8F9FA" />
            <circle cx="220" cy="34" r="2" fill="#FAFAFA" />
            <circle cx="220" cy="34" r="1" fill="#FFFFFF" />
          </g>

          <g>
            <rect x="-2" y="150" width="4" height="50" rx="2" fill="url(#frameGradient)" />
            <rect x="-1.5" y="150.5" width="3" height="49" rx="1.5" stroke="rgba(0,0,0,0.1)" strokeWidth="0.5" />
            
            <rect x="-2" y="210" width="4" height="50" rx="2" fill="url(#frameGradient)" />
            <rect x="-1.5" y="210.5" width="3" height="49" rx="1.5" stroke="rgba(0,0,0,0.1)" strokeWidth="0.5" />
            
            <rect x="388" y="180" width="4" height="60" rx="2" fill="url(#frameGradient)" />
            <rect x="388.5" y="180.5" width="3" height="59" rx="1.5" stroke="rgba(0,0,0,0.1)" strokeWidth="0.5" />
          </g>

          <g>
            <rect x="155" y="830" width="80" height="4" rx="2" fill="#DEE2E6" />
            <rect x="155.5" y="830.5" width="79" height="3" rx="1.5" stroke="rgba(0,0,0,0.05)" strokeWidth="0.5" />
            
            {[...Array(6)].map((_, i) => (
              <rect
                key={i}
                x={165 + i * 10}
                y="831"
                width="4"
                height="2"
                rx="1"
                fill="#CED4DA"
              />
            ))}
          </g>
        </svg>

        <Box
          className="phone-content"
          sx={{
            position: 'absolute',
            top: '40px',
            left: '14px',
            right: '14px',
            bottom: '14px',
            display: 'flex',
            flexDirection: 'column',
            background: '#FFFFFF',
            borderRadius: '28px',
            overflow: 'hidden',
            boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.05)',
            transition: 'transform 0.2s ease-in-out',
            height: 'calc(100% - 54px)',
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',
            transform: 'translateZ(0)',
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

const MatchResult = ({ onFindAnother }: { onFindAnother: () => void }) => {
  const { language } = useLanguage();
  return (
    <Box sx={{ height: '100%', position: 'relative', pb: 2 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
        style={{ height: '100%' }}
      >
        <Box sx={{ 
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          scrollbarWidth: 'none',
        }}>
          {/* Match Header */}
          <Box sx={{
            p: 1.25,
            textAlign: 'center',
            background: 'linear-gradient(135deg, rgba(255,75,145,0.1), rgba(255,107,145,0.05))',
          }}>
            <Typography sx={{ 
              fontSize: '1rem',
              fontWeight: 600,
              color: '#FF4B91',
              mb: 0.5,
            }}>
              {language === 'ar' ? 'تطابق مثالي!' : 'Perfect Match!'}
            </Typography>
            <Typography sx={{ 
              fontSize: '0.7rem',
              color: 'text.secondary',
            }}>
              {language === 'ar' ? 'بناءً على تفضيلاتك وتحليل الذكاء الاصطناعي' : 'Based on your preferences and AI analysis'}
            </Typography>
          </Box>

          {/* Match Profile - Horizontal layout */}
          <Box sx={{ p: 1.25 }}>
            <Box sx={{ 
              display: 'flex',
              alignItems: 'flex-start',
              gap: 2,
              mb: 2,
            }}>
              {/* Profile Picture */}
              <Box sx={{
                width: 70,
                height: 70,
                borderRadius: '50%',
                background: 'linear-gradient(45deg, #FF4B91, #FF6B91)',
                flexShrink: 0,
              }} />
              
              {/* Profile Info */}
              <Box sx={{ flex: 1 }}>
                <Typography sx={{ 
                  fontWeight: 600,
                  fontSize: '1rem',
                  mb: 0.25,
                }}>
                  {language === 'ar' ? 'سارة، ٢٥' : 'Sarah, 25'}
                </Typography>
                
                <Typography sx={{ 
                  color: 'text.secondary',
                  fontSize: '0.75rem',
                  mb: 0.75,
                }}>
                  {language === 'ar' ? 'الرياض، ٢ كم' : 'Riyadh, 2km'}
                </Typography>

                <Typography sx={{ 
                  color: 'text.secondary',
                  fontSize: '0.75rem',
                }}>
                  {language === 'ar' ? 'مهتمة بالفن والموسيقى...' : 'Interested in art and music...'}
                </Typography>
              </Box>
            </Box>

            {/* Match Stats - Only Compatibility and Shared Interests */}
            <Box sx={{ p: 1.25 }}>
              <Stack spacing={0.75}>
                <Box sx={{
                  p: 0.75,
                  borderRadius: 2,
                  background: 'white',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}>
                  <FavoriteIcon sx={{ color: '#FF4B91', fontSize: '1.1rem' }} />
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ fontSize: '0.7rem', color: 'text.secondary' }}>
                      {language === 'ar' ? 'نسبة التوافق' : 'Compatibility Score'}
                    </Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: '0.85rem' }}>
                      {language === 'ar' ? '٩٥٪' : '95%'}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{
                  p: 0.75,
                  borderRadius: 2,
                  background: 'white',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mb: 3,
                }}>
                  <StarIcon sx={{ color: '#FF4B91', fontSize: '1.1rem' }} />
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ fontSize: '0.7rem', color: 'text.secondary' }}>
                      {language === 'ar' ? 'الاهتمامات المشتركة' : 'Shared Interests'}
                    </Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: '0.85rem' }}>
                      {language === 'ar' ? 'الفن، الموسيقى، السفر' : 'Art, Music, Travel'}
                    </Typography>
                  </Box>
                </Box>

                {/* Back Button - Moved up right after shared interests */}
                <Button
                  variant="contained"
                  startIcon={language === 'ar' ? null : <AutoAwesomeIcon />}
                  endIcon={language === 'ar' ? <AutoAwesomeIcon /> : null}
                  onClick={onFindAnother}
                  sx={{
                    width: '100%',
                    height: '36px',
                    borderRadius: '18px',
                    background: 'linear-gradient(45deg, #FF4B91 30%, #FF6B6B 90%)',
                    boxShadow: '0 8px 20px rgba(255, 75, 145, 0.25)',
                    color: 'white',
                    textTransform: 'none',
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    display: 'flex',
                    flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #FF6B6B 30%, #FF4B91 90%)',
                      boxShadow: '0 8px 25px rgba(255, 75, 145, 0.35)',
                    },
                    transition: 'all 0.3s ease',
                  }}
                >
                  {language === 'ar' ? 'رجوع' : 'Back'}
                </Button>
              </Stack>

              {/* Additional Info section remains after the button */}
              <Box sx={{ mt: 3 }}>
                <Typography sx={{ 
                  fontSize: '0.75rem',
                  color: 'text.secondary',
                  mb: 1,
                }}>
                  {language === 'ar' ? 'معلومات إضافية:' : 'Additional Information:'}
                </Typography>
                <Stack spacing={0.75}>
                  <Typography sx={{ fontSize: '0.75rem' }}>
                    {language === 'ar' ? '• تبحث عن علاقة جادة' : '• Looking for a serious relationship'}
                  </Typography>
                  <Typography sx={{ fontSize: '0.75rem' }}>
                    {language === 'ar' ? '• متعلمة جامعياً' : '• University educated'}
                  </Typography>
                  <Typography sx={{ fontSize: '0.75rem' }}>
                    {language === 'ar' ? '• تعمل في مجال الفنون' : '• Works in arts field'}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
};

const DiscoverScreen = () => {
  const { language } = useLanguage();
  const [isGenerating, setIsGenerating] = useState(false);
  const [showMatch, setShowMatch] = useState(false);
  const [aiLevel, setAiLevel] = useState(80);
  const [preferences, setPreferences] = useState<Preferences>({
    personality: { 
      enabled: true, 
      label: language === 'ar' ? 'الشخصية' : 'Personality'
    },
    values: { 
      enabled: true, 
      label: language === 'ar' ? 'القيم' : 'Values'
    },
    lifestyle: { 
      enabled: false, 
      label: language === 'ar' ? 'نمط الحياة' : 'Lifestyle'
    },
    interests: { 
      enabled: true, 
      label: language === 'ar' ? 'الاهتمامات' : 'Interests'
    },
  });
  const [matchType, setMatchType] = useState('balanced');
  const [isLoading, setIsLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);

  const handleGenerate = () => {
    setIsLoading(true);
    setShowMatch(false);
    setTimeout(() => {
      setIsGenerating(false);
      setShowMatch(true);
      setIsLoading(false);
    }, 2000);
  };

  const handleFindAnother = () => {
    setShowMatch(false);
    setIsLoading(false);
  };

  const matchTypes = [
    { id: 'balanced', 
      label: language === 'ar' ? 'متوازن' : 'Balanced', 
      icon: '⚖️' 
    },
    { id: 'values', 
      label: language === 'ar' ? 'القيم' : 'Values', 
      icon: '🎯' 
    },
    { id: 'lifestyle', 
      label: language === 'ar' ? 'نمط الحياة' : 'Lifestyle', 
      icon: '🌟' 
    },
  ];

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {!showMatch ? (
        isGenerating ? (
          <LoadingAnimation />
        ) : (
          // Discovery Options Screen
          <>
            <Box sx={{ p: 1.25 }}>
              {/* Match Type Selection */}
              <Box sx={{ mb: 1.5 }}>
                <Typography sx={{ 
                  fontSize: '0.85rem', 
                  fontWeight: 600, 
                  color: '#FF4B91',
                  mb: 1,
                }}>
                  {language === 'ar' ? 'نوع المطابقة' : 'Match Type'}
                </Typography>
                <Box sx={{ 
                  display: 'grid', 
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: 0.75,
                }}>
                  {matchTypes.map((type) => (
                    <Box
                      key={type.id}
                      onClick={() => setMatchType(type.id)}
                      sx={{
                        p: 0.75,
                        borderRadius: 1.5,
                        border: '1px solid',
                        borderColor: matchType === type.id ? 'rgba(255,75,145,0.3)' : 'rgba(0,0,0,0.08)',
                        background: matchType === type.id ? 'rgba(255,75,145,0.05)' : 'transparent',
                        cursor: 'pointer',
                        textAlign: 'center',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          borderColor: 'rgba(255,75,145,0.3)',
                        },
                      }}
                    >
                      <Typography sx={{ fontSize: '0.9rem', mb: 0.25 }}>{type.icon}</Typography>
                      <Typography sx={{ 
                        fontSize: '0.75rem',
                        color: matchType === type.id ? '#FF4B91' : 'text.secondary',
                      }}>
                        {type.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>

              {/* AI Level Slider */}
              <Box sx={{ mb: 1.5, direction: language === 'ar' ? 'rtl' : 'ltr' }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 0.5,
                  flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                }}>
                  <AutoAwesomeIcon sx={{ 
                    color: '#FF4B91', 
                    fontSize: '0.9rem',
                    mr: language === 'ar' ? 0.5 : 0,
                    ml: language === 'ar' ? 0 : 0.5,
                  }} />
                  <Typography sx={{ fontSize: '0.85rem', fontWeight: 600, color: '#FF4B91' }}>
                    {language === 'ar' ? 'مستوى الذكاء الاصطناعي' : 'AI Understanding'}
                  </Typography>
                </Box>
                <Slider
                  value={aiLevel}
                  onChange={(_, value) => setAiLevel(value as number)}
                  sx={{
                    color: '#FF4B91',
                    height: 4,
                    padding: '10px 0',
                    '& .MuiSlider-thumb': {
                      width: 12,
                      height: 12,
                    },
                    direction: language === 'ar' ? 'rtl' : 'ltr',
                  }}
                />
              </Box>

              {/* Preference Grid */}
              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 0.75,
              }}>
                {Object.entries(preferences).map(([key, value]) => (
                  <Box
                    key={key}
                    onClick={() => setPreferences(prev => ({
                      ...prev,
                      [key]: { 
                        ...prev[key as keyof Preferences], 
                        enabled: !prev[key as keyof Preferences].enabled 
                      }
                    }))}
                    sx={{
                      p: 0.75,
                      borderRadius: 1.5,
                      border: '1px solid',
                      borderColor: value.enabled ? 'rgba(255,75,145,0.2)' : 'rgba(0,0,0,0.08)',
                      background: value.enabled ? 'rgba(255,75,145,0.03)' : 'transparent',
                      cursor: 'pointer',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        borderColor: 'rgba(255,75,145,0.3)',
                      },
                    }}
                  >
                    <Typography sx={{ 
                      fontSize: '0.75rem',
                      color: value.enabled ? '#FF4B91' : 'text.secondary',
                      textTransform: 'capitalize',
                      textAlign: 'center',
                    }}>
                      {value.label}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Box sx={{ 
                mt: 1.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 0.75,
                borderRadius: 1.5,
                border: '1px solid rgba(0,0,0,0.08)',
                background: 'rgba(255,75,145,0.02)',
              }}>
                <Box>
                  <Typography sx={{ 
                    fontSize: '0.75rem',
                    fontWeight: 600,
                    color: '#FF4B91',
                  }}>
                    {language === 'ar' ? 'المطابقة المتقدمة' : 'Advanced Matching'}
                  </Typography>
                  <Typography sx={{ 
                    fontSize: '0.65rem',
                    color: 'text.secondary',
                  }}>
                    {language === 'ar' ? 'استخدم الذكاء الاصطناعي لتحسين النتائج' : 'Use AI to enhance results'}
                  </Typography>
                </Box>
                <Switch
                  size="small"
                  checked={aiLevel > 50}
                  onChange={(e) => setAiLevel(e.target.checked ? 80 : 20)}
                  sx={{
                    transform: 'scale(0.8)',
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#FF4B91',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#FF4B91',
                    },
                  }}
                />
              </Box>
            </Box>

            {/* Generate Action Button with Loading State */}
            <Box
              sx={{
                position: 'absolute',
                bottom: '60px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: 'auto',
                zIndex: 5,
              }}
            >
              <Button
                variant="contained"
                startIcon={language === 'ar' ? null : <AutoAwesomeIcon />}
                endIcon={language === 'ar' ? <AutoAwesomeIcon /> : null}
                onClick={handleGenerate}
                disabled={isLoading}
                sx={{
                  minWidth: '180px',
                  height: '48px',
                  borderRadius: '24px',
                  background: 'linear-gradient(45deg, #FF4B91 30%, #FF6B6B 90%)',
                  boxShadow: '0 8px 20px rgba(255, 75, 145, 0.25)',
                  color: 'white',
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 600,
                  display: 'flex',
                  flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #FF6B6B 30%, #FF4B91 90%)',
                    boxShadow: '0 8px 25px rgba(255, 75, 145, 0.35)',
                    transform: 'translateY(-2px)',
                  },
                  transition: 'all 0.3s ease',
                  animation: 'pulse 2s infinite',
                  '@keyframes pulse': {
                    '0%': { boxShadow: '0 0 0 0 rgba(255, 75, 145, 0.4)' },
                    '70%': { boxShadow: '0 0 0 10px rgba(255, 75, 145, 0)' },
                    '100%': { boxShadow: '0 0 0 0 rgba(255, 75, 145, 0)' },
                  },
                }}
              >
                {isLoading ? (
                  <CircularProgress size={24} sx={{ 
                    color: 'white',
                    mr: language === 'ar' ? 0 : 1,
                    ml: language === 'ar' ? 1 : 0,
                  }} />
                ) : language === 'ar' ? 'ابدأ البحث' : 'Generate'}
              </Button>
            </Box>
          </>
        )
      ) : (
        <MatchResult onFindAnother={handleFindAnother} />
      )}
    </Box>
  );
};

const SearchScreen = () => {
  const { language } = useLanguage();
  return (
    <Box sx={{ height: '100%', p: 1.25, display: 'flex', flexDirection: 'column' }}>
      {/* Search Header */}
      <Box sx={{ mb: 1.5 }}>
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          p: 1,
          borderRadius: 2,
          background: 'rgba(255,255,255,0.8)',
          border: '1px solid rgba(0,0,0,0.08)',
          boxShadow: '0 2px 8px rgba(0,0,0,0.02)',
        }}>
          <SearchIcon sx={{ color: '#FF4B91', fontSize: '1.2rem' }} />
          <input
            placeholder={language === 'ar' ? 'ابحث بالاسم أو الموقع...' : 'Search by name, location...'}
            style={{
              border: 'none',
              outline: 'none',
              width: '100%',
              fontSize: '0.9rem',
              background: 'transparent',
              textAlign: language === 'ar' ? 'right' : 'left',
            }}
          />
          <TuneIcon sx={{ color: 'text.secondary', fontSize: '1.2rem' }} />
        </Box>
      </Box>

      {/* Quick Filters - Adjusted for single row */}
      <Box sx={{ mb: 1.5 }}>
        <Box sx={{ 
          display: 'flex',
          gap: 0.5,
          flexWrap: 'nowrap',
          width: '100%',
          justifyContent: 'space-between',
        }}>
          {[
            { en: 'Nearby', ar: 'قريب' },
            { en: 'Online', ar: 'متصل' },
            { en: 'New', ar: 'جديد' },
            { en: 'Popular', ar: 'مشهور' },
            { en: 'Verified', ar: 'موثق' }
          ].map((filter) => (
            <Chip
              key={filter.en}
              label={language === 'ar' ? filter.ar : filter.en}
              size="small"
              sx={{
                background: 'linear-gradient(45deg, #FF4B91, #FF6B91)',
                color: 'white',
                fontWeight: 500,
                fontSize: '0.7rem',
                height: '24px',
                flex: '1',
                maxWidth: 'calc(20% - 4px)',
                '& .MuiChip-label': {
                  padding: '0 8px',
                },
              }}
            />
          ))}
        </Box>
      </Box>

      {/* Search Results - Fixed height container */}
      <Box sx={{ 
        flex: 1,
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        scrollbarWidth: 'none',
      }}>
        <Stack spacing={1.5}>
          {[
            { 
              name: language === 'ar' ? 'سارة...' : 'Sarah...',
              age: 25, 
              distance: language === 'ar' ? '٢ كم' : '2km',
              bio: language === 'ar' ? 'فنانة، مهتمة بالفن...' : 'Artist, Art enthusiast...',
              tags: language === 'ar' ? ['الفن', 'الموسيقى', '...'] : ['Art', 'Music', '...']
            },
            { 
              name: language === 'ar' ? 'نور...' : 'Noor...',
              age: 28, 
              distance: language === 'ar' ? '٥ كم' : '5km',
              bio: language === 'ar' ? 'مهندسة، مهتمة بالتقنية...' : 'Engineer, Tech enthusiast...',
              tags: language === 'ar' ? ['التقنية', 'الألعاب', '...'] : ['Tech', 'Gaming', '...']
            },
            { 
              name: language === 'ar' ? 'ريم...' : 'Reem...',
              age: 24, 
              distance: language === 'ar' ? '١ كم' : '1km',
              bio: language === 'ar' ? 'مدربة يوغا...' : 'Yoga instructor...',
              tags: language === 'ar' ? ['اليوغا', 'الصحة', '...'] : ['Yoga', 'Health', '...']
            },
            { 
              name: language === 'ar' ? 'لينا...' : 'Lina...',
              age: 26, 
              distance: language === 'ar' ? '٣ كم' : '3km',
              bio: language === 'ar' ? 'مصورة محترفة...' : 'Professional photographer...',
              tags: language === 'ar' ? ['التصوير', 'السفر', '...'] : ['Photography', 'Travel', '...']
            },
          ].map((profile, index) => (
            <motion.div
              key={profile.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Box sx={{
                p: 1.25,
                borderRadius: 2,
                background: 'white',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                display: 'flex',
                gap: 1.5,
              }}>
                <Box sx={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  background: 'linear-gradient(45deg, #FF4B91, #FF6B91)',
                }} />
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '0.9rem' }}>
                      {profile.name}, {profile.age}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', fontSize: '0.75rem' }}>
                      {profile.distance} away
                    </Typography>
                  </Box>
                  <Typography sx={{ color: 'text.secondary', fontSize: '0.75rem', mb: 0.5 }}>
                    {profile.bio}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 0.5 }}>
                    {profile.tags.map((tag) => (
                      <Chip
                        key={tag}
                        label={tag}
                        size="small"
                        sx={{
                          height: 20,
                          fontSize: '0.65rem',
                          background: 'rgba(255,75,145,0.1)',
                          color: '#FF4B91',
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </motion.div>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

const ChatScreen = () => {
  const { language } = useLanguage();
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 1.25 }}>
      {/* Chat Search */}
      <Box sx={{ mb: 1.5 }}>
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          p: 0.75,
          borderRadius: 2,
          background: 'rgba(255,255,255,0.8)',
          border: '1px solid rgba(0,0,0,0.08)',
        }}>
          <SearchIcon sx={{ color: 'text.secondary', fontSize: '1rem' }} />
          <input
            placeholder={language === 'ar' ? 'ابحث في المحادثات...' : 'Search messages...'}
            style={{
              border: 'none',
              outline: 'none',
              width: '100%',
              fontSize: '0.85rem',
              background: 'transparent',
              textAlign: language === 'ar' ? 'right' : 'left',
            }}
          />
        </Box>
      </Box>

      {/* Chat List - Fixed height container */}
      <Box sx={{ 
        flex: 1,
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        scrollbarWidth: 'none',
      }}>
        <Stack spacing={1}>
          {[
            { 
              name: language === 'ar' ? 'منى...' : 'Mona...',
              time: language === 'ar' ? '٢ د' : '2m',
              message: language === 'ar' ? 'مرحباً! كيف حالك؟ 😊' : 'Hi! How are you? 😊',
              online: true 
            },
            { 
              name: language === 'ar' ? 'سامي...' : 'Sami...',
              time: language === 'ar' ? '١ س' : '1h',
              message: language === 'ar' ? 'شكراً جزيلاً! 🎵' : 'Thank you so much! 🎵',
              online: false 
            },
            { 
              name: language === 'ar' ? 'دانة...' : 'Dana...',
              time: language === 'ar' ? '٣ س' : '3h',
              message: language === 'ar' ? 'أراك قريباً... 🎨' : 'See you soon... 🎨',
              online: true 
            },
            { 
              name: language === 'ar' ? 'عمر...' : 'Omar...',
              time: language === 'ar' ? '١ ي' : '1d',
              message: language === 'ar' ? 'سعدت بالتحدث معك...' : 'Great talking to you...',
              online: false 
            },
          ].map((chat, index) => (
            <motion.div
              key={chat.name}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Box sx={{
                p: 1.25,
                borderRadius: 2,
                background: 'white',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                display: 'flex',
                gap: 1.5,
                alignItems: 'center',
              }}>
                <Box sx={{
                  position: 'relative',
                  width: 50,
                  height: 50,
                }}>
                  <Box sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    background: 'linear-gradient(45deg, #FF4B91, #FF6B91)',
                  }} />
                  <Box sx={{
                    position: 'absolute',
                    bottom: -2,
                    right: -2,
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    background: '#4CAF50',
                    border: '2px solid white',
                  }} />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.25 }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '0.9rem' }}>
                      {chat.name}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', fontSize: '0.7rem' }}>
                      {chat.time} ago
                    </Typography>
                  </Box>
                  <Typography sx={{ 
                    color: 'text.secondary',
                    fontSize: '0.8rem',
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}>
                    {chat.message}
                  </Typography>
                </Box>
              </Box>
            </motion.div>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

const ProfileScreen = () => {
  const { language } = useLanguage();
  return (
    <Box sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
      {/* Profile Header - Reduced padding */}
      <Box sx={{
        p: 1.5,
        textAlign: 'center',
        background: 'linear-gradient(135deg, rgba(255,75,145,0.1), rgba(255,107,145,0.05))',
      }}>
        <Box sx={{
          width: 80,
          height: 80,
          borderRadius: '50%',
          background: 'linear-gradient(45deg, #FF4B91, #FF6B91)',
          margin: '0 auto 1rem',
          position: 'relative',
        }}>
          <IconButton
            sx={{
              position: 'absolute',
              bottom: -4,
              right: -4,
              background: 'white',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              padding: 0.75,
              '&:hover': { background: 'white' },
            }}
          >
            <EditIcon sx={{ fontSize: '1rem', color: '#FF4B91' }} />
          </IconButton>
        </Box>
        <Typography sx={{ fontWeight: 600, fontSize: '1.1rem', mb: 0.5 }}>
          {language === 'ar' ? 'جنى...' : 'Jana...'}
        </Typography>
        <Typography sx={{ color: 'text.secondary', fontSize: '0.8rem' }}>
          {language === 'ar' ? 'الرياض...' : 'Riyadh...'}
        </Typography>
      </Box>

      {/* Profile Sections - Adjusted alignment */}
      <Box sx={{ 
        flex: 1,
        p: 1.25,
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        scrollbarWidth: 'none',
      }}>
        <Stack spacing={1.5}>
          {/* Stats - Adjusted for better alignment */}
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
            textAlign: 'center',
          }}>
            {[
              { label: language === 'ar' ? 'المطابقات' : 'Matches', value: '28' },
              { label: language === 'ar' ? 'الإعجابات' : 'Likes', value: '142' },
              { label: language === 'ar' ? 'المشاهدات' : 'Views', value: '891' },
            ].map((stat) => (
              <Box key={stat.label} sx={{
                p: 1,
                borderRadius: 2,
                background: 'white',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Typography sx={{ 
                  fontWeight: 600, 
                  color: '#FF4B91', 
                  fontSize: '1.1rem',
                  lineHeight: 1.2,
                }}>
                  {stat.value}
                </Typography>
                <Typography sx={{ 
                  color: 'text.secondary', 
                  fontSize: '0.7rem',
                  lineHeight: 1.2,
                }}>
                  {stat.label}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* Settings Sections - More compact */}
          {[
            { icon: <PersonIcon />, title: language === 'ar' ? 'تعديل الملف' : 'Edit Profile', subtitle: language === 'ar' ? 'تحديث معلوماتك' : 'Update your information' },
            { icon: <SettingsIcon />, title: language === 'ar' ? 'التفضيلات' : 'Preferences', subtitle: language === 'ar' ? 'تفضيلات المطابقة' : 'Matching preferences' },
            { icon: <SmartToyIcon />, title: language === 'ar' ? 'إعدادات الذكاء الاصطناعي' : 'AI Settings', subtitle: language === 'ar' ? 'تخصيص المطابقة الذكية' : 'Customize AI matching' },
            { icon: <SecurityIcon />, title: language === 'ar' ? 'الخصوصية' : 'Privacy', subtitle: language === 'ar' ? 'إدارة خصوصيتك' : 'Manage your privacy' },
          ].map((section) => (
            <Box
              key={section.title}
              sx={{
                p: 1,
                borderRadius: 2,
                background: 'white',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                display: 'flex',
                alignItems: 'center',
                gap: 1.25,
              }}
            >
              <Box sx={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                background: 'rgba(255,75,145,0.1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                {React.cloneElement(
                  language === 'ar' ? <ChevronLeftIcon /> : <ChevronRightIcon />,
                  { sx: { color: 'text.secondary' } }
                )}
              </Box>
              <Box>
                <Typography sx={{ fontWeight: 600, fontSize: '0.9rem' }}>
                  {section.title}
                </Typography>
                <Typography sx={{ color: 'text.secondary', fontSize: '0.7rem' }}>
                  {section.subtitle}
                </Typography>
              </Box>
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

const AppContent = () => {
  const { language } = useLanguage();
  const isRTL = language === 'ar';
  const [activeTab, setActiveTab] = useState('home');
  const [isAIActive, setIsAIActive] = useState(false);

  // Get translations based on language
  const t = (key: string) => {
    if (language === 'ar') {
      return {
        // Navigation
        'discover': 'اكتشف',
        'search': 'بحث',
        'chat': 'دردشة',
        'profile': 'الملف الشخصي',
        'app.name': 'مهرة',
        'interactive.preview': 'معاينة تفاعلية',

        // Search
        'search.placeholder': 'ابحث بالاسم أو الموقع...',
        'search.messages': 'ابحث في الرسائل...',

        // Profile
        'profile.edit': 'تعديل الملف',
        'profile.preferences': 'التفضيلات',
        'profile.ai.settings': 'إعدادات الذكاء الاصطناعي',
        'profile.privacy': 'الخصوصية',
        'profile.matches': 'التطابقات',
        'profile.likes': 'الإعجابات',
        'profile.views': 'المشاهدات',

        // Match
        'match.found': 'تم العثور على تطابق مثالي!',
        'match.preferences': 'بناءً على تفضيلاتك',
        'match.compatibility': 'نسبة التوافق',
        'match.interests': 'الاهتمامات المشتركة',
        'match.distance': 'المسافة',

        // Loading
        'loading.finding': 'جارٍ البحث عن تطابق مثالي...',
        'loading.analyzing': 'تحليل التفضيلات والتوافق',
      }[key] || key;
    }
    return key === 'app.name' ? 'Mahra' : key;
  };

  const screens: ScreenContent[] = [
    { id: 'home', content: <DiscoverScreen /> },
    { id: 'search', content: <SearchScreen /> },
    { id: 'chat', content: <ChatScreen /> },
    { id: 'profile', content: <ProfileScreen /> },
  ];

  const navigationItems = [
    { id: 'home', icon: <AutoAwesomeIcon />, label: t('discover') },
    { id: 'search', icon: <SearchIcon />, label: t('search') },
    { id: 'chat', icon: <ChatIcon />, label: t('chat') },
    { id: 'profile', icon: <PersonIcon />, label: t('profile') },
  ];

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transform: 'scale(0.92)',
        transformOrigin: 'top center',
        direction: isRTL ? 'rtl' : 'ltr',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          p: { xs: 1.5, sm: 2 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid rgba(0,0,0,0.08)',
          background: '#FFFFFF',
          boxShadow: '0 1px 2px rgba(0,0,0,0.02)',
          flexShrink: 0,
        }}
      >
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: { xs: '1rem', sm: '1.1rem' },
            fontWeight: 600,
          }}
        >
          {t('app.name')}
        </Typography>
        <IconButton
          onClick={() => setIsAIActive(!isAIActive)}
          sx={{
            color: isAIActive ? '#FF4B91' : 'text.secondary',
            padding: { xs: 0.5, sm: 1 },
          }}
        >
          <SmartToyIcon sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }} />
        </IconButton>
      </Box>

      {/* Main Content Area */}
      <Box 
        sx={{ 
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
          paddingBottom: '100px',
          '& > *': {
            height: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }
        }}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            style={{ 
              height: '100%',
              position: 'absolute',
              inset: 0,
              overflow: 'hidden',
              direction: isRTL ? 'rtl' : 'ltr',
            }}
          >
            {screens.find(screen => screen.id === activeTab)?.content}
          </motion.div>
        </AnimatePresence>
      </Box>

      {/* Navigation */}
      <Stack
        direction="row"
        sx={{
          p: { xs: 0.75, sm: 1 },
          borderTop: '1px solid rgba(0,0,0,0.08)',
          background: '#FFFFFF',
          justifyContent: 'space-around',
          direction: isRTL ? 'rtl' : 'ltr',
          boxShadow: '0 -1px 2px rgba(0,0,0,0.02)',
          flexShrink: 0,
          position: 'absolute',
          bottom: '-30px',
          left: 0,
          right: 0,
          mx: 'auto',
          width: '100%',
          borderRadius: '24px',
          backgroundColor: 'rgba(255, 255, 255, 0.98)',
          backdropFilter: 'blur(8px)',
          zIndex: 10,
        }}
      >
        {navigationItems.map((tab) => (
          <IconButton
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            sx={{
              color: activeTab === tab.id ? '#FF4B91' : 'text.secondary',
              flexDirection: 'column',
              gap: 0.25,
              padding: 0.75,
              '&:hover': {
                background: 'rgba(255,75,145,0.05)',
              },
            }}
          >
            {tab.icon}
            <Typography variant="caption" sx={{ fontSize: '0.65rem' }}>
              {tab.label}
            </Typography>
          </IconButton>
        ))}
      </Stack>
    </Box>
  );
};

export const AppPreview: React.FC = () => {
  return (
    <ModernPhoneFrame>
      <AppContent />
    </ModernPhoneFrame>
  );
};
