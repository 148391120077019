import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  useTheme,
  useMediaQuery,
  Typography,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Link,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import TranslateIcon from '@mui/icons-material/Translate';
import { MENU_ITEMS } from '../../constants/menu';
import { Button } from '../../components/Button';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../constants/translations';
import type { HeaderTranslations } from '../../constants/translations';
import LanguageIcon from '@mui/icons-material/Language';
import { WaitlistModal } from '../WaitlistModal/WaitlistModal';

export const Header: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { language, setLanguage } = useLanguage();
  const t = translations[language];
  const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = () => {
    setLanguage(language === 'en' ? 'ar' : 'en');
    handleMenuClose();
  };

  const scrollToSection = (sectionId: string) => {
    handleMenuClose();
    
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = isMobile ? 70 : 80;
      
      setTimeout(() => {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }, 100);
    }
  };

  const handleMenuItemClick = (sectionId: string) => {
    scrollToSection(sectionId);
    handleMenuClose();
  };

  const handleDownloadClick = () => {
    setIsWaitlistOpen(true);
    handleMenuClose();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <AppBar 
        position="fixed" 
        color="transparent" 
        elevation={0}
        sx={{
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          borderBottom: '1px solid',
          borderColor: 'rgba(255, 255, 255, 0.1)',
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              minHeight: { xs: '70px', md: '80px' },
              py: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}
            disableGutters
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ 
                flex: '0 0 auto',
                minWidth: 0,
                cursor: 'pointer',
              }}
              onClick={scrollToTop}
            >
              <Box
                sx={{
                  position: 'relative',
                  height: { xs: '40px', md: '40px' },
                  width: { xs: '40px', md: '40px' },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  borderRadius: '8px',
                }}
              >
                <Box 
                  component="img"
                  src="/images/logo/logo.png"
                  alt="Logo"
                  sx={{ 
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                    filter: 'brightness(1.1) contrast(1.1)',
                    transform: 'scale(3.1)',
                  }}
                />
              </Box>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                  background: 'linear-gradient(45deg, #FF4B91 30%, #6C22A6 90%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    opacity: 0.8,
                  },
                }}
              >
                Mahra.io
              </Typography>
            </Stack>

            {isMobile ? (
              <IconButton
                onClick={handleMenuOpen}
                sx={{
                  color: 'primary.main',
                  border: '1.5px solid',
                  borderColor: 'primary.main',
                  borderRadius: '8px',
                  p: '8px',
                }}
              >
                <MenuIcon sx={{ fontSize: 20 }} />
              </IconButton>
            ) : (
              <Stack 
                direction="row" 
                spacing={2}
                alignItems="center"
                sx={{
                  height: '100%',
                }}
              >
                <Tooltip title={language === 'en' ? 'العربية' : 'English'}>
                  <IconButton
                    onClick={handleLanguageChange}
                    sx={{
                      color: 'primary.main',
                      border: '1px solid',
                      borderColor: 'primary.main',
                      borderRadius: '50%',
                      width: '32px',
                      height: '32px',
                      '&:hover': {
                        backgroundColor: 'primary.main',
                        color: 'white',
                      },
                    }}
                  >
                    <LanguageIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                </Tooltip>

                {MENU_ITEMS.map((item) => (
                  <Button
                    key={item.label}
                    onClick={() => scrollToSection(item.href.replace('#', ''))}
                    variant="secondary"
                    sx={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      height: '40px',
                      px: 2,
                      fontSize: '0.9rem',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 75, 145, 0.08)',
                        transform: 'translateY(-2px)',
                      },
                    }}
                  >
                    {t.header[item.label as keyof HeaderTranslations]}
                  </Button>
                ))}
                <Button 
                  variant="primary"
                  onClick={handleDownloadClick}
                  sx={{
                    height: '40px',
                    fontSize: '0.9rem',
                  }}
                >
                  {t.header.downloadApp}
                </Button>
              </Stack>
            )}

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  mt: 1,
                  borderRadius: '12px',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
                  minWidth: '200px',
                  direction: language === 'ar' ? 'rtl' : 'ltr',
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={handleLanguageChange} sx={{ py: 1.25, px: 2.5 }}>
                <Stack 
                  direction="row" 
                  spacing={1.5} 
                  alignItems="center"
                  sx={{ 
                    width: '100%',
                    justifyContent: language === 'ar' ? 'flex-start' : 'flex-start',
                  }}
                >
                  <TranslateIcon sx={{ 
                    fontSize: 20, 
                    color: 'primary.main',
                    order: language === 'ar' ? 1 : 0
                  }} />
                  <Typography sx={{ 
                    fontSize: '0.9rem',
                    textAlign: language === 'ar' ? 'right' : 'left',
                    flex: 1,
                  }}>
                    {language === 'en' ? 'العربية' : 'English'}
                  </Typography>
                </Stack>
              </MenuItem>
              
              {MENU_ITEMS.map((item) => (
                <MenuItem 
                  key={item.label}
                  onClick={() => handleMenuItemClick(item.href.replace('#', ''))}
                  component="a"
                  href={item.href}
                  sx={{
                    fontSize: '0.9rem',
                    py: 1.25,
                    px: 2.5,
                    textAlign: language === 'ar' ? 'right' : 'left',
                    width: '100%',
                  }}
                >
                  {t.header[item.label as keyof HeaderTranslations]}
                </MenuItem>
              ))}
              <MenuItem sx={{ p: 1.5 }}>
                <Button 
                  variant="primary" 
                  fullWidth 
                  size="small"
                  onClick={handleDownloadClick}
                  sx={{
                    justifyContent: language === 'ar' ? 'center' : 'center',
                  }}
                >
                  {t.header.downloadApp}
                </Button>
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>

      <WaitlistModal
        open={isWaitlistOpen}
        onClose={() => setIsWaitlistOpen(false)}
      />
    </>
  );
}; 