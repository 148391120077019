import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  IconButton,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { SectionTitle } from '../SectionTitle';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../constants/translations';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ElegantDivider } from '../ElegantDivider/ElegantDivider';

export const FAQ: React.FC = () => {
  const theme = useTheme();
  const { language } = useLanguage();
  const t = translations[language].faq;
  const [expanded, setExpanded] = useState<string | false>(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const questionsPerPage = 4;

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const totalPages = Math.ceil(t.questions.length / questionsPerPage);
  const currentQuestions = t.questions.slice(
    currentPage * questionsPerPage,
    (currentPage + 1) * questionsPerPage
  );

  const handlePageChange = (direction: 'next' | 'prev') => {
    setExpanded(false);
    setPrevPage(currentPage);
    const newPage = direction === 'next' ? currentPage + 1 : currentPage - 1;
    setCurrentPage(newPage);
  };

  const getSlideAnimation = (direction: 'next' | 'prev') => {
    const isRTL = language === 'ar';
    const slideDirection = isRTL ? -1 : 1;
    
    if (direction === 'next') {
      return {
        initial: { opacity: 0, x: 100 * slideDirection },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -100 * slideDirection }
      };
    }
    
    return {
      initial: { opacity: 0, x: -100 * slideDirection },
      animate: { opacity: 1, x: 0 },
      exit: { opacity: 0, x: 100 * slideDirection }
    };
  };

  return (
    <Box
      component="section"
      sx={{
        py: { xs: 4, md: 6 },
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'hidden',
        direction: language === 'ar' ? 'rtl' : 'ltr',
      }}
    >
      <ElegantDivider />
      
      <Container maxWidth="md">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <SectionTitle
            title={t.sectionTitle}
            subtitle={t.sectionSubtitle}
            align="center"
          />
        </motion.div>

        <Box 
          sx={{ 
            position: 'relative', 
            mt: { xs: 3, md: 6 },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              mb: 2,
              order: { xs: -1, md: 1 },
            }}
          >
            <IconButton
              onClick={() => handlePageChange('prev')}
              disabled={currentPage === 0}
              sx={{
                backgroundColor: 'background.paper',
                boxShadow: theme.shadows[2],
                '&:hover:not(:disabled)': {
                  backgroundColor: 'background.paper',
                  transform: 'translateY(-2px)',
                },
              }}
            >
              {language === 'ar' ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
            </IconButton>
            <IconButton
              onClick={() => handlePageChange('next')}
              disabled={currentPage === totalPages - 1}
              sx={{
                backgroundColor: 'background.paper',
                boxShadow: theme.shadows[2],
                '&:hover:not(:disabled)': {
                  backgroundColor: 'background.paper',
                  transform: 'translateY(-2px)',
                },
              }}
            >
              {language === 'ar' ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </Box>

          <AnimatePresence mode="wait">
            <motion.div
              key={currentPage}
              {...getSlideAnimation(currentPage > prevPage ? 'next' : 'prev')}
              transition={{ duration: 0.5 }}
            >
              {currentQuestions.map((question, index) => (
                <Accordion
                  key={`${currentPage}-${index}`}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  sx={{
                    mb: 1.5,
                    backgroundColor: 'background.default',
                    borderRadius: '16px !important',
                    '&:before': { display: 'none' },
                    boxShadow: expanded === `panel${index}`
                      ? '0 8px 32px rgba(0,0,0,0.08)'
                      : '0 4px 24px rgba(0,0,0,0.06)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: 'background.default',
                      transform: 'translateY(-2px)',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      borderRadius: '16px',
                      '& .MuiAccordionSummary-content': {
                        my: { xs: 1, md: 2 },
                      },
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: 600,
                        fontSize: { xs: '1rem', md: '1.25rem' }
                      }}
                    >
                      {question.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body1"
                      sx={{
                        color: 'text.secondary',
                        lineHeight: 1.8,
                        mb: 1,
                      }}
                    >
                      {question.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </motion.div>
          </AnimatePresence>
        </Box>
      </Container>
    </Box>
  );
}; 