import React from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';

interface SectionTransitionProps {
  color?: string;
  pattern?: 'wave' | 'curve' | 'angle';
}

export const SectionTransition: React.FC<SectionTransitionProps> = ({ 
  color = '#fff',
  pattern = 'wave'
}) => {
  const paths = {
    wave: "M0 0c116.143 39.804 273.367 73.322 427.132 73.322C650.264 73.322 832.764 0 1028.542 0 1224.321 0 1440 39.804 1440 39.804V120H0V0z",
    curve: "M0,96L1440,32L1440,0L0,0Z",
    angle: "M0 0L1440 96V0H0Z"
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: -2,
        height: { xs: '4rem', md: '6rem' },
        zIndex: 1,
      }}
    >
      <motion.svg
        viewBox="0 0 1440 120"
        preserveAspectRatio="none"
        style={{ width: '100%', height: '100%' }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.path
          d={paths[pattern]}
          fill={color}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 2, ease: "easeInOut" }}
        />
      </motion.svg>
    </Box>
  );
}; 