import React from 'react';
import { Box } from '@mui/material';
import { Header } from '../../components/Header';
import { Hero } from '../../components/Hero';
import { Features } from '../../components/Features';
import { HowItWorks } from '../../components/HowItWorks';
import { AppHighlights } from '../../components/AppHighlights';
import { Testimonials } from '../../components/Testimonials';
import { FAQ } from '../../components/FAQ';
import { JoinWaitlist } from '../../components/JoinWaitlist';
import { Footer } from '../../components/Footer';
import { WaveSeparator } from '../../components/WaveSeparator';
import { useTheme } from '@mui/material';
import { Contact } from '../../components/Contact/Contact';

export const HomePage = () => {
  const theme = useTheme();

  return (
    <Box>
      <Header />
      <Hero />
      
      <Box
        component="main"
        sx={{
          position: 'relative',
          zIndex: 1,
          background: 'white',
          '& > div': {
            mb: { xs: 2, sm: 3 }
          }
        }}
      >
        {/* Features Section */}
        <Box sx={{ position: 'relative' }}>
          <WaveSeparator
            position="top"
            backgroundColor={theme.palette.background.default}
            foregroundColor={theme.palette.background.paper}
            pattern="curve"
            height={40}
            opacity={0.1}
          />
          <Box id="features">
            <Features />
          </Box>
        </Box>

        {/* How It Works Section - Adjusted spacing */}
        <Box sx={{ 
          position: 'relative',
          pt: { xs: 2, sm: 3 }, // Only top padding
          pb: { xs: 0, sm: 1 }  // Minimal bottom padding
        }}>
          <WaveSeparator
            position="top"
            backgroundColor={theme.palette.background.paper}
            foregroundColor={theme.palette.background.default}
            pattern="wave"
            height={40}
            opacity={0.1}
          />
          <Box id="how-it-works">
            <HowItWorks />
          </Box>
        </Box>

        {/* Join Waitlist Section - Moved closer to How It Works */}
        <Box sx={{ 
          position: 'relative',
          mt: { xs: -2, sm: -3 } // Negative margin to pull it up
        }}>
          <WaveSeparator
            position="top"
            backgroundColor={theme.palette.background.default}
            foregroundColor={theme.palette.background.paper}
            pattern="curve"
            height={40}
            opacity={0.1}
          />
          <JoinWaitlist />
        </Box>

        {/* App Highlights Section */}
        <Box sx={{ position: 'relative' }}>
          <WaveSeparator
            position="top"
            backgroundColor={theme.palette.background.paper}
            foregroundColor={theme.palette.background.default}
            pattern="wave"
            height={40}
            opacity={0.1}
          />
          <Box id="app-highlights">
            <AppHighlights />
          </Box>
        </Box>

        {/* Testimonials Section */}
        <Box sx={{ position: 'relative' }}>
          <WaveSeparator
            position="top"
            backgroundColor={theme.palette.background.default}
            foregroundColor={theme.palette.background.paper}
            pattern="curve"
            height={40}
            opacity={0.1}
          />
          <Box id="testimonials">
            <Testimonials />
          </Box>
        </Box>

        {/* FAQ Section */}
        <Box sx={{ position: 'relative' }}>
          <WaveSeparator
            position="top"
            backgroundColor={theme.palette.background.paper}
            foregroundColor={theme.palette.background.default}
            pattern="wave"
            height={40}
            opacity={0.1}
          />
          <Box id="faq">
            <FAQ />
          </Box>
        </Box>

        {/* Contact Section */}
        <Box sx={{ position: 'relative' }}>
          <WaveSeparator
            position="top"
            backgroundColor={theme.palette.background.default}
            foregroundColor={theme.palette.background.paper}
            pattern="curve"
            height={40}
            opacity={0.1}
          />
          <Box id="contact">
            <Contact />
          </Box>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}; 