import { cookiesContentEn } from './content/cookies-en';
import { cookiesContentAr } from './content/cookies-ar';

interface CookiesContentType {
  en: {
    en: string;
    ar: string;
  };
  ar: {
    en: string;
    ar: string;
  };
}

export const cookiesContent: CookiesContentType = {
  en: {
    en: cookiesContentEn,
    ar: cookiesContentAr
  },
  ar: {
    en: cookiesContentEn,
    ar: cookiesContentAr
  }
}; 