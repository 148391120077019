export type MenuItemKey = 'features' | 'showcase' | 'testimonials' | 'faq' | 'contact';

export interface MenuItem {
  label: MenuItemKey;
  href: string;
}

export const MENU_ITEMS = [
  {
    label: 'features',
    href: '#features'
  },
  {
    label: 'howItWorks',
    href: '#how-it-works'
  },
  {
    label: 'testimonials',
    href: '#testimonials'
  },
  {
    label: 'faq',
    href: '#faq'
  },
  {
    label: 'contact',
    href: '#contact'
  }
] as const;

export type MenuItems = typeof MENU_ITEMS; 