import React from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../constants/translations';
import { SectionTitle } from '../SectionTitle';
import EmailIcon from '@mui/icons-material/Email';

export const Contact = () => {
  const theme = useTheme();
  const { language } = useLanguage();
  const t = translations[language].contact;
  const isRTL = language === 'ar';

  // Animation variants for floating elements
  const floatingAnimation = {
    y: [-10, 10],
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse" as const,
      ease: "easeInOut"
    }
  };

  return (
    <Box
      component="section"
      sx={{
        py: { xs: 8, md: 12 },
        background: `linear-gradient(135deg, ${theme.palette.primary.main}08 0%, ${theme.palette.secondary.main}08 100%)`,
        position: 'relative',
        overflow: 'hidden',
        direction: isRTL ? 'rtl' : 'ltr',
      }}
    >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: { xs: 4, md: 6 },
          }}>
            <SectionTitle
              title={t.sectionTitle}
              subtitle={t.sectionSubtitle}
              sx={{
                textAlign: 'center',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& h2': {
                  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mb: 2,
                  fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                  fontWeight: 700,
                  width: '100%',
                  textAlign: 'center',
                },
                '& p': {
                  maxWidth: { xs: '600px', md: '800px' },
                  width: '100%',
                  mx: 'auto',
                  color: 'text.secondary',
                  fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
                  lineHeight: 1.6,
                  textAlign: 'center',
                  px: { xs: 2, sm: 4 },
                  mt: 1,
                }
              }}
            />
          </Box>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <Paper
            elevation={0}
            sx={{
              maxWidth: '600px',
              mx: 'auto',
              mt: 6,
              p: { xs: 4, sm: 5 },
              borderRadius: '24px',
              background: 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(10px)',
              border: '1px solid',
              borderColor: 'divider',
              textAlign: 'center',
              position: 'relative',
              overflow: 'hidden',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '4px',
                background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3,
                position: 'relative',
                zIndex: 1,
              }}
            >
              <motion.div
                whileHover={{ scale: 1.05, rotate: 5 }}
                whileTap={{ scale: 0.95 }}
              >
                <Box
                  sx={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    background: `linear-gradient(135deg, ${theme.palette.primary.main}15 0%, ${theme.palette.secondary.main}15 100%)`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      inset: -4,
                      borderRadius: '50%',
                      border: '2px solid',
                      borderColor: 'primary.main',
                      opacity: 0.2,
                    }
                  }}
                >
                  <EmailIcon
                    sx={{
                      fontSize: '48px',
                      color: theme.palette.primary.main,
                    }}
                  />
                </Box>
              </motion.div>

              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  color: 'text.primary',
                  mb: 1,
                  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                {t.emailTitle}
              </Typography>

              <motion.div
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <Box
                  component="a"
                  href="mailto:Support@mahra.io"
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.primary.main,
                    fontSize: { xs: '1.2rem', sm: '1.4rem' },
                    fontWeight: 500,
                    display: 'inline-block',
                    position: 'relative',
                    py: 1,
                    px: 3,
                    borderRadius: '12px',
                    transition: 'all 0.3s ease',
                    background: `linear-gradient(135deg, ${theme.palette.primary.main}10 0%, ${theme.palette.secondary.main}10 100%)`,
                    border: '1px solid',
                    borderColor: 'divider',
                    '&:hover': {
                      background: `linear-gradient(135deg, ${theme.palette.primary.main}15 0%, ${theme.palette.secondary.main}15 100%)`,
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                >
                  {t.emailAddress}
                </Box>
              </motion.div>

              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ 
                  mt: 2,
                  maxWidth: '400px',
                  mx: 'auto',
                  fontSize: '1rem',
                  lineHeight: 1.6,
                }}
              >
                {t.supportText}
              </Typography>
            </Box>
          </Paper>
        </motion.div>
      </Container>
    </Box>
  );
}; 