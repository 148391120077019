import React, { useState } from 'react';
import { Box, Container, Grid, Typography, useTheme, useMediaQuery, Collapse } from '@mui/material';
import { motion } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../constants/translations';
import { SectionTitle } from '../SectionTitle';
import SecurityIcon from '@mui/icons-material/Security';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ChatIcon from '@mui/icons-material/Chat';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FeatureVisual } from './FeatureVisual';

export const AppHighlights: React.FC = () => {
  const theme = useTheme();
  const { language } = useLanguage();
  const t = translations[language].appHighlights;

  const [selectedFeature, setSelectedFeature] = useState<string>('aiMatching');
  const [expandedFeature, setExpandedFeature] = useState<string | null>('aiMatching');

  const handleFeatureClick = (featureId: string) => {
    setSelectedFeature(featureId);
    setExpandedFeature(expandedFeature === featureId ? null : featureId);
  };

  const features = [
    {
      id: 'aiMatching',
      icon: <PsychologyIcon />,
      title: language === 'ar' ? 'المطابقة بالذكاء الاصطناعي' : 'AI-Powered Matchmaking',
      tagline: language === 'ar' ? 'مطابقة ذكية ودقيقة ومخصصة' : 'Smart, Accurate, and Personalized Matches',
      description: language === 'ar' 
        ? 'اكتشف التطابقات بناءً على القيم المشتركة والاهتمامات والتفضيلات الثقافية. تحلل خوارزميات الذكاء الاصطناعي المتقدمة ملفات المستخدمين لتقييم التوافق، وتقدم توصيات في الوقت الفعلي مخصصة حسب سلوكك وتفضيلاتك.'
        : 'Discover matches based on shared values, interests, and cultural preferences. Our advanced AI algorithms analyze user profiles for compatibility scoring, providing real-time recommendations tailored to your behavior and preferences.',
      whyItMatters: language === 'ar'
        ? 'اعثر على روابط ذات معنى بسهولة مع تقنية تفهمك حقاً'
        : 'Find meaningful connections effortlessly with technology that truly understands you.'
    },
    {
      id: 'compatibility',
      icon: <FavoriteIcon />,
      title: language === 'ar' ? 'أدوات التوافق للزواج' : 'AI Marriage Compatibility Tools',
      tagline: language === 'ar' ? 'مساعدتك في التخطيط لمستقبل هادف' : 'Helping You Plan for a Meaningful Future',
      description: language === 'ar'
        ? 'يقيّم تقييم الاستعداد للزواج الأهداف والقيم المشتركة والتوافق طويل المدى. تتوافق ميزات المطابقة المركزة على العائلة مع التقاليد الخليجية، بينما تحلل رؤى العلاقات المخصصة التوافق في أسلوب التواصل وأهداف الحياة.'
        : 'Our Marriage Readiness Assessment evaluates shared goals, values, and long-term compatibility. Family-centric matching features align with Gulf traditions, while personalized relationship insights analyze compatibility in communication style and life goals.',
      whyItMatters: language === 'ar'
        ? 'مهرة يتجاوز المواعدة - أدواتنا مصممة لمساعدتك في بناء أساس قوي لشراكة دائمة'
        : 'Mahra.io goes beyond dating—our tools are designed to help you build a strong foundation for a lasting partnership.'
    },
    {
      id: 'communication',
      icon: <ChatIcon />,
      title: language === 'ar' ? 'أدوات تواصل تفاعلية' : 'Engaging Communication Tools',
      tagline: language === 'ar' ? 'محادثات سهلة وممتعة' : 'Conversations Made Easy and Fun',
      description: language === 'ar'
        ? 'تساعد رسائل كسر الجليد المولدة بالذكاء الاصطناعي في بدء محادثات هادفة. استمتع بالدردشة في الوقت الفعلي مع ميزات مثل مؤشرات الكتابة وإشعارات القراءة ومشاركة الوسائط. يحافظ الإشراف الذكي على الدردشة على مساحة محترمة.'
        : 'AI-generated icebreaker messages help you start meaningful chats. Enjoy real-time chat with features like typing indicators, read receipts, and media sharing. Smart chat moderation powered by AI maintains a respectful space.',
      whyItMatters: language === 'ar'
        ? 'اكسر الجليد، شارك اللحظات، وأنشئ روابط حقيقية بسهولة'
        : 'Break the ice, share moments, and create genuine connections with ease.'
    },
    {
      id: 'security',
      icon: <SecurityIcon />,
      title: language === 'ar' ? 'الخصوصية والأمان أولاً' : 'Privacy & Security First',
      tagline: language === 'ar' ? 'سلامتك هي أولويتنا' : 'Your Safety is Our Priority',
      description: language === 'ar'
        ? 'يضمن التشفير من طرف إلى طرف لجميع المحادثات اتصالاً خاصاً وآمناً. تضمن أدوات التحقق من الوجه الأصالة وتبني الثقة، بينما تتيح أدوات إخفاء الهوية الفريدة مثل إخفاء الصور والوضع المتخفي التصفح بخصوصية.'
        : 'End-to-end encryption for all conversations ensures private and secure communication. Face verification tools ensure authenticity and build trust, while unique anonymity tools like image masking and incognito mode enable discreet browsing.',
      whyItMatters: language === 'ar'
        ? 'اشعر بالأمان والثقة أثناء التواصل في بيئة تحترم الثقافة'
        : 'Feel secure and confident as you connect in a culturally respectful environment.'
    },
  ];

  return (
    <Box
      component="section"
      sx={{
        py: { xs: 4, md: 8 },
        backgroundColor: 'background.default',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ mb: { xs: 2, md: 4 } }}>
          <SectionTitle
            title={t.sectionTitle}
            subtitle={t.sectionSubtitle}
            align="center"
          />
        </Box>

        <Grid 
          container 
          spacing={2}
          alignItems="flex-start"
          sx={{ 
            mt: { xs: 2, md: 4 },
            direction: language === 'ar' ? 'row-reverse' : 'row'
          }}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: 'relative',
                height: { xs: '380px', md: '480px' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <FeatureVisual feature={selectedFeature} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box 
              sx={{ 
                pl: { md: language === 'ar' ? 0 : 3 },
                pr: { md: language === 'ar' ? 3 : 0 },
                pt: { xs: '280px', md: 0 },
                mt: { xs: '-100px', md: 0 },
                '& > div': {
                  mb: { md: 3 },
                }
              }}
            >
              {features.map((feature) => (
                <motion.div
                  key={feature.id}
                  initial={{ opacity: 0, x: language === 'ar' ? -50 : 50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                >
                  <Box
                    onClick={() => handleFeatureClick(feature.id)}
                    sx={{
                      mb: 1.5,
                      p: 2,
                      borderRadius: 2,
                      backgroundColor: 'background.paper',
                      boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
                      transition: 'all 0.3s ease',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 8px 30px rgba(0,0,0,0.1)',
                        zIndex: 2,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        flexDirection: language === 'ar' ? 'row-reverse' : 'row'
                      }}>
                        <Box
                          sx={{
                            ml: language === 'ar' ? 2 : 0,
                            mr: language === 'ar' ? 0 : 2,
                            p: 1.5,
                            borderRadius: '12px',
                            backgroundColor: selectedFeature === feature.id ? 'primary.main' : 'primary.light',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: 'all 0.3s ease',
                          }}
                        >
                          {feature.icon}
                        </Box>
                        <Typography variant="h6" sx={{ textAlign: language === 'ar' ? 'right' : 'left' }}>
                          {feature.title}
                        </Typography>
                      </Box>
                      <motion.div
                        animate={{ rotate: expandedFeature === feature.id ? 180 : 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <ExpandMoreIcon />
                      </motion.div>
                    </Box>
                    <Collapse in={expandedFeature === feature.id}>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ 
                          mt: 2, 
                          lineHeight: 1.7,
                          textAlign: language === 'ar' ? 'right' : 'left'
                        }}
                      >
                        {feature.description}
                      </Typography>
                    </Collapse>
                  </Box>
                </motion.div>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}; 