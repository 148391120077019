import React, { useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  fullDescription?: string;
}

export const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
  fullDescription,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Paper
      component={motion.div}
      whileHover={{ y: -8 }}
      layout
      onClick={() => setIsExpanded(!isExpanded)}
      elevation={0}
      sx={{
        p: 4,
        width: { xs: '280px', md: '320px' },
        minHeight: '280px',
        borderRadius: '24px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(20px)',
        transition: 'all 0.3s ease',
        border: '1px solid',
        borderColor: 'rgba(255, 255, 255, 0.2)',
        cursor: 'pointer',
        '@supports (-webkit-overflow-scrolling: touch)': {
          transform: 'translate3d(0,0,0)',
          backfaceVisibility: 'hidden',
          WebkitBackfaceVisibility: 'hidden',
          '& h5, & p, & span': {
            maxWidth: '100%',
            display: 'block',
            letterSpacing: '-0.01em',
          }
        },
        '& .MuiTypography-root': {
          textRendering: 'geometricPrecision',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        },
        '& .icon-container': {
          transform: 'translate3d(0,0,0)',
          '& svg': {
            filter: 'none',
            shapeRendering: 'geometricPrecision'
          }
        },
        '&:hover': {
          boxShadow: '0 20px 40px rgba(0, 0, 0, 0.08)',
          borderColor: 'primary.main',
          '& .icon-container': {
            transform: 'scale(1.1) translate3d(0,0,0)',
            backgroundColor: 'primary.main',
            '& svg': {
              color: 'white',
            },
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          height: '100%',
        }}
      >
        <Box
          className="icon-container"
          sx={{
            mb: 3,
            p: 2,
            borderRadius: '20px',
            backgroundColor: 'rgba(255, 75, 145, 0.1)',
            color: 'primary.main',
            transition: 'all 0.3s ease',
            WebkitTransform: 'translate3d(0,0,0)',
          }}
        >
          {icon}
        </Box>
        <Typography
          variant="h5"
          sx={{
            mb: 2,
            fontWeight: 600,
            color: 'text.primary',
            textRendering: 'geometricPrecision',
            WebkitFontSmoothing: 'antialiased',
            letterSpacing: '-0.01em',
          }}
        >
          {title}
        </Typography>
        <AnimatePresence>
          {isExpanded ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'text.secondary',
                  lineHeight: 1.6,
                  textRendering: 'geometricPrecision',
                  WebkitFontSmoothing: 'antialiased',
                  letterSpacing: '-0.01em',
                }}
              >
                {fullDescription || description}
              </Typography>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'text.secondary',
                  lineHeight: 1.6,
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textRendering: 'geometricPrecision',
                  WebkitFontSmoothing: 'antialiased',
                  letterSpacing: '-0.01em',
                }}
              >
                {description}
              </Typography>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </Paper>
  );
}; 