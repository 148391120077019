import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SecurityIcon from '@mui/icons-material/Security';
import WifiIcon from '@mui/icons-material/Wifi';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import { useLanguage } from '../../contexts/LanguageContext';

interface FeatureVisualProps {
  feature: string;
}

export const FeatureVisual: React.FC<FeatureVisualProps> = ({ feature }) => {
  const theme = useTheme();
  const { language } = useLanguage();
  const isRTL = language === 'ar';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const PhoneFrame: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Box
      sx={{
        position: 'relative',
        width: { xs: '240px', md: '280px' },
        height: { xs: '480px', md: '580px' },
        borderRadius: '40px',
        background: `linear-gradient(145deg, ${theme.palette.background.paper}, ${theme.palette.grey[100]})`,
        boxShadow: `
          0 20px 40px rgba(0,0,0,0.1),
          inset 0 -2px 6px rgba(255,255,255,0.5),
          inset 0 2px 6px rgba(0,0,0,0.05)
        `,
        padding: { xs: '8px', md: '12px' },
        transform: isRTL ? 'scaleX(-1)' : 'none',
        mx: 'auto',
      }}
    >
      {/* Phone Frame Details */}
      <Box
        sx={{
          position: 'absolute',
          top: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '80px',
          height: '8px',
          borderRadius: '4px',
          backgroundColor: theme.palette.grey[300],
          zIndex: 2,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: '30px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '120px',
          height: '4px',
          borderRadius: '2px',
          backgroundColor: theme.palette.grey[300],
        }}
      />

      {/* Main Screen */}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: '32px',
          overflow: 'hidden',
          position: 'relative',
          background: theme.palette.background.default,
        }}
      >
        {/* Status Bar */}
        <Box
          sx={{
            position: 'relative',
            height: '44px',
            px: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
            transform: isRTL ? 'scaleX(-1)' : 'none',
          }}
        >
          <Typography variant="caption" sx={{ fontWeight: 'medium' }}>
            9:41
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {/* <WifiIcon sx={{ fontSize: 16 }} /> */}
            <SignalCellularAltIcon sx={{ fontSize: 16 }} />
            <BatteryFullIcon sx={{ fontSize: 18 }} />
          </Box>
        </Box>

        {/* Content Area */}
        <Box sx={{ transform: isRTL ? 'scaleX(-1)' : 'none' }}>
          {children}
        </Box>
      </Box>
    </Box>
  );

  const visuals = {
    aiMatching: (
      <PhoneFrame>
        <Box sx={{ p: 2, height: 'calc(100% - 44px)', overflow: 'auto' }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Box sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1.5,
              direction: language === 'ar' ? 'rtl' : 'ltr'
            }}>
              <Typography variant="subtitle1" sx={{ 
                textAlign: 'center', 
                mb: 0.5
              }}>
                {language === 'ar' ? 'تحليل التوافق الذكي' : 'AI Match Analysis'}
              </Typography>

              {/* AI Score Display - Simplified without spinning circles */}
              <Box sx={{ 
                position: 'relative', 
                height: { xs: '120px', md: '140px' }, 
                mb: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'background.paper',
                borderRadius: '20px',
              }}>
                <Box sx={{
                  textAlign: 'center',
                  p: 3,
                  border: `2px solid ${theme.palette.primary.main}`,
                  borderRadius: '16px',
                }}>
                  <Typography variant="h4" color="primary" sx={{ fontWeight: 'bold' }}>
                    {language === 'ar' ? '٩٨٪' : '98%'}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {language === 'ar' ? 'معدل التوافق' : 'Match Rate'}
                  </Typography>
                </Box>
              </Box>

              {/* Categories with proper RTL support */}
              <Box sx={{ mb: 2 }}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    mb: 1,
                    textAlign: language === 'ar' ? 'right' : 'left',
                    fontSize: '0.875rem'
                  }}
                >
                  {language === 'ar' ? 'فئات التوافق' : 'Compatibility Categories'}
                </Typography>
                {[
                  {
                    title: language === 'ar' ? 'القيم والمعتقدات' : 'Values & Beliefs',
                    score: language === 'ar' ? '٩٥٪' : '95%',
                    icon: '👨ٌ',
                    details: language === 'ar' ? 'توافق عالي في القيم الدينية والعائلية' : 'High alignment in religious and family values'
                  },
                  {
                    title: language === 'ar' ? 'التوافق الثقافي' : 'Cultural Alignment',
                    score: language === 'ar' ? '٩٢٪' : '92%',
                    icon: '🎭',
                    details: language === 'ar' ? 'خلفية ثقافية وتقاليد متشابهة' : 'Similar cultural background and traditions'
                  },
                  {
                    title: language === 'ar' ? 'أهداف الحياة' : 'Life Goals',
                    score: language === 'ar' ? '٨٨٪' : '88%',
                    icon: '💰',
                    details: language === 'ar' ? 'تطابق في الأهداف المستقبلية' : 'Matching future aspirations'
                  },
                  {
                    title: language === 'ar' ? 'نمط الحياة' : 'Lifestyle',
                    score: language === 'ar' ? '٩٠٪' : '90%',
                    icon: '⭐',
                    details: language === 'ar' ? 'توافق في أسلوب الحياة والاهتمامات' : 'Compatible lifestyle and interests'
                  }
                ].map((category, index) => (
                  <motion.div
                    key={category.title}
                    initial={{ x: language === 'ar' ? 50 : -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <Box
                      sx={{
                        mb: 1,
                        p: 1.5,
                        borderRadius: 2,
                        backgroundColor: 'background.paper',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                        mb: 1
                      }}>
                        <Box sx={{ 
                          fontSize: '1.2rem',
                          mr: language === 'ar' ? 0 : 1,
                          ml: language === 'ar' ? 1 : 0
                        }}>
                          {category.icon}
                        </Box>
                        <Typography variant="body2" sx={{ flex: 1 }}>
                          {category.title}
                        </Typography>
                        <Typography 
                          variant="body2" 
                          color="primary.main" 
                          sx={{ fontWeight: 'bold' }}
                        >
                          {category.score}
                        </Typography>
                      </Box>
                      <Typography 
                        variant="caption" 
                        color="text.secondary"
                        sx={{ 
                          display: 'block',
                          textAlign: language === 'ar' ? 'right' : 'left'
                        }}
                      >
                        {category.details}
                      </Typography>
                    </Box>
                  </motion.div>
                ))}
              </Box>

              {/* AI Insights */}
              <Box sx={{ mt: 'auto', mb: 1 }}>
                <Typography variant="subtitle2" sx={{ 
                  mb: 1,
                  textAlign: language === 'ar' ? 'right' : 'left',
                  fontSize: '0.875rem'
                }}>
                  {language === 'ar' ? 'رؤى الذكاء الاصطناعي' : 'AI Insights'}
                </Typography>
                <Box sx={{
                  p: 1.5,
                  borderRadius: 2,
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                }}>
                  <Typography variant="body2" sx={{ 
                    mb: 0.5,
                    textAlign: language === 'ar' ? 'right' : 'left',
                    direction: language === 'ar' ? 'rtl' : 'ltr'
                  }}>
                    {language === 'ar' 
                      ? 'تطابق ممتاز! لديكما قيم متشابهة وأهداف متوافقة للزواج.'
                      : 'Excellent match! You share similar values and aligned marriage goals.'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </motion.div>
        </Box>
      </PhoneFrame>
    ),

    security: (
      <PhoneFrame>
        <Box sx={{ p: 2, height: 'calc(100% - 44px)', overflow: 'auto' }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Box sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column',
              gap: 3,
              direction: language === 'ar' ? 'rtl' : 'ltr'
            }}>
              {/* Title */}
              <Typography variant="subtitle1" sx={{ 
                textAlign: 'center',
                mb: 2
              }}>
                {language === 'ar' ? 'ميزات ال�مان والخصوصية' : 'Security & Privacy Features'}
              </Typography>

              {/* Central Security Shield */}
              <Box sx={{ 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: 3
              }}>
                <motion.div
                  animate={{
                    scale: [1, 1.1, 1],
                    opacity: [0.8, 1, 0.8],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                >
                  <Box sx={{
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    backgroundColor: 'primary.light',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0 0 30px rgba(108, 34, 166, 0.2)',
                  }}>
                    <SecurityIcon sx={{ 
                      fontSize: 40,
                      color: 'primary.main'
                    }} />
                  </Box>
                </motion.div>
              </Box>

              {/* Security Features List */}
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                px: 1
              }}>
                {[
                  {
                    icon: '🔒',
                    title: language === 'ar' ? 'تشفير المحادثات' : 'End-to-End Encryption',
                    status: language === 'ar' ? 'نشط' : 'Active',
                    color: '#4CAF50'
                  },
                  {
                    icon: '👤',
                    title: language === 'ar' ? 'التحقق من الهوية' : 'Identity Verification',
                    status: language === 'ar' ? 'مؤكد' : 'Verified',
                    color: '#2196F3'
                  },
                  {
                    icon: '🛡️',
                    title: language === 'ar' ? 'حماية الخصوصية' : 'Privacy Protection',
                    status: language === 'ar' ? 'محمي' : 'Protected',
                    color: '#9C27B0'
                  },
                  {
                    icon: '👁️',
                    title: language === 'ar' ? 'وضع التخفي' : 'Incognito Mode',
                    status: language === 'ar' ? 'متاح' : 'Available',
                    color: '#FF9800'
                  }
                ].map((feature, index) => (
                  <motion.div
                    key={feature.title}
                    initial={{ x: language === 'ar' ? 50 : -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <Box sx={{
                      p: 2,
                      borderRadius: 2,
                      backgroundColor: 'background.paper',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                      gap: 2
                    }}>
                      <Box sx={{ 
                        fontSize: '1.5rem',
                        width: 40,
                        height: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '12px',
                        backgroundColor: 'primary.light',
                      }}>
                        {feature.icon}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="body2" sx={{ 
                          fontWeight: 'medium',
                          textAlign: language === 'ar' ? 'right' : 'left'
                        }}>
                          {feature.title}
                        </Typography>
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            color: feature.color,
                            display: 'block',
                            textAlign: language === 'ar' ? 'right' : 'left'
                          }}
                        >
                          {feature.status}
                        </Typography>
                      </Box>
                    </Box>
                  </motion.div>
                ))}
              </Box>

              {/* Bottom Status */}
              <Box sx={{
                mt: 'auto',
                p: 2,
                borderRadius: 2,
                backgroundColor: 'success.light',
                color: 'success.dark',
                textAlign: 'center'
              }}>
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                  {language === 'ar' 
                    ? 'جميع أنظمة الحماية نشطة وتعمل بكفاءة'
                    : 'All protection systems active and working efficiently'}
                </Typography>
              </Box>
            </Box>
          </motion.div>
        </Box>
      </PhoneFrame>
    ),

    culturalMatch: (
      <PhoneFrame>
        <Box sx={{ p: 2, height: 'calc(100% - 44px)', overflow: 'auto' }}>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="subtitle1" sx={{ textAlign: 'center', mb: 1 }}>
                {language === 'ar' ? 'التوافق الثقافي' : 'Cultural Matching'}
              </Typography>

              {/* Cultural Preferences Display */}
              <Box sx={{ mb: 2 }}>
                {[
                  { 
                    icon: '🕌',
                    en: 'Religious Values',
                    ar: 'القيم الدينية'
                  },
                  {
                    icon: '👨‍👩‍👧‍👦',
                    en: 'Family Traditions',
                    ar: 'التقاليد العائلية'
                  },
                  {
                    icon: '🎭',
                    en: 'Cultural Background',
                    ar: 'الخلفية الثقافية'
                  }
                ].map((item, index) => (
                  <Box
                    key={item.en}
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      background: theme.palette.background.paper,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                    }}
                  >
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: theme.palette.primary.light,
                        color: theme.palette.primary.main,
                        fontSize: '1.2rem',
                      }}
                    >
                      {item.icon}
                    </Box>
                    <Box>
                      <Typography variant="body2">{item.en}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </motion.div>
        </Box>
      </PhoneFrame>
    ),

    matching: (
      <PhoneFrame>
        <Box sx={{ p: 2, height: 'calc(100% - 44px)', overflow: 'auto' }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="subtitle1" sx={{ textAlign: 'center', mb: 1 }}>
                {language === 'ar' ? 'التوافق الثقافي' : 'Cultural Matching'}
              </Typography>

              {/* Match Animation */}
              <Box sx={{ position: 'relative', height: '200px', mb: 3 }}>
                <motion.div
                  animate={{
                    scale: [1, 1.1, 1],
                  }}
                  transition={{ duration: 1.5, repeat: Infinity }}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <FavoriteIcon sx={{ fontSize: '80px', color: theme.palette.primary.main }} />
                </motion.div>
              </Box>

              {/* Match Details */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {[
                  { label: 'Personality Match', value: '98%' },
                  { label: 'Common Interests', value: '12' },
                  { label: 'Compatibility Score', value: '9.5/10' },
                ].map((detail, index) => (
                  <motion.div
                    key={detail.label}
                    initial={{ x: -30, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: index * 0.2 }}
                  >
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: 2,
                        bgcolor: theme.palette.background.paper,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                      }}
                    >
                      <Typography variant="body2">{detail.label}</Typography>
                      <Typography
                        variant="body2"
                        color="primary.main"
                        sx={{ fontWeight: 'bold' }}
                      >
                        {detail.value}
                      </Typography>
                    </Box>
                  </motion.div>
                ))}
              </Box>

              {/* Action Button */}
              <motion.div
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    borderRadius: 2,
                    bgcolor: theme.palette.primary.main,
                    color: 'white',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Typography variant="button">Start Conversation</Typography>
                </Box>
              </motion.div>
            </Box>
          </motion.div>
        </Box>
      </PhoneFrame>
    ),

    communication: (
      <PhoneFrame>
        <Box sx={{ p: 2, height: 'calc(100% - 44px)', overflow: 'auto' }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
              {/* Chat Interface */}
              <Box sx={{ 
                bgcolor: 'background.paper',
                borderRadius: 2,
                overflow: 'hidden',
                boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
              }}>
                {/* Chat Header */}
                <Box sx={{ 
                  p: 2,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                  gap: 2
                }}>
                  <motion.div
                    animate={{
                      scale: [1, 1.1, 1],
                      opacity: [0.7, 1, 0.7],
                    }}
                    transition={{ duration: 2, repeat: Infinity }}
                  >
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        background: `linear-gradient(135deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontSize: '1.2rem'
                      }}
                    >
                      {language === 'ar' ? 'ن' : 'N'}
                    </Box>
                  </motion.div>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle2" sx={{ 
                      textAlign: language === 'ar' ? 'right' : 'left'
                    }}>
                      {language === 'ar' ? 'نور' : 'Noor'}
                    </Typography>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                      gap: 0.5
                    }}>
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          bgcolor: 'success.main'
                        }}
                      />
                      <Typography variant="caption" color="text.secondary">
                        {language === 'ar' ? 'متصل الن' : 'Online now'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Chat Messages */}
                <Box sx={{ p: 2, bgcolor: 'background.default' }}>
                  {[
                    {
                      type: 'ai',
                      content: language === 'ar' 
                        ? 'لاحظت أن لديكما اهتمام مشترك بالفن الإسلامي 🎨'
                        : "I noticed you both share an interest in Islamic art 🎨",
                    },
                    {
                      type: 'suggestion',
                      content: language === 'ar'
                        ? 'اقتراح محادثة: ما رأيك في مناقشة فن الخط العربي المفضل لديك؟'
                        : "Chat suggestion: How about discussing your favorite Arabic calligraphy style?",
                    },
                    {
                      type: 'received',
                      content: language === 'ar'
                        ? 'نعم! أحب خط الثلث كثيراً 😊'
                        : "Yes! I especially love Thuluth style 😊",
                    },
                  ].map((message, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.3 }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: message.type === 'received' 
                            ? (language === 'ar' ? 'flex-start' : 'flex-end')
                            : (language === 'ar' ? 'flex-end' : 'flex-start'),
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            maxWidth: '80%',
                            p: 2,
                            borderRadius: 3,
                            bgcolor: message.type === 'ai' 
                              ? 'warning.light'
                              : message.type === 'suggestion'
                              ? 'primary.light'
                              : 'primary.main',
                            color: message.type === 'received' ? 'white' : 'text.primary',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                          }}
                        >
                          <Typography 
                            variant="body2"
                            sx={{ 
                              direction: language === 'ar' ? 'rtl' : 'ltr',
                              textAlign: language === 'ar' ? 'right' : 'left',
                            }}
                          >
                            {message.content}
                          </Typography>
                        </Box>
                      </Box>
                    </motion.div>
                  ))}
                </Box>

                {/* Smart Features Bar */}
                <Box sx={{ 
                  p: 2,
                  borderTop: `1px solid ${theme.palette.divider}`,
                  display: 'flex',
                  gap: 2,
                  flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                }}>
                  {[
                    { icon: '🎯', label: language === 'ar' ? 'اقتراحات ذكية' : 'Smart Suggestions' },
                    { icon: '🌐', label: language === 'ar' ? 'ترجمة فورية' : 'Live Translation' },
                    { icon: '🛡️', label: language === 'ar' ? 'حماية المحادثة' : 'Chat Protection' },
                  ].map((feature, index) => (
                    <motion.div
                      key={index}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Box
                        sx={{
                          p: 1,
                          borderRadius: 2,
                          bgcolor: 'background.paper',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          cursor: 'pointer',
                          flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                        }}
                      >
                        <Box sx={{ fontSize: '1.2rem' }}>{feature.icon}</Box>
                        <Typography variant="caption">{feature.label}</Typography>
                      </Box>
                    </motion.div>
                  ))}
                </Box>
              </Box>
            </Box>
          </motion.div>
        </Box>
      </PhoneFrame>
    ),

    compatibility: (
      <PhoneFrame>
        <Box sx={{ p: 2, height: 'calc(100% - 44px)', overflow: 'auto' }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="subtitle1" sx={{ 
                textAlign: 'center',
                mb: 1
              }}>
                {language === 'ar' ? 'تقييم التوافق للزواج' : 'Marriage Compatibility Assessment'}
              </Typography>

              {/* Compatibility Score */}
              <Box sx={{ 
                p: 2,
                borderRadius: 2,
                bgcolor: 'background.paper',
                boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
                mb: 2
              }}>
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                  gap: 2
                }}>
                  <Box
                    sx={{
                      width: 60,
                      height: 60,
                      borderRadius: '50%',
                      background: `conic-gradient(${theme.palette.primary.main} ${92}%, ${theme.palette.grey[200]} 0)`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        width: 54,
                        height: 54,
                        borderRadius: '50%',
                        background: theme.palette.background.paper,
                      }
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      color="primary" 
                      sx={{ 
                        position: 'relative',
                        zIndex: 1,
                        fontWeight: 'bold'
                      }}
                    >
                      {language === 'ar' ? '٩٢٪' : '92%'}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle2" sx={{ 
                      textAlign: language === 'ar' ? 'right' : 'left',
                      mb: 0.5
                    }}>
                      {language === 'ar' ? 'توافق ممتاز' : 'Excellent Match'}
                    </Typography>
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{ 
                        display: 'block',
                        textAlign: language === 'ar' ? 'right' : 'left'
                      }}
                    >
                      {language === 'ar' 
                        ? 'تطابق عالي في القيم الأساسية والأهداف المستقبلية'
                        : 'High alignment in core values and future goals'}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Key Areas */}
              <Box sx={{ mb: 2 }}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    mb: 1.5,
                    textAlign: language === 'ar' ? 'right' : 'left'
                  }}
                >
                  {language === 'ar' ? 'المجالات الرئيسية للتوافق' : 'Key Compatibility Areas'}
                </Typography>
                {[
                  {
                    title: language === 'ar' ? 'القيم العائلية' : 'Family Values',
                    score: language === 'ar' ? '٩٥٪' : '95%',
                    icon: '👨‍👩‍👧‍👦',
                    details: language === 'ar' ? 'رؤية متوافقة لتربية الأسرة' : 'Aligned vision for family upbringing'
                  },
                  {
                    title: language === 'ar' ? 'الالتزام الديني' : 'Religious Commitment',
                    score: language === 'ar' ? '٩٨٪' : '98%',
                    icon: '🕌',
                    details: language === 'ar' ? 'مستوى متناسق من الالتزام الديني' : 'Harmonious level of religious observance'
                  },
                  {
                    title: language === 'ar' ? 'الأهداف المالية' : 'Financial Goals',
                    score: language === 'ar' ? '٨٨٪' : '88%',
                    icon: '💰',
                    details: language === 'ar' ? 'تخطي مالي متوافق للمستقبل' : 'Compatible financial planning for future'
                  }
                ].map((area, index) => (
                  <motion.div
                    key={area.title}
                    initial={{ x: language === 'ar' ? 50 : -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <Box
                      sx={{
                        mb: 1.5,
                        p: 2,
                        borderRadius: 2,
                        bgcolor: 'background.paper',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                        mb: 1
                      }}>
                        <Box sx={{ 
                          fontSize: '1.5rem',
                          mr: language === 'ar' ? 0 : 1.5,
                          ml: language === 'ar' ? 1.5 : 0
                        }}>
                          {area.icon}
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="body2" sx={{ 
                            textAlign: language === 'ar' ? 'right' : 'left'
                          }}>
                            {area.title}
                          </Typography>
                          <Typography 
                            variant="caption" 
                            color="text.secondary"
                            sx={{ 
                              display: 'block',
                              textAlign: language === 'ar' ? 'right' : 'left'
                            }}
                          >
                            {area.details}
                          </Typography>
                        </Box>
                        <Typography 
                          variant="body2" 
                          color="primary.main"
                          sx={{ fontWeight: 'bold' }}
                        >
                          {area.score}
                        </Typography>
                      </Box>
                    </Box>
                  </motion.div>
                ))}
              </Box>

              {/* Recommendation */}
              <Box
                sx={{
                  mt: 'auto',
                  p: 2,
                  borderRadius: 2,
                  bgcolor: theme.palette.primary.light,
                  color: theme.palette.primary.main,
                }}
              >
                <Typography 
                  variant="body2"
                  sx={{ 
                    textAlign: language === 'ar' ? 'right' : 'left',
                    direction: language === 'ar' ? 'rtl' : 'ltr',
                    fontWeight: 'medium'
                  }}
                >
                  {language === 'ar' 
                    ? 'توصية: هذا التطابق يظهر إمكانية عالية لعلاقة ناجحة وطويلة الأمد'
                    : 'Recommendation: This match shows high potential for a successful, long-term relationship'}
                </Typography>
              </Box>
            </Box>
          </motion.div>
        </Box>
      </PhoneFrame>
    ),
  };

  return (
    <Box
      sx={{
        position: 'relative',
        ...(isMobile && {
          position: 'sticky',
          top: '20px',
          zIndex: 1,
          mb: -30,
        })
      }}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key={feature}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.3 }}
        >
          {visuals[feature as keyof typeof visuals] || null}
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};