import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  TextField,
  Paper,
  Collapse,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from '../Button';
import { useLanguage } from '../../contexts/LanguageContext';
import { signInWithGoogle, addToWaitlist } from '../../utils/firebase';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import styles from './WaitlistModal.module.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface WaitlistModalProps {
  open: boolean;
  onClose: () => void;
}

// Define the view type as a union of string literals
type ViewType = 'main' | 'phone' | 'email';

// Create an object for view constants
const VIEWS: Record<'MAIN' | 'PHONE' | 'EMAIL', ViewType> = {
  MAIN: 'main',
  PHONE: 'phone',
  EMAIL: 'email',
} as const;


const GOOGLE_LOGO_URL = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OCA0OCI+PHBhdGggZmlsbD0iI0VBNDMzNSIgZD0iTTI0IDkuNWMzLjU0IDAgNi43MSAxLjIyIDkuMjEgMy42bDYuODUtNi44NUMzNS45IDIuMzggMzAuNDcgMCAyNCAwIDE0LjYyIDAgNi41MSA1LjM4IDIuNTYgMTMuMjJsNy45OCA2LjE5QzEyLjQzIDEzLjcyIDE3Ljc0IDkuNSAyNCA5LjV6Ii8+PHBhdGggZmlsbD0iIzQyODVGNCIgZD0iTTQ2Ljk4IDI0LjU1YzAtMS41Ny0uMTUtMy4wOS0uMzgtNC41NUgyNHY5LjAyaDEyLjk0Yy0uNTggMi45Ni0yLjI2IDUuNDgtNC43OCA3LjE4bDcuNzMgNmM0LjUxLTQuMTggNy4wOS0xMC4zNiA3LjA5LTE3LjY1eiIvPjxwYXRoIGZpbGw9IiNGQkJDMDUiIGQ9Ik0xMC41MyAyOC41OWMtLjQ4LTEuNDUtLjc2LTIuOTktLjc2LTQuNTlzLjI3LTMuMTQuNzYtNC41OWwtNy45OC02LjE5Qy45MiAxNi40NiAwIDIwLjEyIDAgMjRjMCAzLjg4LjkyIDcuNTQgMi41NiAxMC43OGw3Ljk3LTYuMTl6Ii8+PHBhdGggZmlsbD0iIzM0QTg1MyIgZD0iTTI0IDQ4YzYuNDggMCAxMS45My0yLjEzIDE1Ljg5LTUuODFsLTcuNzMtNmMtMi4xNSAxLjQ1LTQuOTIgMi4zLTguMTYgMi4zLTYuMjYgMC0xMS41Ny00LjIyLTEzLjQ3LTkuOTFsLTcuOTggNi4xOUM2LjUxIDQyLjYyIDE0LjYyIDQ4IDI0IDQ4eiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoNDh2NDhIMHoiLz48L3N2Zz4=';

const StatusMessage = ({ type, message }: { type: 'success' | 'error', message: string }) => (
  <Stack 
    spacing={2} 
    alignItems="center" 
    justifyContent="center" 
    sx={{ 
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: type === 'success' 
        ? 'rgba(237, 247, 237, 0.95)' // Light green background for success
        : 'rgba(253, 237, 237, 0.95)', // Light red background for error
      backdropFilter: 'blur(8px)',
      zIndex: 10,
      px: 3,
      borderRadius: '16px',
    }}
  >
    {type === 'success' ? (
      <CheckCircleOutlineIcon 
        sx={{ 
          fontSize: 64,
          color: '#2E7D32', // Dark green color
          animation: 'popIn 0.5s cubic-bezier(0.34, 1.56, 0.64, 1)',
          '@keyframes popIn': {
            '0%': { 
              transform: 'scale(0)',
              opacity: 0,
            },
            '100%': { 
              transform: 'scale(1)',
              opacity: 1,
            }
          }
        }} 
      />
    ) : (
      <ErrorOutlineIcon 
        sx={{ 
          fontSize: 64,
          color: '#D32F2F', // Dark red color
          animation: 'shake 0.5s cubic-bezier(.36,.07,.19,.97)',
          '@keyframes shake': {
            '0%, 100%': { transform: 'translateX(0)' },
            '20%, 60%': { transform: 'translateX(-5px)' },
            '40%, 80%': { transform: 'translateX(5px)' }
          }
        }} 
      />
    )}
    <Typography 
      variant="h6" 
      textAlign="center"
      sx={{ 
        color: type === 'success' ? '#1B5E20' : '#C62828', // Darker text colors
        animation: 'fadeIn 0.5s ease-out',
        '@keyframes fadeIn': {
          '0%': { 
            opacity: 0,
            transform: 'translateY(10px)',
          },
          '100%': { 
            opacity: 1,
            transform: 'translateY(0)',
          }
        }
      }}
    >
      {message}
    </Typography>
  </Stack>
);

export const WaitlistModal: React.FC<WaitlistModalProps> = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const { language } = useLanguage();
  const isRTL = language === 'ar';
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [selectedView, setSelectedView] = useState<ViewType>(VIEWS.MAIN);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone: string) => {
    try {
      // Just check if:
      // 1. Has a plus sign
      // 2. Has at least 3 digits after the plus
      // 3. Contains only numbers after the plus
      
      if (!phone.startsWith('+')) {
        return false;
      }

      const numberPart = phone.slice(1); // Remove the + sign
      return numberPart.length >= 3 && /^\d+$/.test(numberPart);

    } catch (error) {
      console.error('Phone validation error:', error);
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    
    setLoading(true);
    setError(null);
    setSuccessMessage(null);
    setValidationError(null);
    
    try {
      if (selectedView === VIEWS.EMAIL) {
        if (!email) {
          throw new Error(isRTL ? 'يرجى إدخال البريد الإلكتروني' : 'Please enter an email address');
        }
        if (!isValidEmail(email)) {
          throw new Error(isRTL ? 'يرجى إدخال بريد إلكتروني صحيح' : 'Please enter a valid email address');
        }
        
        await addToWaitlist({
          email,
          provider: 'email',
          phone: null,
        });
        
        setSuccessMessage(isRTL ? 'تم التسجيل بنجاح! سنتواصل معك قريباً' : 'Successfully registered! We\'ll be in touch soon');
        setEmail('');
        
        // Auto close on success after animation
        setTimeout(() => {
          onClose();
        }, 1500);
        
      } else if (selectedView === VIEWS.PHONE) {
        if (!phone || !countryCode) {
          throw new Error(isRTL ? 'يرجى إدخال رقم الهاتف' : 'Please enter a phone number');
        }

        // Simple concatenation of country code and phone
        const fullPhone = countryCode + phone;
        
        if (!isValidPhone(fullPhone)) {
          setValidationError(isRTL ? 'يرجى إدخال رقم هاتف صحيح' : 'Please enter a valid phone number');
          setLoading(false);
          return;
        }
        
        await addToWaitlist({
          phone: fullPhone,
          provider: 'phone',
          email: null,
        });
        
        setSuccessMessage(isRTL ? 'تم التسجيل بنجاح! سنتواصل معك قريباً' : 'Successfully registered! We\'ll be in touch soon');
        setPhone('');
        setCountryCode('');
        
        // Auto close on success after animation
        setTimeout(() => {
          onClose();
        }, 1500);
      }

    } catch (error) {
      console.error('Error submitting:', error);
      if (error instanceof Error) {
        if (error.message.includes('valid')) {
          setValidationError(error.message);
        } else {
          setError(error.message);
          // Reset form on error
          setSelectedView(VIEWS.MAIN);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignup = async () => {
    setLoading(true);
    setError(null);
    setSuccessMessage(null);
    
    try {
      const result = await signInWithGoogle();
      const user = result.user;
      
      await addToWaitlist({
        email: user.email || '',
        name: user.displayName || undefined,
        provider: 'google',
      });
      
      setSuccessMessage(isRTL ? 'تم التسجيل بنجاح! سنتواصل معك قريباً' : 'Successfully registered! We\'ll be in touch soon');
      
      // Auto close on success after animation
      setTimeout(() => {
        onClose();
      }, 1500);
      
    } catch (error) {
      console.error('Error with Google signup:', error);
      setError(error instanceof Error ? error.message : 'An error occurred with Google sign-in');
      // Reset form on error
      setSelectedView(VIEWS.MAIN);
    } finally {
      setLoading(false);
    }
  };

  const optionCardStyles = {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: isRTL ? '0 16px' : { xs: '0 12px', sm: '0 16px' },
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: '12px',
    cursor: 'pointer',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    background: 'white',
    '& .option-text': {
      flex: 1,
      textAlign: 'center',
    },
    '&:hover': {
      backgroundColor: '#f8f8f8',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
    },
  };

  const optionIconStyles = {
    fontSize: 24,
    color: '#666',
    marginRight: '12px',
    flexShrink: 0,
  };

  const paperStyles = {
    p: { xs: 2, sm: 2.5 },
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: '12px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    background: 'transparent',
    backdropFilter: 'blur(8px)',
    '& .icon': {
      transition: 'transform 0.3s ease',
    },
  };

  const expandedPaperStyles = {
    ...paperStyles,
    borderColor: 'primary.main',
    background: 'transparent',
    boxShadow: '0 8px 24px rgba(255, 75, 145, 0.12)',
    position: 'relative',
    zIndex: 2,
    '& .icon': {
      transform: 'scale(1.1)',
    },
  };

  const collapsedPaperStyles = {
    ...paperStyles,
    height: { xs: '60px', sm: '72px' },
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.05)',
    },
  };

  const commonIconStyles = {
    fontSize: { xs: 20, sm: 24 },
    color: 'primary.main',
    transition: 'all 0.2s',
    flexShrink: 0,
  };

  const titleStyles = {
    fontWeight: 600,
    fontSize: { xs: '0.875rem', sm: '1rem' },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    background: 'transparent',
    color: 'text.primary',
    position: 'relative',
    zIndex: 2,
  };

  const textFieldStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      backgroundColor: 'white',
      '& fieldset': {
        top: 0,
        '& legend': {
          display: 'none',
        },
      },
    },
    '& .MuiInputLabel-root': {
      right: isRTL ? 'unset' : 'unset',
      left: isRTL ? 'unset' : 'unset',
      transformOrigin: isRTL ? 'right' : 'left',
      textAlign: isRTL ? 'right' : 'left',
      inset: isRTL ? '-6px 24px auto auto' : '-6px auto auto 14px',
      marginRight: isRTL ? '8px' : 0,
      backgroundColor: 'transparent !important',
      '&.MuiInputLabel-shrink': {
        transform: isRTL 
          ? 'translate(0, -9px) scale(0.75)'
          : 'translate(0, -9px) scale(0.75)',
        backgroundColor: 'transparent !important',
        padding: '0 4px',
        '& .MuiInputLabel-asterisk': {
          color: 'error.main',
        },
      },
    },
    '& .MuiInputBase-input': {
      textAlign: isRTL ? 'right' : 'left',
      direction: isRTL ? 'rtl' : 'ltr',
      paddingRight: isRTL ? '24px' : '14px',
      paddingLeft: isRTL ? '14px' : '14px',
    },
  };

  const snackbarStyles = {
    '& .MuiAlert-root': {
      borderRadius: '12px',
      backdropFilter: 'blur(10px)',
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      border: '1px solid',
      width: { xs: '90vw', sm: '400px' },
    },
    '& .MuiAlert-standardSuccess': {
      borderColor: 'success.light',
      background: 'linear-gradient(135deg, rgba(255, 75, 145, 0.05) 0%, rgba(108, 34, 166, 0.05) 100%)',
      '& .MuiAlert-icon': {
        color: '#FF4B91',
      },
    },
    '& .MuiAlert-standardError': {
      borderColor: 'error.light',
      background: 'linear-gradient(135deg, rgba(255, 75, 145, 0.05) 0%, rgba(108, 34, 166, 0.05) 100%)',
      '& .MuiAlert-icon': {
        color: '#FF4B91',
      },
    },
    '& .MuiAlert-message': {
      fontSize: '0.95rem',
      fontWeight: 500,
    },
    '& .MuiAlert-action': {
      paddingTop: 0,
    },
  };

  const phoneInput = (
    <Stack 
      direction="row"
      spacing={0}
      alignItems="center"
      sx={{
        flexDirection: 'row !important',
        direction: 'ltr !important',
        gap: '8px !important',
        width: { xs: '80%', sm: '100%' },
        mx: 'auto',
        mb: { xs: 2, sm: 0 },
        transform: { 
          xs: isRTL ? 'translateX(-40px)' : 'none',
          sm: 'none' 
        },
        ml: { xs: isRTL ? '12px' : 'auto', sm: 'auto' },
        '& > *': {
          direction: 'ltr !important',
        }
      }}
    >
      <Box sx={{ 
        width: isRTL ? '90px' : '85px',
        flexShrink: 0,
        marginRight: '8px',
        '& .react-tel-input': {
          width: '100%',
          direction: 'ltr !important',
        },
      }}>
        <PhoneInput
          country={'sa'}
          value={countryCode}
          onChange={(value, data: any) => {
            // Always set the country code with plus sign
            const dialCode = data.dialCode;
            const formattedCode = dialCode.startsWith('+') ? dialCode : `+${dialCode}`;
            setCountryCode(formattedCode);
          }}
          enableSearch
          searchPlaceholder="Search countries..."
          containerClass={styles.phoneInputCountry}
          inputProps={{
            name: 'country-code',
            required: true,
            dir: 'ltr',
            style: {
              width: '100%',
              height: '48px',
              fontSize: '14px',
              textAlign: 'left',
              paddingLeft: '44px',
            },
            readOnly: true,
          }}
          buttonStyle={{
            backgroundColor: 'transparent',
            padding: '0 4px',
          }}
          countryCodeEditable={false}
          enableAreaCodes={false}
          disableCountryCode={false}
          disableDropdown={false}
          specialLabel=""
          prefix="+"
        />
      </Box>
      <TextField
        fullWidth
        placeholder="Phone Number"
        value={phone}
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, '');
          setPhone(value);
        }}
        variant="outlined"
        error={!!validationError}
        helperText={validationError}
        inputProps={{
          dir: 'ltr',
          style: { 
            textAlign: 'left',
            paddingLeft: '14px'
          }
        }}
        sx={{
          flex: '1 1 auto',
          minWidth: { 
            xs: isRTL ? '140px' : '150px',
            sm: '200px'
          },
          maxWidth: { 
            xs: isRTL ? '140px' : '160px',
            sm: 'none'
          },
          direction: 'ltr !important',
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            backgroundColor: 'white',
            height: '48px',
          },
        }}
      />
    </Stack>
  );

  const emailInput = (
    <TextField
      fullWidth
      label={isRTL ? 'البريد الإلكتروني' : 'Email'}
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      variant="outlined"
      error={!!validationError}
      helperText={validationError}
      sx={textFieldStyles}
      onClick={(e) => e.stopPropagation()}
    />
  );

  const BackButton = () => (
    <IconButton 
      onClick={() => setSelectedView(VIEWS.MAIN)}
      sx={{ 
        position: 'absolute',
        top: 16,
        [isRTL ? 'right' : 'left']: 16,
        color: 'text.secondary',
      }}
    >
      {isRTL ? <ArrowForwardIcon /> : <ArrowBackIcon />}
    </IconButton>
  );

  // Create a helper function for view comparison
  const isView = (current: ViewType, target: ViewType): boolean => current === target;

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          // Prevent closing when showing status message
          if (successMessage || error) return;
          onClose();
        }}
        maxWidth="sm"
        fullWidth
        dir={isRTL ? 'rtl' : 'ltr'}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            p: { xs: 1, sm: 2 },
            backgroundImage: 'linear-gradient(135deg, rgba(255, 75, 145, 0.05) 0%, rgba(108, 34, 166, 0.05) 100%)',
            maxWidth: { xs: '95%', sm: '500px' },
            m: { xs: 1, sm: 2 },
            position: 'relative',
            minHeight: selectedView !== VIEWS.MAIN ? { xs: '450px', sm: '400px' } : 'auto',
            overflow: 'visible',
          },
        }}
      >
        <DialogContent sx={{ 
          overflow: 'visible',
          pb: { xs: 8, sm: 6 },
          position: 'relative',
        }}>
          {(successMessage || error) && (
            <StatusMessage 
              type={successMessage ? 'success' : 'error'} 
              message={successMessage || error || ''}
            />
          )}
          
          {isView(selectedView, VIEWS.MAIN) ? (
            <Stack spacing={3} sx={{ mt: 2, px: { xs: 1, sm: 2 } }}>
              <Typography variant="h5" textAlign="center" sx={{ mb: 1, fontWeight: 600 }}>
                {isRTL 
                  ? 'اختر طريقة التسجيل'
                  : 'Join the Waitlist!'}
              </Typography>
              
              <Typography variant="body1" color="text.secondary" textAlign="center" sx={{ mb: 2 }}>
                {isRTL 
                  ? 'كن أول من يجرب مهرة عند إطلاقها! سجل في قائمة الانتظار اليوم.'
                  : 'Be the first to experience Mahra when we launch! Sign up for our waitlist today.'}
              </Typography>

              <Stack spacing={2} sx={{ 
                maxWidth: '400px', 
                mx: 'auto', 
                width: '100%',
                mb: { xs: 10, sm: 8 },
              }}>
                {/* Google Sign-in Button */}
                <Paper
                  elevation={0}
                  onClick={handleGoogleSignup}
                  sx={optionCardStyles}
                >
                  <Box 
                    component="img" 
                    src={GOOGLE_LOGO_URL} 
                    sx={{ 
                      height: 24,
                      width: 24,
                      mr: isRTL ? 0 : 1.5,
                      ml: isRTL ? 1.5 : 0,
                      flexShrink: 0,
                      order: isRTL ? 1 : 0,
                    }} 
                  />
                  <Typography 
                    variant="body1" 
                    className="option-text"
                    sx={{ 
                      color: '#000000DE',
                      fontSize: isRTL ? { xs: '12px', sm: '15px' } : { xs: '14px', sm: '15px' },
                      fontWeight: 500,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      paddingLeft: isRTL ? '24px' : { xs: '4px', sm: '8px' },
                      paddingRight: isRTL ? { xs: '8px', sm: '24px' } : { xs: '12px', sm: '24px' },
                    }}
                  >
                    {isRTL ? 'المتابعة باستخدام Google' : 'Continue with Google'}
                  </Typography>
                </Paper>

                {/* Email Option */}
                <Paper
                  elevation={0}
                  onClick={() => setSelectedView(VIEWS.EMAIL)}
                  sx={optionCardStyles}
                >
                  <EmailIcon sx={{ 
                    ...optionIconStyles,
                    order: isRTL ? 1 : 0,
                    mr: isRTL ? 0 : 1.5,
                    ml: isRTL ? 1.5 : 0,
                  }} />
                  <Typography 
                    variant="body1"
                    className="option-text"
                    sx={{ 
                      color: '#000000DE',
                      fontSize: '15px',
                      fontWeight: 500,
                    }}
                  >
                    {isRTL ? 'التسجيل بالبريد الإلكتروني' : 'Continue with Email'}
                  </Typography>
                </Paper>

                {/* Phone Option */}
                <Paper
                  elevation={0}
                  onClick={() => setSelectedView(VIEWS.PHONE)}
                  sx={{
                    ...optionCardStyles,
                    mb: { xs: 2, sm: 0 },
                  }}
                >
                  <PhoneIcon sx={{ 
                    ...optionIconStyles,
                    order: isRTL ? 1 : 0,
                    mr: isRTL ? 0 : 1.5,
                    ml: isRTL ? 1.5 : 0,
                  }} />
                  <Typography 
                    variant="body1"
                    className="option-text"
                    sx={{ 
                      color: '#000000DE',
                      fontSize: '15px',
                      fontWeight: 500,
                    }}
                  >
                    {isRTL ? 'التسجيل برقم الهاتف' : 'Continue with Phone'}
                  </Typography>
                </Paper>
              </Stack>
            </Stack>
          ) : isView(selectedView, VIEWS.PHONE) ? (
            <Stack spacing={3} sx={{ mt: 4, px: { xs: 1, sm: 2 } }}>
              <BackButton />
              <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
                {isRTL ? 'التسجيل برقم الهاتف' : 'Sign up with Phone'}
              </Typography>
              {phoneInput}
              <Button
                variant="primary"
                fullWidth
                onClick={handleSubmit}
                disabled={loading}
                sx={{
                  height: '48px',
                  borderRadius: '12px',
                  background: 'linear-gradient(45deg, #FF4B91 30%, #6C22A6 90%)',
                  boxShadow: '0 4px 16px rgba(255, 75, 145, 0.24)',
                  transition: 'all 0.3s',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 8px 24px rgba(255, 75, 145, 0.32)',
                  },
                }}
              >
                {loading ? (
                  isRTL ? 'جاري التحميل...' : 'Loading...'
                ) : (
                  isRTL ? 'انضم الآن' : 'Join Now'
                )}
              </Button>
            </Stack>
          ) : isView(selectedView, VIEWS.EMAIL) ? (
            <Stack spacing={3} sx={{ mt: 4, px: { xs: 1, sm: 2 } }}>
              <BackButton />
              <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
                {isRTL ? 'التسجيل بالبريد الإلكتروني' : 'Sign up with Email'}
              </Typography>
              {emailInput}
              <Button
                variant="primary"
                fullWidth
                onClick={handleSubmit}
                disabled={loading}
                sx={{
                  height: '48px',
                  borderRadius: '12px',
                  background: 'linear-gradient(45deg, #FF4B91 30%, #6C22A6 90%)',
                  boxShadow: '0 4px 16px rgba(255, 75, 145, 0.24)',
                  transition: 'all 0.3s',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 8px 24px rgba(255, 75, 145, 0.32)',
                  },
                }}
              >
                {loading ? (
                  isRTL ? 'جاري التحميل...' : 'Loading...'
                ) : (
                  isRTL ? 'انضم الآن' : 'Join Now'
                )}
              </Button>
            </Stack>
          ) : null}

          <Typography 
            variant="caption" 
            color="text.secondary" 
            align="center"
            sx={{
              px: 2,
              opacity: 0.8,
              position: 'absolute',
              bottom: { xs: 20, sm: 24 },
              left: 0,
              right: 0,
              maxWidth: '100%',
              mx: 'auto',
              lineHeight: 1.5,
            }}
          >
            {isRTL 
              ? 'بالتسجيل، فإنك توافق على شروط الخدمة وسياسة الخصوصية'
              : 'By signing up, you agree to our Terms of Service and Privacy Policy'}
          </Typography>
        </DialogContent>
      </Dialog>

      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={snackbarStyles}
      >
        <Alert 
          severity="error" 
          onClose={() => setError(null)}
          sx={{
            alignItems: 'center',
            '& .MuiAlert-message': {
              textAlign: isRTL ? 'right' : 'left',
              direction: isRTL ? 'rtl' : 'ltr',
            },
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>
            {error}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
}; 