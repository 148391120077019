export const cookiesContentEn = `Cookie Policy
Last Updated: 14/01/2025

1. Introduction
Mahra (“we,” “us,” “our”), operated by Mahra.io, a UK-based company, uses cookies and similar technologies to improve user experience, analyze performance, and provide personalized content. This Cookie Policy explains how and why we use these technologies, and how you can manage them.

2. What Are Cookies?
Cookies are small text files stored on your device when you visit certain websites. We also use other tracking technologies like pixels, web beacons, and mobile identifiers to gather analytical data and enhance our services.

3. Types of Cookies We Use
Essential Cookies: Required for Mahra’s basic features (e.g., maintaining your logged-in status).
Performance and Analytics Cookies: Help us understand user behavior and enhance functionality.
Functionality Cookies: Remember your preferences, like language or region.
Advertising/Targeting Cookies (If Applicable): Track browsing habits to show relevant ads or content, if we employ advertising features.
4. How We Use Cookies
Authentication: Keep you logged in to your account.
Customization: Store settings to provide a personalized experience.
Analytics: Analyze user engagement and improve overall performance.
Advertising (If Applicable): Deliver targeted ads based on user interests and measure ad effectiveness.
5. Third-Party Cookies
Some third parties may use cookies to provide services on our behalf (e.g., analytics or advertising). These third parties have their own cookie and privacy policies, which we encourage you to review.

6. Managing Cookies
Most web browsers allow you to control or disable cookies via settings or preferences. Disabling cookies may limit your ability to access certain features of Mahra.

7. Changes to This Cookie Policy
We may revise this Cookie Policy to reflect changes in our practices or for legal reasons. We will post the updated policy on Mahra. Your continued use of Mahra after any changes indicates acceptance of the revised Cookie Policy.

8. Contact Us
If you have questions about our use of cookies, please get in touch at:
Email: [support@mahra.io]
Address: Mahra.io, UK
`; 