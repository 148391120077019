import React, { useState } from 'react';
import { Box, Container, Typography, useTheme, Grid } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { SectionTitle } from '../SectionTitle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GroupIcon from '@mui/icons-material/Group';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import InterestsIcon from '@mui/icons-material/Interests';
import TuneIcon from '@mui/icons-material/Tune';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VerifiedIcon from '@mui/icons-material/Verified';
import PersonIcon from '@mui/icons-material/Person';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import GppGoodIcon from '@mui/icons-material/GppGood';
import SearchIcon from '@mui/icons-material/Search';
import PeopleIcon from '@mui/icons-material/People';
import HandshakeIcon from '@mui/icons-material/Handshake';
import MessageIcon from '@mui/icons-material/Message';
import SecurityIcon from '@mui/icons-material/Security';
import { SectionTransition } from '../SectionTransition';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../constants/translations';

interface StepDetail {
  icon: React.ReactNode;
  title: string;
  description: string;
}

interface Step {
  icon: React.ReactNode;
  title: string;
  description: string;
  color: string;
  stepDetails: StepDetail[];
}

const getArabicNumber = (num: number): string=> {
  const arabicNumbers = ['١', '٢', '٣', '٤', '٥', '٦'];
  return arabicNumbers[num - 1] || num.toString();
};

export const HowItWorks: React.FC = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const { language } = useLanguage();
  const t = translations[language].howItWorks;

  const getStepDetails = (stepKey: 'profile'| 'preferences' | 'matches' | 'chat') => {
    const details = t.steps[stepKey].details;
    
    switch(stepKey) {
      case 'profile':
        return [
          {
            icon: <PhotoCameraIcon />,
            title: details.photoVerification.title,
            description: details.photoVerification.description
          },
          {
            icon: <PersonIcon />,
            title: details.interestSelection.title,
            description: details.interestSelection.description
          },
          {
            icon: <SchoolIcon />,
            title: details.matchPreferences.title,
            description: details.matchPreferences.description
          },
          {
            icon: <WorkIcon />,
            title: details.personalityQuiz.title,
            description: details.personalityQuiz.description
          },
          {
            icon: <FamilyRestroomIcon />,
            title: details.locationSettings.title,
            description: details.locationSettings.description
          },
          {
            icon: <GppGoodIcon />,
            title: details.profileReview.title,
            description: details.profileReview.description
          }
        ];
        
      case 'preferences':
        return [
          {
            icon: <TuneIcon />,
            title: details.photoVerification.title,
            description: details.photoVerification.description
          },
          {
            icon: <LocationOnIcon />,
            title: details.interestSelection.title,
            description: details.interestSelection.description
          },
          {
            icon: <SchoolIcon />,
            title: details.matchPreferences.title,
            description: details.matchPreferences.description
          },
          {
            icon: <WorkIcon />,
            title: details.personalityQuiz.title,
            description: details.personalityQuiz.description
          },
          {
            icon: <InterestsIcon />,
            title: details.locationSettings.title,
            description: details.locationSettings.description
          },
          {
            icon: <VolunteerActivismIcon />,
            title: details.profileReview.title,
            description: details.profileReview.description
          }
        ];
        
      case 'matches':
        return [
          {
            icon: <SearchIcon />,
            title: details.photoVerification.title,
            description: details.photoVerification.description
          },
          {
            icon: <PeopleIcon />,
            title: details.interestSelection.title,
            description: details.interestSelection.description
          },
          {
            icon: <HandshakeIcon />,
            title: details.matchPreferences.title,
            description: details.matchPreferences.description
          },
          {
            icon: <MessageIcon />,
            title: details.personalityQuiz.title,
            description: details.personalityQuiz.description
          },
          {
            icon: <SecurityIcon />,
            title: details.locationSettings.title,
            description: details.locationSettings.description
          },
          {
            icon: <VerifiedIcon />,
            title: details.profileReview.title,
            description: details.profileReview.description
          }
        ];
        
      case 'chat':
        return [
          {
            icon: <MessageIcon />,
            title: details.photoVerification.title,
            description: details.photoVerification.description
          },
          {
            icon: <PeopleIcon />,
            title: details.interestSelection.title,
            description: details.interestSelection.description
          },
          {
            icon: <HandshakeIcon />,
            title: details.matchPreferences.title,
            description: details.matchPreferences.description
          },
          {
            icon: <SecurityIcon />,
            title: details.personalityQuiz.title,
            description: details.personalityQuiz.description
          },
          {
            icon: <FavoriteIcon />,
            title: details.locationSettings.title,
            description: details.locationSettings.description
          },
          {
            icon: <GppGoodIcon />,
            title: details.profileReview.title,
            description: details.profileReview.description
          }
        ];
    }
  };

  const steps: Step[] = [
    {
      icon: <AccountCircleIcon sx={{ fontSize: 40 }} />,
      title: t.steps.profile.title,
      description: t.steps.profile.description,
      color: theme.palette.primary.main,
      stepDetails: getStepDetails('profile')
    },
    {
      icon: <FilterAltIcon sx={{ fontSize: 40 }} />,
      title: t.steps.preferences.title,
      description: t.steps.preferences.description,
      color: theme.palette.secondary.main,
      stepDetails: getStepDetails('preferences')
    },
    {
      icon: <GroupIcon sx={{ fontSize: 40 }} />,
      title: t.steps.matches.title,
      description: t.steps.matches.description,
      color: "#FF8DC7",
      stepDetails: getStepDetails('matches')
    },
    {
      icon: <FavoriteIcon sx={{ fontSize: 40 }} />,
      title: t.steps.chat.title,
      description: t.steps.chat.description,
      color: "#6C22A6",
      stepDetails: getStepDetails('chat')
    }
  ];

  return (
    <Box
      component="section"
      sx={{
        py: { xs: 6, md: 10 },
        backgroundColor: 'background.paper',
        position: 'relative',
        overflow: 'hidden',
        direction: language === 'ar' ? 'rtl' : 'ltr',
      }}
    >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <SectionTitle
            title={t.sectionTitle}
            subtitle={t.sectionSubtitle}
            align="center"
          />
        </motion.div>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 3, md: 4 },
            mt: { xs: 3, md: 6 },
            height: { md: '600px' },
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', md: '30%' },
              display: 'flex',
              flexDirection: { xs: 'row', md: 'column' },
              gap: 2,
              overflowX: { xs: 'auto', md: 'visible' },
              pb: { xs: 2, md: 0 },
              height: '100%',
              justifyContent: 'space-between',
            }}
          >
            {steps.map((step, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setActiveStep(index)}
                style={{ flex: 1 }}
              >
                <Box
                  sx={{
                    p: 3,
                    borderRadius: '16px',
                    backgroundColor: activeStep === index 
                      ? `${step.color}20`
                      : 'background.default',
                    border: '1px solid',
                    borderColor: activeStep === index 
                      ? step.color
                      : 'divider',
                    cursor: 'pointer',
                    minWidth: { xs: '200px', md: 'auto' },
                    transition: 'all 0.3s ease',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        p: 1,
                        borderRadius: '12px',
                        backgroundColor: `${step.color}20`,
                        color: step.color,
                      }}
                    >
                      {step.icon}
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 600,
                          color: activeStep === index 
                            ? step.color
                            : 'text.primary',
                        }}
                      >
                        {step.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          display: { xs: 'none', md: 'block' },
                        }}
                      >
                        {step.description}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </motion.div>
            ))}
          </Box>

          <Box
            sx={{
              flex: 1,
              height: '100%',
            }}
          >
            <AnimatePresence mode="wait">
              <motion.div
                key={activeStep}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
                style={{ height: '100%' }}
              >
                <Box
                  sx={{
                    p: 4,
                    borderRadius: '24px',
                    backgroundColor: `${steps[activeStep].color}10`,
                    border: '1px solid',
                    borderColor: steps[activeStep].color,
                    height: '100%',
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      right: '-10%',
                      bottom: '-10%',
                      width: '50%',
                      height: '50%',
                      opacity: 0.05,
                      background: `radial-gradient(circle, ${steps[activeStep].color} 0%, transparent 70%)`,
                    }}
                  />

                  <Box sx={{ position: 'relative', zIndex: 1 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3,
                      }}
                    >
                      <Box
                        sx={{
                          p: 1.5,
                          borderRadius: '16px',
                          backgroundColor: `${steps[activeStep].color}20`,
                          color: steps[activeStep].color,
                        }}
                      >
                        {steps[activeStep].icon}
                      </Box>
                      <Box>
                        <Typography
                          variant="h5"
                          sx={{
                            color: steps[activeStep].color,
                            fontWeight: 600,
                            mb: 0.5,
                          }}
                        >
                          {steps[activeStep].title}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'text.secondary',
                          }}
                        >
                          {steps[activeStep].description}
                        </Typography>
                      </Box>
                    </Box>

                    <Grid container spacing={2.5}>
                      {steps[activeStep].stepDetails.map((detail, index) => (
                        <Grid item xs={12} sm={6} key={detail.title}>
                          <motion.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.1 }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 2,
                                p: 2.5,
                                borderRadius: '16px',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                                transition: 'all 0.3s ease',
                                height: '100%',
                                position: 'relative',
                                '&:hover': {
                                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                                  transform: 'translateY(-4px)',
                                  boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: -10,
                                  left: -10,
                                  width: 28,
                                  height: 28,
                                  borderRadius: '50%',
                                  backgroundColor: steps[activeStep].color,
                                  color: 'white',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontSize: '0.875rem',
                                  fontWeight: 600,
                                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                }}
                              >
                                {language === 'ar' ? getArabicNumber(index +1) : (index + 1)}
                              </Box>

                              <Box
                                sx={{
                                  p: 1.5,
                                  borderRadius: '12px',
                                  backgroundColor: `${steps[activeStep].color}20`,
                                  color: steps[activeStep].color,
                                  height: 'fit-content',
                                }}
                              >
                                {detail.icon}
                              </Box>

                              <Box sx={{ flex: 1 }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontWeight: 600,
                                    color: 'text.primary',
                                    mb: 0.5,
                                  }}
                                >
                                  {detail.title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: 'text.secondary',
                                    lineHeight: 1.6,
                                  }}
                                >
                                  {detail.description}
                                </Typography>
                              </Box>
                            </Box>
                          </motion.div>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </motion.div>
            </AnimatePresence>
          </Box>
        </Box>
      </Container>

      <Box 
        sx={{ 
          position: 'relative', 
          mt: { xs: 12, md: 16 },
          mb: -2,
        }}
      >
        <SectionTransition 
          color={theme.palette.background.default}
          pattern="wave"
        />
      </Box>
    </Box>
  );
};

export default HowItWorks; 