import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from '@mui/material/styles';
import { HomePage } from '../../screens/HomePage';
import { LanguageProvider } from '../../contexts/LanguageContext';
import Terms from '../Terms/Terms';
import Privacy from '../Privacy/Privacy';
import Cookies from '../Cookies/Cookies';

// Add interface for gesture event
interface GestureEvent extends Event {
  scale: number;
}

// Create basic theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#FF4B91',
    },
    secondary: {
      main: '#6C22A6',
    },
  },
  typography: {
    fontFamily: 'Poppins, "Noto Kufi Arabic", sans-serif',
  },
});

const MainPage = () => {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookies, setShowCookies] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    
    // Handle terms
    if (path.includes('terms')) {
      setShowTerms(true);
      const isArabicTerms = path.includes('terms-ar');
      localStorage.setItem('preferredLanguage', isArabicTerms ? 'ar' : 'en');
    }
    
    // Handle privacy
    else if (path.includes('privacy')) {
      setShowPrivacy(true);
      const isArabicPrivacy = path.includes('privacy-ar');
      localStorage.setItem('preferredLanguage', isArabicPrivacy ? 'ar' : 'en');
    }
    
    // Handle cookies
    else if (path.includes('cookies')) {
      setShowCookies(true);
      const isArabicCookies = path.includes('cookies-ar');
      localStorage.setItem('preferredLanguage', isArabicCookies ? 'ar' : 'en');
    }
  }, []);

  useEffect(() => {
    // Handle touch move events
    const handleTouchMove = (e: TouchEvent) => {
      // Check if it's a gesture event
      if ('scale' in e && (e as unknown as GestureEvent).scale !== 1) {
        e.preventDefault();
      }
    };

    // Handle gesture events
    const handleGestureStart = (e: Event) => {
      e.preventDefault();
    };

    // Add event listeners
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('gesturestart', handleGestureStart);

    // Cleanup
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('gesturestart', handleGestureStart);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <CssBaseline />
        {showTerms ? <Terms /> : 
         showPrivacy ? <Privacy /> : 
         showCookies ? <Cookies /> : 
         <HomePage />}
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default MainPage; 