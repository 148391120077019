export const termsContentEn = `
Terms of Service
Last Updated: 14/01/2025

1. Introduction
Welcome to Mahra, operated by Mahra.io, a UK-based company (“Mahra,” “we,” “us,” or “our”). These Terms and Conditions (“T&C”) govern your access to and use of our AI-powered dating service (the “Service”). By using Mahra, you confirm that you have read, understood, and agree to be bound by these T&C. If you do not agree, you must not access or use Mahra.

2. Eligibility
Age Requirement: You must be at least 18 years old (or the age of majority in your jurisdiction) to use Mahra. By creating an account, you represent and warrant that you meet this age requirement.
Account Integrity: You agree to provide accurate, current, and complete information during registration. You are responsible for maintaining the confidentiality of your account credentials.
3. Description of the Service
Mahra uses artificial intelligence (AI) to facilitate introductions between individuals seeking romantic or marriage-oriented connections. We do not guarantee the accuracy or usefulness of any AI-driven match. Your results may vary, and no particular outcome is promised.

4. User Conduct
Lawful Use: You agree to use Mahra for lawful purposes only and in accordance with all applicable local, national, and international laws and regulations.
Prohibited Behavior: You must not:
Post or distribute any harassing, defamatory, abusive, obscene, threatening, racist, or otherwise objectionable content.
Impersonate another individual or misrepresent your affiliation with any entity.
Collect or store personal data about other users without their express permission.
Use Mahra for unauthorized commercial purposes or fraudulent schemes.
Upload or transmit viruses or harmful code, or otherwise disrupt the security or performance of Mahra.
5. User Content and Interactions
User Responsibility: You are solely responsible for the content you submit, including messages, profile details, images, or other materials (“User Content”). Mahra.io does not endorse or assume liability for any User Content.
Offline Interactions: Mahra is a facilitator of introductions only. You are fully responsible for any interactions with other users, online or offline. Mahra.io does not conduct background checks or identity verifications and disclaims any liability for user misconduct or disputes arising from user interactions.
6. Intellectual Property
Service Materials: Except for User Content, all content, features, and functionality provided by Mahra are owned by Mahra.io and protected under UK and international intellectual property laws.
License: Subject to your compliance with these T&C, Mahra.io grants you a limited, non-exclusive, and revocable license to use Mahra for personal, non-commercial purposes.
7. Privacy and Data Protection
Please refer to our Privacy Policy to learn how we collect, use, and protect your personal data. By using Mahra, you agree to our data practices as described in the Privacy Policy.

8. Payments and Subscriptions (If Applicable)
Fees: Some Mahra features may require subscription fees or one-time payments (“Paid Services”). You agree to pay all stated fees at the time of purchase or subscription.
Refunds: Except where UK law mandates otherwise, payments are final and non-refundable. We reserve the right to modify our pricing at any time without prior notice.
9. Disclaimer of Warranties
As-Is Basis: Mahra is provided “AS IS” and “AS AVAILABLE” without any warranties, express or implied. Mahra.io disclaims all implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
User Conduct: Mahra.io does not control or guarantee the conduct of any user. You assume all risks associated with using the Service, including any interactions with other users.
No Guaranteed Outcomes: Mahra.io does not guarantee that users will achieve any specific dating, engagement, or marriage outcome.
10. Limitation of Liability
To the maximum extent permitted by law, Mahra.io and its affiliates will not be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of Mahra. This includes any personal injury, emotional distress, or lost profits resulting from user interactions or reliance on any content.

11. Indemnification
You agree to indemnify and hold harmless Mahra.io, its officers, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses (including attorneys’ fees) arising out of:

Your use of Mahra.
Your breach of these T&C.
Your violation of any law or the rights of a third party.
Any User Content you provide.
12. Third-Party Links and Services
Mahra may contain links to third-party websites or services. Mahra.io does not endorse or accept responsibility for such external services or content. Accessing third-party resources is at your own risk.

13. Modification and Termination
Mahra.io may modify or discontinue any part of Mahra at any time without notice. We reserve the right to amend these T&C and will post any updates on Mahra. Continued use of Mahra constitutes acceptance of the revised T&C.

14. Governing Law and Jurisdiction
These T&C and any related disputes are governed by the laws of England and Wales. You agree to submit to the exclusive jurisdiction of the courts in England and Wales to resolve any disputes.

15. Contact Us
If you have questions or concerns regarding these T&C, please contact us at:
Email: [support@mahra.io]
Address: Mahra.io, UK

BY CREATING AN ACCOUNT OR USING MAHRA, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREED TO THESE TERMS AND CONDITIONS.
`;