import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { SectionTitle } from '../SectionTitle';
import { FeatureCard } from '../FeatureCard';
import { ScrollableFeatures } from './ScrollableFeatures';
import { motion } from 'framer-motion';
import SmartMatchIcon from '@mui/icons-material/Psychology';
import SecurityIcon from '@mui/icons-material/Security';
import ChatIcon from '@mui/icons-material/Chat';
import VerifiedIcon from '@mui/icons-material/Verified';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../constants/translations';

export const Features: React.FC = () => {
  const { language } = useLanguage();
  const t = translations[language].features;

  const features = [
    {
      icon: <SmartMatchIcon sx={{ fontSize: 40 }} />,
      title: t.features.aiMatching.title,
      description: t.features.aiMatching.description,
      fullDescription: t.features.aiMatching.fullDescription,
    },
    {
      icon: <SecurityIcon sx={{ fontSize: 40 }} />,
      title: t.features.security.title,
      description: t.features.security.description,
      fullDescription: t.features.security.fullDescription,
    },
    {
      icon: <ChatIcon sx={{ fontSize: 40 }} />,
      title: t.features.smartChat.title,
      description: t.features.smartChat.description,
      fullDescription: t.features.smartChat.fullDescription,
    },
    {
      icon: <VerifiedIcon sx={{ fontSize: 40 }} />,
      title: t.features.verifiedProfiles.title,
      description: t.features.verifiedProfiles.description,
      fullDescription: t.features.verifiedProfiles.fullDescription,
    },
    {
      icon: <PersonalVideoIcon sx={{ fontSize: 40 }} />,
      title: t.features.videoDating.title,
      description: t.features.videoDating.description,
      fullDescription: t.features.videoDating.fullDescription,
    },
    {
      icon: <FavoriteIcon sx={{ fontSize: 40 }} />,
      title: t.features.compatibility.title,
      description: t.features.compatibility.description,
      fullDescription: t.features.compatibility.fullDescription,
    },
    {
      icon: <LocationOnIcon sx={{ fontSize: 40 }} />,
      title: t.features.localEvents.title,
      description: t.features.localEvents.description,
      fullDescription: t.features.localEvents.fullDescription,
    },
    {
      icon: <FilterAltIcon sx={{ fontSize: 40 }} />,
      title: t.features.smartFilters.title,
      description: t.features.smartFilters.description,
      fullDescription: t.features.smartFilters.fullDescription,
    },
  ];

  return (
    <Box
      component="section"
      sx={{
        py: { xs: 8, md: 12 },
        backgroundColor: 'background.default',
        position: 'relative',
        overflow: 'hidden',
        direction: language === 'ar' ? 'rtl' : 'ltr',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 20% 20%, rgba(255, 75, 145, 0.1) 0%, rgba(108, 34, 166, 0.1) 100%)',
          opacity: 0.5,
        },
      }}
    >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <SectionTitle
            title={t.sectionTitle}
            subtitle={
              <Typography
                variant="body1"
                sx={{
                  color: 'text.secondary',
                  mb: 6,
                  maxWidth: '800px',
                  textAlign: { xs: 'center', md: 'left' },
                  mx: { xs: 'auto', md: 0 },
                }}
              >
                {t.sectionSubtitle}
              </Typography>
            }
            sx={{
              textAlign: language === 'ar' ? 'center' : { xs: 'center', md: 'left' },
              '& h2, & p': {
                textAlign: language === 'ar' ? 'center' : { xs: 'center', md: 'left' },
                marginX: language === 'ar' ? 'auto' : { xs: 'auto', md: 0 },
              }
            }}
          />
        </motion.div>
        
        <ScrollableFeatures>
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ 
                opacity: 0, 
                x: language === 'ar' ? 20 : -20
              }}
              whileInView={{ 
                opacity: 1, 
                x: 0 
              }}
              viewport={{ once: true }}
              transition={{ 
                delay: language === 'ar' ? (features.length - 1 - index) * 0.1 : index * 0.1
              }}
            >
              <FeatureCard {...feature} />
            </motion.div>
          ))}
        </ScrollableFeatures>
      </Container>
    </Box>
  );
};

export default Features; 