export const privacyContentEn = `Privacy Policy

Last Updated: 14/01/2025

1. Introduction
This Privacy Policy applies to Mahra, operated by Mahra.io (“we,” “us,” “our”), a UK-based company. We are committed to respecting your privacy and safeguarding your personal data in accordance with relevant UK data protection laws.

2. Data We Collect
Personal Information: Such as your name, email address, date of birth, gender, location, and other information you provide upon registration or when updating your profile.
Profile Content: Photos, personal preferences, and any other details you choose to include in your profile.
Device and Usage Data: IP addresses, browser type, operating system, and usage information gathered through cookies or similar technologies.
Communications: Messages or support inquiries you send to other users or to us.
3. How We Use Your Data
Service Delivery: To create and manage user accounts, provide AI matchmaking, and support core functionality of Mahra.
Improvement and Analytics: To analyze usage trends and enhance user experience.
Communications: To send you updates, newsletters, or marketing messages (where legally permitted).
Legal Compliance and Safety: To comply with legal obligations, detect unlawful or suspicious activities, and safeguard the rights and safety of Mahra.io and users.
4. Sharing Your Data
We will not sell or rent your personal data. However, we may share your information with:

Service Providers: Trusted partners who perform functions on our behalf under confidentiality obligations.
Legal and Regulatory: To comply with applicable laws, respond to lawful requests, or protect our rights and property.
Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets.
5. Data Retention
We retain your personal data only as long as necessary to fulfill the purposes stated in this Privacy Policy, unless a longer retention period is required by law.

6. Your Rights
Depending on your jurisdiction, you may have rights to:

Access, correct, or delete your personal data.
Restrict or object to processing of your data.
Request data portability.
To exercise these rights, contact us at [support@mahra.io]. We may require further verification of your identity.

7. Security Measures
We implement reasonable security measures such as encryption and secure servers. However, no electronic transmission or storage method can guarantee absolute security.

8. Age Restriction
Mahra is not intended for individuals under 18 years of age (or the age of majority in your jurisdiction). We do not knowingly collect personal data from minors. If you become aware of such activity, please contact us immediately.

9. Third-Party Links
Mahra may link to third-party websites or services. Mahra.io does not control these external platforms, and we recommend reviewing their respective privacy policies.

10. Changes to This Privacy Policy
We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any significant changes by posting the new policy on Mahra. Your continued use of Mahra constitutes acceptance of the updated policy.

11. Contact Us
If you have questions about our data practices, or wish to exercise your rights, please contact us at:
Email: [support@mahra.io]
Address: Mahra.io, UK


`; 