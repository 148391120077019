export const privacyContentAr = `سياسة الخصوصية
آخر تحديث: 14/01/2025

1. المقدمة
تنطبق هذه السياسة على خدمة Mahra التي تديرها شركة Mahra.io (المشار إليها بـ"نحن" أو "نا")، وهي شركة مقرّها المملكة المتحدة. نحن ملتزمون بحماية خصوصيتك وبياناتك الشخصية وفقًا للقوانين المعمول بها في المملكة المتحدة.

2. البيانات التي نجمعها
المعلومات الشخصية: مثل الاسم وعنوان البريد الإلكتروني وتاريخ الميلاد والجنس والموقع وأي معلومات إضافية تقدّمها عند التسجيل أو تحديث ملفك الشخصي.
محتوى الملف الشخصي: مثل الصور والتفضيلات وأي معلومات أخرى تختار إضافتها.
بيانات الجهاز والاستخدام: عنوان IP ونوع المتصفح ونظام التشغيل ومعلومات الاستخدام التي نحصل عليها من خلال الكوكيز أو تقنيات مشابهة.
المراسلات: أي رسائل أو طلبات دعم تتبادلها مع المستخدمين الآخرين أو معنا.
3. كيفية استخدامنا لبياناتك
تقديم الخدمة: لإنشاء الحسابات وإدارتها وتوفير خدمات التعارف بالذكاء الاصطناعي وضمان عمل Mahra بكفاءة.
التحسين والتحليل: لتحليل كيفية تفاعل المستخدمين مع الخدمة وتحسين الميزات ورفع جودة التوافقات المقترحة.
التواصل: لإرسال التحديثات أو النشرات الإخبارية أو رسائل التسويق (وفقًا للقانون). ويمكنك إلغاء الاشتراك في أي وقت.
الامتثال القانوني والأمان: للكشف عن الأنشطة الاحتيالية أو غير القانونية والامتثال للالتزامات القانونية وحماية حقوق ومصالح Mahra.io ومستخدميها.
4. مشاركة بياناتك
لن نبيع أو نؤجّر بياناتك الشخصية. ومع ذلك، قد نشارك بياناتك مع:

مزوّدي الخدمات: أطراف موثوقة تساعدنا في تشغيل الخدمة تحت التزامات سرية صارمة.
الأغراض القانونية: للامتثال لأي قانون أو لائحة أو إجراء قانوني واجب النفاذ (مثل أوامر المحكمة) أو لحماية حقوق أو ممتلكات Mahra.io أو مستخدميها.
التحويلات التجارية: في حال دمج الشركة أو بيع أصولها أو الاستحواذ عليها.
5. مدة الاحتفاظ بالبيانات
نحتفظ ببياناتك الشخصية فقط للمدة اللازمة للأغراض الموضّحة في هذه السياسة، ما لم تتطلب القوانين فترة أطول للاحتفاظ.

6. حقوقك
وفقًا للتشريعات السارية في المملكة المتحدة (وعند الاقتضاء)، قد يحق لك:

الوصول إلى بياناتك الشخصية أو تصحيحها أو حذفها.
طلب تقييد المعالجة أو الاعتراض عليها.
طلب نقل البيانات (قابلية نقل البيانات).
لممارسة هذه الحقوق، يرجى التواصل معنا عبر البريد الإلكتروني [support@mahra.io]. قد نطلب إثبات الهوية قبل تنفيذ طلبك.

7. التدابير الأمنية
نستخدم إجراءات أمان معقولة مثل التشفير والخوادم الآمنة لحماية بياناتك الشخصية. ولكن لا يوجد نظام إلكتروني يضمن أمانًا كاملًا بنسبة 100%.

8. الفئة العمرية المستهدفة
خدمة Mahra غير موجّهة للأفراد دون سن 18 عامًا (أو دون سن الرشد وفقًا للتشريعات المحلية). لا نجمع بيانات القاصرين عن قصد. إذا اكتشفت أي حساب لشخص دون 18 عامًا، يرجى التواصل معنا فورًا.

9. الروابط الخارجية
قد تتضمّن Mahra روابط لمواقع أو خدمات خارجية. لا تتحمّل Mahra.io مسؤولية محتوى أو سياسات الخصوصية الخاصة بتلك المواقع أو الخدمات، وننصحك بمراجعة سياساتها قبل استخدام أي خدمات خارجية.

10. تعديلات على سياسة الخصوصية
نحتفظ بالحق في تحديث أو تعديل هذه السياسة في أي وقت. سيتم نشر النسخة المحدثة على Mahra، ويُعتبر استمرارك في استخدام الخدمة بعد أي تحديث بمثابة قبول منك للسياسة المحدّثة.

11. التواصل معنا
في حال وجود أي استفسارات حول ممارساتنا المتعلّقة بالبيانات الشخصية أو إذا رغبت في ممارسة حقوقك المذكورة أعلاه، يرجى التواصل معنا على:
البريد الإلكتروني: [support@mahra.io]
العنوان: Mahra.io, UK
`; 