import React, { useState, useCallback } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { Button } from '../Button';
import { GradientText } from '../GradientText';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../constants/translations';
import { AppPreview } from '../AppPreview/AppPreview';
import { motion } from 'framer-motion';
import { WaitlistModal } from '../WaitlistModal/WaitlistModal';
import { ParticlesBackground } from '../ParticlesBackground/ParticlesBackground';

const PhoneBackgroundDesign = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '140%', sm: '160%', md: '140%' },
        height: { xs: '140%', sm: '160%', md: '140%' },
        zIndex: 0,
      }}
    >
      <Box
        sx={{
          opacity: 1,
          transform: 'none',
        }}
      >
        {/* Glowing Orbs - Static on mobile */}
        <Box
          sx={{
            position: 'absolute',
            top: '30%',
            right: '20%',
            width: '120px',
            height: '120px',
            borderRadius: '50%',
            background: 'radial-gradient(circle, rgba(255,75,145,0.2) 0%, rgba(255,75,145,0) 70%)',
            filter: 'blur(15px)',
            animation: isMobile ? 'none' : 'float 6s ease-in-out infinite',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '25%',
            left: '15%',
            width: '150px',
            height: '150px',
            borderRadius: '50%',
            background: 'radial-gradient(circle, rgba(108,34,166,0.2) 0%, rgba(108,34,166,0) 70%)',
            filter: 'blur(20px)',
            animation: isMobile ? 'none' : 'float 8s ease-in-out infinite reverse',
          }}
        />

        {/* Decorative Lines - Reduced or removed on mobile */}
        {!isMobile && (
          <Box sx={{ position: 'absolute', inset: 0 }}>
            {[...Array(3)].map((_, i) => (
              <motion.div
                key={`line-${i}`}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  width: `${400 + i * 60}px`,
                  height: `${400 + i * 60}px`,
                  borderRadius: '40px',
                  border: '1px dashed rgba(255,255,255,0.1)',
                  transform: 'translate(-50%, -50%) rotate(45deg)',
                }}
                animate={{
                  rotate: [45, 225, 45],
                  scale: [1, 1.05, 1],
                }}
                transition={{
                  duration: 20 + i * 5,
                  repeat: Infinity,
                  ease: "linear",
                }}
              />
            ))}
          </Box>
        )}

        {/* Sparkles - Reduced count on mobile */}
        {[...Array(isMobile ? 5 : 15)].map((_, i) => (
          <Box
            key={`sparkle-${i}`}
            sx={{
              position: 'absolute',
              width: '4px',
              height: '4px',
              background: 'white',
              borderRadius: '50%',
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              opacity: 0.5,
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export const Hero = () => {
  const theme = useTheme();
  const { language } = useLanguage();
  const t = translations[language].hero;
  const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // More robust scroll function
  const scrollToTestimonials = useCallback(() => {
    try {
      const testimonialsSection = document.getElementById('testimonials');
      if (testimonialsSection) {
        const headerOffset = isMobile ? 70 : 80;
        const elementPosition = testimonialsSection.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    } catch (error) {
      console.error('Error scrolling to testimonials:', error);
      // Fallback scroll method
      const testimonialsSection = document.getElementById('testimonials');
      testimonialsSection?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isMobile]);

  // Handlers with console logs for debugging
  const handleGetStarted = () => {
    console.log('Opening waitlist modal');
    setIsWaitlistOpen(true);
  };

  const handleReadStories = () => {
    console.log('Scrolling to testimonials');
    scrollToTestimonials();
  };

  // Optimize animations for mobile
  const fadeInVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: isMobile ? 0.5 : 0.8,
        ease: "easeOut"
      }
    }
  };

  const floatVariants = {
    animate: {
      y: [0, -10, 0],
      transition: {
        duration: 3,
        repeat: Infinity,
        ease: "easeInOut",
        // Reduce animation complexity on mobile
        repeatType: isMobile ? "reverse" : "mirror",
      }
    }
  };

  // Make some elements static on mobile
  const imageAnimation = isMobile ? {} : {
    initial: { scale: 0.8, opacity: 0 },
    animate: { 
      scale: 1, 
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  // Reduce particles on mobile
  const particleCount = isMobile ? 15 : 30;

  // Mobile buttons with explicit handlers
  const mobileButtons = (
    <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
      <Button
        variant="secondary"
        size="large"
        onClick={handleGetStarted}
        startIcon={language === 'ar' ? <ArrowForwardIcon sx={{ transform: 'scaleX(-1)' }} /> : undefined}
        endIcon={language === 'ar' ? undefined : <ArrowForwardIcon />}
        sx={{
          px: 4,
          backgroundColor: 'white',
          color: 'primary.main',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          },
          cursor: 'pointer',
          zIndex: 10,
        }}
      >
        {t.getStarted}
      </Button>
      <Button
        variant="outline"
        size="large"
        onClick={handleReadStories}
        startIcon={language === 'ar' ? <PlayArrowIcon /> : undefined}
        endIcon={language === 'ar' ? undefined : <PlayArrowIcon />}
        sx={{
          px: 4,
          borderColor: 'rgba(255, 255, 255, 0.5)',
          color: 'white',
          '&:hover': {
            borderColor: 'white',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
          cursor: 'pointer',
          zIndex: 10,
        }}
      >
        {t.readStories}
      </Button>
    </Stack>
  );

  // Desktop view buttons
  const desktopButtons = (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 4 }}>
      <Button
        variant="secondary"
        size="large"
        onClick={() => setIsWaitlistOpen(true)}
        startIcon={language === 'ar' ? <ArrowForwardIcon sx={{ transform: 'scaleX(-1)' }} /> : undefined}
        endIcon={language === 'ar' ? undefined : <ArrowForwardIcon />}
        sx={{
          px: 4,
          backgroundColor: 'white',
          color: 'primary.main',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          },
        }}
      >
        {t.getStarted}
      </Button>
      <Button
        variant="outline"
        size="large"
        onClick={scrollToTestimonials}
        startIcon={<PlayArrowIcon />}
        sx={{
          px: 4,
          borderColor: 'rgba(255, 255, 255, 0.5)',
          color: 'white',
          '&:hover': {
            borderColor: 'white',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        {t.readStories}
      </Button>
    </Stack>
  );

  return (
    <>
      <Box
        component="section"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          pt: { xs: 8, sm: 6, md: 8 },
          pb: { xs: 8, sm: 8, md: 10 },
          minHeight: { sm: 'calc(100vh - 80px)' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
        }}
      >
        {/* Background Pattern for web view */}
        {!isMobile && (
          <Box
            sx={{
              position: 'absolute',
              inset: 0,
              background: 'url("/images/patterns/hero-pattern.svg")',
              opacity: 0.1,
              animation: 'float 20s linear infinite',
              zIndex: 0,
            }}
          />
        )}

        {/* Mobile Layout */}
        {isMobile ? (
          <Container 
            maxWidth="sm" 
            sx={{ 
              width: '100%',
              px: 2,
              mx: 'auto',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Stack 
              spacing={4}
              alignItems="center" 
              textAlign="center"
              sx={{ 
                mt: { xs: 4 },
                width: '100%',
                position: 'relative',
                transform: 'none',
              }}
            >
              {/* Title with Gradient - Simplified animation */}
              <Box
                sx={{
                  opacity: 1,
                  transform: 'none',
                }}
              >
                <Typography
                  variant="h1"
                  lang={language === 'ar' ? 'ar' : 'en'}
                  sx={{
                    fontSize: { xs: '1.75rem' },
                    fontWeight: 800,
                    lineHeight: 1.2,
                    mb: 2,
                    px: 2,
                    color: 'white',
                    maxWidth: '300px',
                    mx: 'auto',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {t.title}
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: '0.9rem' },
                    fontWeight: 500,
                    color: 'rgba(255, 255, 255, 0.9)',
                    mb: 3,
                    px: 2,
                    maxWidth: '260px',
                    mx: 'auto',
                  }}
                >
                  {t.subtitle}
                </Typography>
              </Box>

              {/* App Preview - Static on mobile */}
              <Box sx={{ 
                my: 3,
                pt: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                transform: 'none',
                position: 'relative',
                left: { xs: '-14px', sm: 0 },
              }}>
                <Box
                  sx={{
                    width: '100%',
                    maxWidth: '240px',
                    opacity: 1,
                    transform: 'none',
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      maxWidth: { xs: '240px', sm: '260px', md: '300px' },
                      mx: 'auto',
                      mt: { xs: 4, md: 0 },
                      isolation: 'isolate',
                      zIndex: 1,
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        inset: '-20%',
                        background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
                        borderRadius: '50%',
                        zIndex: -1,
                      }
                    }}
                  >
                    <PhoneBackgroundDesign />
                    <AppPreview />
                  </Box>
                </Box>
              </Box>

              {/* Action Buttons - Static on mobile */}
              <Box
                sx={{ 
                  width: '100%', 
                  padding: '0 16px',
                  position: 'relative',
                  zIndex: 20,
                  opacity: 1,
                  transform: 'none',
                }}
              >
                {mobileButtons}
              </Box>
            </Stack>
          </Container>
        ) : (
          <Container maxWidth="lg" sx={{ 
            position: 'relative',
            py: { sm: 2, md: 4 },
            px: { xs: 2, sm: 3, md: 4 },
            zIndex: 1,
          }}>
            <Grid 
              container 
              spacing={4} 
              alignItems="center"
              sx={{ 
                flexDirection: { xs: 'column', md: language === 'ar' ? 'row-reverse' : 'row' } 
              }}
            >
              <Grid item xs={12} md={6}>
                <Stack 
                  spacing={3} 
                  alignItems={{ xs: 'center', md: language === 'ar' ? 'flex-end' : 'flex-start' }}
                  textAlign={{ xs: 'center', md: language === 'ar' ? 'right' : 'left' }}
                >
                  <Typography
                    variant="h1"
                    lang={language === 'ar' ? 'ar' : 'en'}
                    sx={{
                      color: 'white',
                      fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem' },
                      fontWeight: 700,
                      lineHeight: 1.2,
                      maxWidth: '600px',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {t.title}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      color: 'rgba(255, 255, 255, 0.9)',
                      maxWidth: '500px',
                      fontSize: { xs: '1rem', sm: '1.25rem' },
                    }}
                  >
                    {t.subtitle}
                  </Typography>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    sx={{ mt: 4 }}
                  >
                    {desktopButtons}
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    position: 'relative',
                    maxWidth: { xs: '240px', sm: '260px', md: '300px' },
                    mx: 'auto',
                    mt: { xs: 4, md: 0 },
                    isolation: 'isolate',
                    zIndex: 1,
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      inset: '-20%',
                      background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
                      borderRadius: '50%',
                      zIndex: -1,
                    }
                  }}
                >
                  <PhoneBackgroundDesign />
                  <AppPreview />
                </Box>
              </Grid>
            </Grid>
          </Container>
        )}

        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '80px',
            overflow: 'hidden',
            transform: 'translateY(1px)',
          }}
        >
          <svg
            viewBox="0 0 1440 320"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <path
              d="M0 192L48 176C96 160 192 128 288 128C384 128 480 160 576 165.3C672 171 768 149 864 160C960 171 1056 213 1152 213.3C1248 213 1344 171 1392 149.3L1440 128V320H1392C1344 320 1248 320 1152 320C1056 320 960 320 864 320C768 320 672 320 576 320C480 320 384 320 288 320C192 320 96 320 48 320H0V192Z"
              fill="white"
            />
            <path
              d="M0 224L48 213.3C96 203 192 181 288 181.3C384 181 480 203 576 208C672 213 768 203 864 208C960 213 1056 235 1152 240C1248 245 1344 235 1392 229.3L1440 224V320H1392C1344 320 1248 320 1152 320C1056 320 960 320 864 320C768 320 672 320 576 320C480 320 384 320 288 320C192 320 96 320 48 320H0V224Z"
              fill="white"
              fillOpacity="0.5"
            />
          </svg>
        </Box>
      </Box>

      <WaitlistModal
        open={isWaitlistOpen}
        onClose={() => setIsWaitlistOpen(false)}
      />

      <ParticlesBackground
        count={particleCount}
        // Reduce particle movement speed on mobile
        speed={isMobile ? 0.3 : 0.5}
      />
    </>
  );
}; 