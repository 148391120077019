export interface HeaderTranslations {
  [key: string]: string;
  features: string;
  showcase: string;
  testimonials: string;
  faq: string;
  contact: string;
  language: string;
  downloadApp: string;
  howItWorks: string;
}

export interface HeroTranslations {
  title: string;
  subtitle: string;
  description: string;
  getStarted: string;
  readStories: string;
  appPreview: string;
}

export interface FeaturesTranslations {
  sectionTitle: string;
  sectionSubtitle: string;
  features: {
    aiMatching: {
      title: string;
      description: string;
      fullDescription: string;
    };
    security: {
      title: string;
      description: string;
      fullDescription: string;
    };
    smartChat: {
      title: string;
      description: string;
      fullDescription: string;
    };
    verifiedProfiles: {
      title: string;
      description: string;
      fullDescription: string;
    };
    videoDating: {
      title: string;
      description: string;
      fullDescription: string;
    };
    compatibility: {
      title: string;
      description: string;
      fullDescription: string;
    };
    localEvents: {
      title: string;
      description: string;
      fullDescription: string;
    };
    smartFilters: {
      title: string;
      description: string;
      fullDescription: string;
    };
  };
}

interface StepDetails {
  photoVerification: {
    title: string;
    description: string;
  };
  interestSelection: {
    title: string;
    description: string;
  };
  matchPreferences: {
    title: string;
    description: string;
  };
  personalityQuiz: {
    title: string;
    description: string;
  };
  locationSettings: {
    title: string;
    description: string;
  };
  profileReview: {
    title: string;
    description: string;
  };
}

export interface HowItWorksTranslations {
  sectionTitle: string;
  sectionSubtitle: string;
  steps: {
    profile: {
      title: string;
      description: string;
      longDescription: string;
      details: StepDetails;
    };
    preferences: {
      title: string;
      description: string;
      longDescription: string;
      details: StepDetails;
    };
    matches: {
      title: string;
      description: string;
      longDescription: string;
      details: StepDetails;
    };
    chat: {
      title: string;
      description: string;
      longDescription: string;
      details: StepDetails;
    };
  };
}

interface Testimonials {
  sectionTitle: string;
  sectionSubtitle: string;
  testimonials: {
    title: string;
    subtitle: string;
    stories: Array<{
      name: string;
      age: number;
      location: string;
      story: string;
      occupation: string;
      matchDate: string;
      rating: number;
    }>;
  };
}

export interface FAQTranslations {
  sectionTitle: string;
  sectionSubtitle: string;
  questions: Array<{
    title: string;
    answer: string;
  }>;
}

interface ContactTranslations {
  sectionTitle: string;
  sectionSubtitle: string;
  emailTitle: string;
  emailAddress: string;
  supportText: string;
}

interface FooterTranslations {
  company: {
    title: string;
    about: string;
    careers: string;
    press: string;
  };
  legal: {
    title: string;
    privacy: string;
    terms: string;
    cookies: string;
  };
  support: {
    title: string;
    help: string;
    contact: string;
    feedback: string;
  };
  copyright: string;
  rights: string;
}

interface Translations {
  header: HeaderTranslations;
  hero: HeroTranslations;
  features: FeaturesTranslations;
  howItWorks: HowItWorksTranslations;
  testimonials: Testimonials;
  faq: FAQTranslations;
  appHighlights: {
    sectionTitle: string;
    sectionSubtitle: string;
    features: {
      aiMatching: {
        title: string;
        tagline: string;
        description: string;
        whyItMatters: string;
      };
      security: {
        title: string;
        tagline: string;
        description: string;
        whyItMatters: string;
      };
      communication: {
        title: string;
        tagline: string;
        description: string;
        whyItMatters: string;
      };
      compatibility: {
        title: string;
        tagline: string;
        description: string;
        whyItMatters: string;
      };
    };
  };
  joinWaitlist: {
    title: {
      en: string;
      ar: string;
    };
    subtitle: {
      en: string;
      ar: string;
    };
    peopleJoined: {
      en: string;
      ar: string;
    };
    averageWait: {
      en: string;
      ar: string;
    };
    dailySignups: {
      en: string;
      ar: string;
    };
    sliderLabel: {
      en: string;
      ar: string;
    };
    joinButton: {
      en: string;
      ar: string;
    };
    matchPotential: {
      en: string;
      ar: string;
    };
    matchCount: {
      en: string;
      ar: string;
    };
    compatibility: {
      en: string;
      ar: string;
    };
    calculating: {
      en: string;
      ar: string;
    };
    distance: {
      en: string;
      ar: string;
    };
    ageRange: {
      en: string;
      ar: string;
    };
  };
  appPreview: {
    title: {
      en: string;
      ar: string;
    };
    screens: {
      home: {
        en: string;
        ar: string;
      };
      search: {
        en: string;
        ar: string;
      };
      chat: {
        en: string;
        ar: string;
      };
      profile: {
        en: string;
        ar: string;
      }
    }
  };
  contact: ContactTranslations;
  footer: FooterTranslations;
  terms: {
    title: {
      en: string;
      ar: string;
    };
    content: {
      en: string;
      ar: string;
    };
    backButton: {
      en: string;
      ar: string;
    };
  };
}

export const translations: Record<'en' | 'ar', Translations> = {
  en: {
    header: {
      features: "Features",
      showcase: "Showcase",
      testimonials: "Testimonials",
      faq: "FAQ",
      contact: "Contact",
      language: "العربية",
      downloadApp: "Download App",
      howItWorks: "Guide"
    },
    hero: {
      title: "AI Dating App \nYour Path to .. \nI Do",
      subtitle: "Modern Matchmaking for Modern Lives",
      description: "Experience a new way of finding meaningful relationships through our advanced AI matchmaking system.",
      getStarted: "Get Started",
      readStories: "Read Stories",
      appPreview: "App Preview Coming Soon"
    },
    features: {
      sectionTitle: "Why Choose Mahra",
      sectionSubtitle: "Experience the future of dating with our innovative features designed to create meaningful connections.",
      features: {
        aiMatching: {
          title: "AI Matching",
          description: "Our AI-powered algorithm learns your preferences to find the most compatible matches.",
          fullDescription: "Using advanced machine learning algorithms, our AI system analyzes your behavior, preferences, and interactions to continuously improve match recommendations."
        },
        security: {
          title: "Safe & Secure",
          description: "Advanced encryption and verification systems to ensure your safety and privacy.",
          fullDescription: "We implement bank-level encryption, strict verification processes, and continuous monitoring to protect your personal information."
        },
        smartChat: {
          title: "Smart Chat",
          description: "Engage in deep conversations with ice-breakers and personality-based prompts.",
          fullDescription: "Our intelligent chat system suggests conversation starters based on shared interests and provides real-time translation for international matches."
        },
        verifiedProfiles: {
          title: "Verified Profiles",
          description: "All profiles are thoroughly verified to maintain a trustworthy community.",
          fullDescription: "Multi-step verification process includes photo verification, social media linking, and optional ID verification for added trust."
        },
        videoDating: {
          title: "Video Dating",
          description: "Connect face-to-face with potential matches through secure video calls.",
          fullDescription: "End-to-end encrypted video calls with blur background option, scheduled virtual dates, and group video hangouts."
        },
        compatibility: {
          title: "Compatibility Score",
          description: "See detailed compatibility metrics with your matches.",
          fullDescription: "Our proprietary compatibility algorithm analyzes over 100 personality traits and preferences to generate a comprehensive matching score."
        },
        localEvents: {
          title: "Local Events",
          description: "Discover and join local dating events and activities.",
          fullDescription: "Browse and join curated local events, from casual meetups to organized dating activities."
        },
        smartFilters: {
          title: "Smart Filters",
          description: "Advanced filtering options for precise match searching.",
          fullDescription: "Customize your search with detailed filters including interests, lifestyle, values, and goals."
        }
      }
    },
    howItWorks: {
      sectionTitle: "How It Works",
      sectionSubtitle: "Get started in just a few simple steps",
      steps: {
        profile: {
          title: "Download & Sign Up",
          description: "Get the app and create your account in minutes",
          longDescription:
            "Join us by downloading the app from your favorite store. Set up your account with basic details and begin your journey to find meaningful connections.",
          details: {
            photoVerification: {
              title: "Photo Verification",
              description: "Ensure authenticity with genuine pictures"
            },
            interestSelection: {
              title: "Interest Selection",
              description: "Pick your hobbies and passions to find like-minded people"
            },
            matchPreferences: {
              title: "Match Criteria",
              description: "Specify age range, distance, and lifestyle preferences"
            },
            personalityQuiz: {
              title: "Quick Quiz",
              description: "Answer a few questions for more accurate matches"
            },
            locationSettings: {
              title: "Location Settings",
              description: "Choose local or global discovery—it's up to you"
            },
            profileReview: {
              title: "Profile Review",
              description: "Verify your details and stand out with a trust badge"
            }
          }
        },
        preferences: {
          title: "Customize Your Experience",
          description: "Fine-tune your dating settings for better results",
          longDescription:
            "Define your relationship goals and cultural preferences. Our AI refines your matches based on the preferences you set, ensuring a better chance at true compatibility.",
          details: {
            photoVerification: {
              title: "Profile Validation",
              description: "Upload clear photos to earn verified status"
            },
            interestSelection: {
              title: "Hobbies & Interests",
              description: "Add more hobbies for detailed match suggestions"
            },
            matchPreferences: {
              title: "Update Preferences",
              description: "Refine age, location, and lifestyle parameters at any time"
            },
            personalityQuiz: {
              title: "Short Personality Test",
              description: "Provide deeper insights for even better match results"
            },
            locationSettings: {
              title: "Explore Regions",
              description: "Discover potential matches near you or around the world"
            },
            profileReview: {
              title: "Final Check",
              description: "Review and confirm your profile before you go live"
            }
          }
        },
        matches: {
          title: "Explore Your Matches",
          description: "AI-powered suggestions tailored to you",
          longDescription:
            "Our system evaluates your profile, quiz answers, and preferences to deliver highly compatible matches. Browse each profile to see the compatibility highlights and connect at your pace.",
          details: {
            photoVerification: {
              title: "Authentic Profiles",
              description: "All matches undergo photo verification for added trust"
            },
            interestSelection: {
              title: "Shared Interests",
              description: "Instantly see how many hobbies you share"
            },
            matchPreferences: {
              title: "Filter Options",
              description: "Sort matches by age, distance, or personal values"
            },
            personalityQuiz: {
              title: "Personality Insights",
              description: "Learn why each suggestion might be a good fit"
            },
            locationSettings: {
              title: "Geographical Range",
              description: "Discover people locally or expand your reach"
            },
            profileReview: {
              title: "Ongoing Updates",
              description: "Refresh your details for real-time, accurate matches"
            }
          }
        },
        chat: {
          title: "Connect & Chat",
          description: "Engage in secure, meaningful conversations",
          longDescription:
            "Send messages, share voice notes, or schedule a video call. Take advantage of our ice-breaker prompts and translation features to build a genuine connection from anywhere.",
          details: {
            photoVerification: {
              title: "Visual Confirmation",
              description: "Keep your photos updated to maintain verification"
            },
            interestSelection: {
              title: "Conversation Starters",
              description: "Use shared interests to spark natural discussions"
            },
            matchPreferences: {
              title: "Contact Controls",
              description: "Filter who can message you based on your criteria"
            },
            personalityQuiz: {
              title: "Guided Chats",
              description: "Leverage quiz insights for deeper conversations"
            },
            locationSettings: {
              title: "Location Sharing",
              description: "Opt in or out of sharing your location in real time"
            },
            profileReview: {
              title: "Regular Updates",
              description: "Refresh your profile to stay visible and relevant"
            }
          }
        }
      }
    },
    testimonials: {
      sectionTitle: "Success Stories",
      sectionSubtitle: "Real stories, real connections, and real love",
      testimonials: {
        title: "Hear from Our Happy Couples",
        subtitle: "Join thousands of successful matches",
        stories: [
          {
            name: "Khalid & Dana",
            age: 30,
            location: "Riyadh",
            story: "We discovered our shared interest in running and decided to meet after a few conversations. In no time, we realized we share the same values and goals. We got married two months ago and are now planning our honeymoon.",
            occupation: "Government Employee",
            matchDate: "Married 2 months ago",
            rating: 5
          },
          {
            name: "Raghad & Abdullah",
            age: 28,
            location: "Jeddah",
            story: "We bonded over our mutual love of reading and Arabic calligraphy. We met several times with our families, and the app helped us discover our great compatibility. We've been married for four months and feel our life is truly complete together.",
            occupation: "Dentist",
            matchDate: "Married 4 months ago",
            rating: 4
          },
          {
            name: "Fahad & Hadeel",
            age: 26,
            location: "Dammam",
            story: "We both wanted someone who loves traveling and exploring. The in-depth questions on the app helped us understand each other's personalities. We had a small, family-centered wedding last month, and it's been wonderful.",
            occupation: "Teacher",
            matchDate: "Married 1 month ago",
            rating: 5
          },
          {
            name: "Ali & Lama",
            age: 33,
            location: "Doha",
            story: "We connected over our passion for volunteering and community service. From our very first messages, we felt like a team supporting each other. We got married about a month ago, and everything is going perfectly.",
            occupation: "Civil Engineer",
            matchDate: "Married 1 month ago",
            rating: 4
          },
          {
            name: "Hanan & Tarek",
            age: 29,
            location: "Abu Dhabi",
            story: "Right from the start, we realized we share many important family values. After a brief period of getting to know each other, we had a beautiful ceremony. Our married life has been calm and happy.",
            occupation: "Accountant",
            matchDate: "Married 2 months ago",
            rating: 5
          },
          {
            name: "Noor & Faisal",
            age: 27,
            location: "Medina",
            story: "What drew me to him was his dedication to volunteer work. We discovered our future goals align perfectly. We got married three weeks ago and love how our families have blended so seamlessly.",
            occupation: "Entrepreneur",
            matchDate: "Married 3 weeks ago",
            rating: 4
          }
        ]
      }
    },
    faq: {
      sectionTitle: "Frequently Asked Questions",
      sectionSubtitle: "Find answers to common questions about our platform",
      questions: [
        {
          title: "How does the AI matching work?",
          answer: "Our AI system analyzes multiple factors including personality traits, interests, values, and behavior patterns to create highly compatible matches. It continuously learns and improves from user interactions."
        },
        {
          title: "What makes your platform different?",
          answer: "We combine advanced AI technology with cultural sensitivity, focusing on meaningful connections rather than casual dating. Our platform is designed specifically for those seeking serious relationships."
        },
        {
          title: "How do you verify profiles?",
          answer: "We use a multi-step verification process including phone verification, AI-powered photo verification, and manual review of profiles to ensure authenticity and safety."
        },
        {
          title: "Is my privacy protected?",
          answer: "Yes, we use bank-level encryption and strict privacy controls. Your personal information is never shared without consent, and you have full control over your visibility settings."
        },
        {
          title: "How long does verification take?",
          answer: "The verification process typically takes 24-48 hours. We thoroughly review each profile while maintaining efficiency to ensure a quality user experience."
        },
        {
          title: "Can I control who sees my profile?",
          answer: "Absolutely! You have complete control over your profile visibility and can adjust privacy settings to show your profile only to matches that meet your criteria."
        },
        {
          title: "What happens if I need support?",
          answer: "Our dedicated support team is available 24/7. You can reach us through the app, email, or phone for any questions or concerns about your experience."
        },
        {
          title: "Are there cultural preferences settings?",
          answer: "Yes, we understand the importance of cultural compatibility. You can set specific cultural preferences in your profile to receive more relevant matches."
        },
        {
          title: "What safety measures do you have in place?",
          answer: "We employ multiple safety measures including AI-powered profile screening, real-time chat monitoring, and a dedicated safety team available 24/7. Users can also report inappropriate behavior instantly."
        },
        {
          title: "Can I pause my profile visibility?",
          answer: "Yes, you can easily pause your profile visibility at any time through the privacy settings. This allows you to take breaks while maintaining your matches and conversations."
        },
        {
          title: "How does the messaging system work?",
          answer: "Our messaging system includes read receipts, typing indicators, and media sharing. Messages are end-to-end encrypted, and you can control who can message you through your privacy settings."
        },
        {
          title: "What happens after I match with someone?",
          answer: "After matching, you can start a conversation through our secure messaging system. Our AI provides conversation starters based on shared interests to help break the ice."
        }
      ]
    },
    appHighlights: {
      sectionTitle: "Experience the Future of Meaningful Connections",
      sectionSubtitle: "Discover how our innovative features help you find your perfect match",
      features: {
        aiMatching: {
          title: "AI-Powered Matchmaking",
          tagline: "Smart, Accurate, and Personalized Matches",
          description: "Discover matches based on shared values, interests, and cultural preferences. Our advanced AI algorithms analyze user profiles for compatibility scoring, providing real-time recommendations tailored to your behavior and preferences.",
          whyItMatters: "Find meaningful connections effortlessly with technology that truly understands you."
        },
        security: {
          title: "Privacy & Security First",
          tagline: "Your Safety is Our Priority",
          description: "End-to-end encryption for all conversations ensures private and secure communication. Face verification tools ensure authenticity and build trust, while unique anonymity tools like image masking and incognito mode enable discreet browsing.",
          whyItMatters: "Feel secure and confident as you connect in a culturally respectful environment."
        },
        communication: {
          title: "Engaging Communication Tools",
          tagline: "Conversations Made Easy and Fun",
          description: "AI-generated icebreaker messages help you start meaningful chats. Enjoy real-time chat with features like typing indicators, read receipts, and media sharing. Smart chat moderation powered by AI maintains a respectful space.",
          whyItMatters: "Break the ice, share moments, and create genuine connections with ease."
        },
        compatibility: {
          title: "AI Marriage Compatibility Tools",
          tagline: "Helping You Plan for a Meaningful Future",
          description: "Our Marriage Readiness Assessment evaluates shared goals, values, and long-term compatibility. Family-centric matching features align with Gulf traditions, while personalized relationship insights analyze compatibility in communication style and life goals.",
          whyItMatters: "Mahra.io goes beyond dating—our tools are designed to help you build a strong foundation for a lasting partnership."
        }
      }
    },
    joinWaitlist: {
      title: {
        en: "Join Our Growing Community",
        ar: "انضم إلى مجتمعنا المتنامي"
      },
      subtitle: {
        en: "Be part of the revolution in matchmaking. Join thousands of others who have already secured their spot.",
        ar: "كن جزءاً من ثورة التوفيق بين الأزواج. انضم إلى آلاف الأشخاص الذين حجزوا مكانهم بالفعل."
      },
      peopleJoined: {
        en: "People Joined",
        ar: "شخص انضم"
      },
      averageWait: {
        en: "Average Wait Time",
        ar: "متوسط وقت الانتظار"
      },
      dailySignups: {
        en: "Daily Sign-ups",
        ar: "التسجيلات اليومية"
      },
      sliderLabel: {
        en: "Watch our community grow in real-time",
        ar: "شاهد نمو مجتمعنا في الوقت الفعلي"
      },
      joinButton: {
        en: "Join Waitlist Now",
        ar: "انضم لقائمة الانتظار الآن"
      },
      matchPotential: {
        en: "Match Potential",
        ar: "احتمالية التوافق"
      },
      matchCount: {
        en: "Potential Matches",
        ar: "التطابقات المحتملة"
      },
      compatibility: {
        en: "Compatibility Score",
        ar: "درجة التوافق"
      },
      calculating: {
        en: "AI is analyzing...",
        ar: "الذكاء الاصطناعي يحلل..."
      },
      distance: {
        en: "Distance Range",
        ar: "نطاق المسافة"
      },
      ageRange: {
        en: "Age Preference",
        ar: "تفضيلات العمر"
      }
    },
    appPreview: {
      title: {
        en: "Experience the App",
        ar: "جرب التطبيق"
      },
      screens: {
        home: {
          en: "Home",
          ar: "الرئيسية"
        },
        search: {
          en: "Search",
          ar: "البحث"
        },
        chat: {
          en: "Chat",
          ar: "المحادثات"
        },
        profile: {
          en: "Profile",
          ar: "الملف"
        }
      }
    },
    contact: {
      sectionTitle: "Get in Touch",
      sectionSubtitle: "Have questions? We're here to help.",
      emailTitle: "Email Us",
      emailAddress: "Support@mahra.io",
      supportText: "Our support team is always ready to assist you",
    },
    footer: {
      company: {
        title: "Company",
        about: "About Us",
        careers: "Careers",
        press: "Press",
      },
      legal: {
        title: "Legal",
        privacy: "Privacy Policy",
        terms: "Terms of Service",
        cookies: "Cookie Policy",
      },
      support: {
        title: "Support",
        help: "Help Center",
        contact: "Contact Us",
        feedback: "Feedback",
      },
      copyright: "© 2024 Mahra",
      rights: "All rights reserved",
    },
    terms: {
      title: {
        en: 'Terms and Conditions',
        ar: 'الشروط والأحكام'
      },
      content: {
        en: '... English terms content ...',
        ar: '... Arabic terms content ...'
      },
      backButton: {
        en: 'Back',
        ar: 'رجوع'
      }
    }
  },
  ar: {
    header: {
      features: "المميزات",
      showcase: "المعرض",
      testimonials: "التوصيات",
      faq: "الأسئلة الشائعة",
      contact: "اتصل بنا",
      language: "English",
      downloadApp: "تحميل التطبيق",
      howItWorks: "الدليل"
    },
    hero: {
      title: "تطبيق التعارف بالذكاء الاصطناعي \nطريقك نحو الزواج",
      subtitle: "تعارف عصري لحياة عصرية",
      description: "اختبر طريقة جديدة لإيجاد علاقات هادفة من خلال نظام التوفيق المتقدم بالذكاء الاصطناعي",
      getStarted: "ابدأ الآن",
      readStories: "شاهد القصص",
      appPreview: "معاينة التطبيق قريباً"
    },
    features: {
      sectionTitle: "لماذا تختار مهرة",
      sectionSubtitle: "اختبر مستقبل المواعدة مع ميزاتنا المبتكرة المصممة لإنشاء علاقات هادفة.",
      features: {
        aiMatching: {
          title: "مطابقة بالذكاء الاصطناعي",
          description: "تتعلّم خوارزميتنا الذكية تفضيلاتك لإيجاد أفضل التطابقات.",
          fullDescription: "باستخدام خوارزميات التعلم الآلي المتقدمة، يحلل نظامنا سلوكك وتفضيلاتك وتفاعلاتك لتحسين توصيات المطابقة باستمرار."
        },
        security: {
          title: "آمن ومحمي",
          description: "أنظمة تشفير ومراجعة متقدمة تضمن سلامتك وخصوصيتك.",
          fullDescription: "نعتمد تشفيراً بمستوى المصارف وإجراءات تحقق صارمة ومراقبة مستمرة لحماية معلوماتك الشخصية."
        },
        smartChat: {
          title: "دردشة ذكية",
          description: "انخرط في محادثات عميقة مع كاسرات الجليد ومحفزات تعتمد على شخصيتك.",
          fullDescription: "يقترح نظام الدردشة الذكي بدايات للمحادثة بناءً على الاهتمامات المشتركة ويوفر ترجمة فورية للمطابقات الدولية."
        },
        verifiedProfiles: {
          title: "حسابات موثّقة",
          description: "يتم التحقق من جميع الحسابات بدقة لضمان مجتمع موثوق.",
          fullDescription: "تشمل عملية التحقق متعددةة الخطوات تأكيد الصور وربط وسائل التواصل الاجتماعي مع خيار التحقق بالهوية لمزيد من الثقة."
        },
        videoDating: {
          title: "مواعدة بالفيديو",
          description: "تواصل وجهاً لوجه مع المطابقات المحتملة عبر مكالمات فيديو آمنة.",
          fullDescription: "مكالمات فيديو مشفرة من الطرفين مع إمكانية تمويه الخلفية ومواعيد افتراضية مجدولة وخيارات دردشة جماعية."
        },
        compatibility: {
          title: "درجة التوافق",
          description: "اطّلع على مقاييس تفصيلية لتوافقك مع الآخرين.",
          fullDescription: "تحلل خوارزمية التوافق لدينا أكثر من 100 سمة شخصية وتفضيل لتقديم درجة توافق شاملة."
        },
        localEvents: {
          title: "الفعاليات المحلية",
          description: "اكتشف وانضم إلى فعاليات وأنشطة المواعدة في منطقتك.",
          fullDescription: "استعرض قائمة الفعاليات المحلية المنظّمة، من اللقاءات غير الرسمية إلى أنشطة المواعدة المخصصة."
        },
        smartFilters: {
          title: "فلاتر ذكية",
          description: "خيارات تصفية متقدمة للعثور بدقة على التطابقات الملائمة.",
          fullDescription: "خصّص بحثك من خلال فلاتر شاملة تشمل الاهتمامات ونمط الحياة والقيم والأهداف."
        }
      }
    },
    howItWorks: {
      sectionTitle: "كيف يعمل",
      sectionSubtitle: "ابدأ في بضع خطوات سهلة",
      steps: {
        profile: {
          title: "حمّل التطبيق وسجّل",
          description: "أنشئ حسابك خلال دقائق",
          longDescription:
            "انضم إلينا بتحميل التطبيق من متجرك المفضّل. أدخل بياناتك الأساسية وابدأ رحلتك نحو علاقات هادفة.",
          details: {
            photoVerification: {
              title: "التحقق من الصور",
              description: "اعرض صوراً حقيقية لتعزيز مصداقيتك"
            },
            interestSelection: {
              title: "تحديد الاهتمامات",
              description: "اختر هواياتك وميولك للعثور على أشخاص يشبهونك"
            },
            matchPreferences: {
              title: "معايير التطابق",
              description: "حدّد العمر والمسافة ونمط الحياة الذي تفضّله"
            },
            personalityQuiz: {
              title: "اختبار سريع",
              description: "أجب عن بضعة أسئلة للحصول على تطابقات أدق"
            },
            locationSettings: {
              title: "إعدادات الموقع",
              description: "اختر البحث محلياً أو عالمياً بما يناسبك"
            },
            profileReview: {
              title: "مراجعة الملف",
              description: "تحقق من بياناتك وميّز حسابك بشارة الثقة"
            }
          }
        },
        preferences: {
          title: "خصّص تجربتك",
          description: "ضبط إعداداتك لتحسين النتائج",
          longDescription:
            "حدّد أهدافك العاطفية وتفضيلاتك الثقافية. سيستخدم الذكاء الاصطناعي هذه المعلومات لتوفير تطابقات أكثر انسجاماً مع احتياجاتك.",
          details: {
            photoVerification: {
              title: "تأكيد الحساب",
              description: "ارفع صوراً واضحة لتحصل على شارة التحقق"
            },
            interestSelection: {
              title: "الهوايات والاهتمامات",
              description: "أضف المزيد من هواياتك لتحسين اقتراحات التطابق"
            },
            matchPreferences: {
              title: "تحديث المعايير",
              description: "عدّل العمر والموقع ونمط الحياة متى شئت"
            },
            personalityQuiz: {
              title: "اختبار شخصي قصير",
              description: "شارك بإجابات أكثر تفصيلاً لنتائج تطابق أفضل"
            },
            locationSettings: {
              title: "استكشاف المناطق",
              description: "اكتشف التطابقات المحتملة بقربك أو في أماكن أخرى"
            },
            profileReview: {
              title: "مراجعة نهائية",
              description: "تأكّد من أن كل شيء صحيح قبل أن تواصل"
            }
          }
        },
        matches: {
          title: "استكشف التطابقات",
          description: "اقتراحات مخصصة بالذكاء الاصطناعي",
          longDescription:
            "يراجع نظامنا ملفك واختبارك وتفضيلاتك ليقدّم تطابقات عالية الانسجام. تصفّح كل حساب للاطلاع على أبرز نقاط التوافق وتواصل بالوتيرة التي تناسبك.",
          details: {
            photoVerification: {
              title: "ملفات موثوقة",
              description: "جميع الحسابات تمر بمرحلة تحقق الصور لزيادة الثقة"
            },
            interestSelection: {
              title: "اهتمامات مشتركة",
              description: "اطّلع سريعاً على عدد الهوايات المتشابهة بينك وبينهم"
            },
            matchPreferences: {
              title: "خيارات الفرز",
              description: "فرز المطابقات وفق العمر أو المسافة أو القيم الشخصية"
            },
            personalityQuiz: {
              title: "تحليل الشخصية",
              description: "تعرّف على أسباب اقتراح كل حساب ومدى التوافق"
            },
            locationSettings: {
              title: "النطاق الجغرافي",
              description: "ابحث عن أشخاص قريبين أو وسّع نطاقك لمدن أخرى"
            },
            profileReview: {
              title: "تحديث مستمر",
              description: "تفقّد حسابك بانتظام لتحسين دقة واقتراحات التطابق"
            }
          }
        },
        chat: {
          title: "تواصل وتعرّف",
          description: "استمتع بمحادثات آمنة وهادفة",
          longDescription:
            "أرسل رسائل أو ملاحظات صوتية أو حدّد موعداً لمكالمة فيديو. استفد من كاسرات الجليد والترجمة الفورية لبناء علاقات حقيقية من أي مكان.",
          details: {
            photoVerification: {
              title: "تأكيد مرئي",
              description: "حدّث صورك باستمرار للحفاظ على حالة التحقق"
            },
            interestSelection: {
              title: "بدء المحادثات",
              description: "استخدم هواياتكم المشتركة لتسهيل كسر الجمود"
            },
            matchPreferences: {
              title: "ضوابط التواصل",
              description: "حدّد من يمكنه مراسلتك وفقاً لمعاييرك"
            },
            personalityQuiz: {
              title: "حوار موجّه",
              description: "اعتمد على نتائج الاختبار لخوض نقاشات أعمق"
            },
            locationSettings: {
              title: "مشاركة الموقع",
              description: "اختر إظهار موقعك في الوقت الفعلي أو إخفاءه"
            },
            profileReview: {
              title: "تحديث مستمر",
              description: "اجعل ملفك محدثاً لجذب التطابقات المناسبة باستمرار"
            }
          }
        }
      }
    },
    testimonials: {
      sectionTitle: "قصص النجاح",
      sectionSubtitle: "قصص حقيقية، علاقات حقيقية، وحب حقيقي",
      testimonials: {
        title: "اسمع من أزواجنا السعداء",
        subtitle: "انضم إلى آلاف المطابقات الناجحة",
        stories: [
          {
            name: "خالد ودانة",
            age: 30,
            location: "الرياض",
            story: "تعرفنا عبر اهتماماتنا المشتركة في رياضة الجري وحددنا موعداً بعد عدة محادثات. سرعان ما أدركنا أننا نتشارك نفس القيم والأهداف. تزوجنا قبل شهرين ونخطط حالياً لرحلة شهر العسل.",
            occupation: "موظف حكومي",
            matchDate: "تم الزواج قبل شهرين",
            rating: 5
          },
          {
            name: "رغد وعبد الله",
            age: 28,
            location: "جدة",
            story: "اكتشفنا حبنا المشترك للقراءة وفن الخط العربي. التقينا عدة مرات بحضور العائلة، وساعدنا التطبيق على اكتشاف مدى الانسجام بيننا. تزوجنا منذ أربعة أشهر ونشعر أن حياتنا اكتملت سوياً.",
            occupation: "طبيبة أسنان",
            matchDate: "تم الزواج قبل 4 أشهر",
            rating: 4
          },
          {
            name: "فهد وهديل",
            age: 26,
            location: "الدمام",
            story: "كنا نبحث عن شريك يحب السفر واستكشاف الأماكن الجديدة. ساعدتنا أسئلة التطبيق التفصيلية في فهم شخصياتنا بشكل أعمق. أقمنا زفاف عائلي بسيط الشهر الماضي، وكانت التجربة رائعة.",
            occupation: "معلم",
            matchDate: "تم الزواج قبل شهر",
            rating: 5
          },
          {
            name: "علي ولمى",
            age: 33,
            location: "الدوحة",
            story: "جمعنا شغف مشترك بالأعمال التطوعية وخدمة المجتمع. منذ رسائلنا الأولى شعرنا بأننا فريق يدعم بعضه. تزوجنا قبل شهر تقريباً، وكل الأمور تسير بشكل ممتاز.",
            occupation: "مهندس مدني",
            matchDate: "تم الزواج قبل شهر",
            rating: 4
          },
          {
            name: "حنان وطارق",
            age: 29,
            location: "أبو ظبي",
            story: "منذ البداية، أدركنا أننا نشترك في الكثير من القيم العائلية. بعد فترة تعارف قصيرة، أقمنا حفل زواج جميل. حياتنا الزوجية الآن مستقرة وسعيدة.",
            occupation: "محاسبة",
            matchDate: "تم الزواج قبل شهرين",
            rating: 5
          },
          {
            name: "نور وفيصل",
            age: 27,
            location: "المدينة المنورة",
            story: "جذبني التزامه بالعمل التطوعي ومساعدة الآخرين. اكتشفنا أن أهدافنا المستقبلية متطابقة إلى حد كبير. تزوجنا قبل ثلاثة أسابيع ونشعر بمدى الانسجام بين العائلتين.",
            occupation: "رائد أعمال",
            matchDate: "تم الزواج قبل 3 أسابيع",
            rating: 4
          }
        ]
      }
    },
    faq: {
      sectionTitle: "الأسئلة الشائعة",
      sectionSubtitle: "اعثر على إجابات لأكثر الأسئلة شيوعاً حول منصتنا",
      questions: [
        {
          title: "كيف يعمل نظام المطابقة بالذكاء الاصطناعي؟",
          answer: "يحلل نظام الذكاء الاصطناعي لدينا عوامل متعددة تشمل السمات الشخصية والاهتمامات والقيم وأنماط السلوك لإيجاد تطابقات عالية التوافق. يتعلم باستمرار ويتطور من خلال تفاعلات المستخدمين."
        },
        {
          title: "ما الذي يميز منصتنا؟",
          answer: "نحن نمزج بين تقنيات الذكاء الاصطناعي المتطورة والحساسية الثقافية، مع التركيز على العلاقات الجادة بدلاً من المواعدة السطحية. منصتنا مصممة خصيصاً لمن يبحثون عن شراكات حقيقية."
        },
        {
          title: "كيف تتحققون من الملفات الشخصية؟",
          answer: "نستخدم عملية تحقق متعددة المراحل تتضمن التحقق من رقم الهاتف، والتحقق من الصور بالذكاء الاصطناعي، والمراجعة اليدوية لضمان المصداقية والأمان."
        },
        {
          title: "هل خصوصيتي محمية؟",
          answer: "نعم، نطبق تشفيراً بمستوى المصارف مع سياسات خصوصية صارمة. لن نشارك معلوماتك الشخصية دون موافقتك، كما يمكنك التحكم الكامل في إعدادات الظهور."
        },
        {
          title: "كم تستغرق عملية التحقق؟",
          answer: "تستغرق عادةً ما بين 24 إلى 48 ساعة. نحرص على مراجعة كل ملف بدقة مع الحفاظ على السرعة لضمان تجربة عالية الجودة."
        },
        {
          title: "هل يمكنني التحكم في من يرى ملفي الشخصي؟",
          answer: "بالطبع! لديك السيطرة الكاملة على إعدادات الخصوصية في ملفك الشخصي، مما يتيح لك تحديد من يمكنه رؤية معلوماتك."
        },
        {
          title: "ماذا أفعل إذا احتجت للمساعدة؟",
          answer: "يتوفر فريق الدعم الخاص بنا على مدار الساعة طوال أيام الأسبوع. يمكنك التواصل معنا عبر التطبيق أو البريد الإلكتروني أو الهاتف لأي استفسارات أو مشكلات تواجهها."
        },
        {
          title: "هل توجد إعدادات لتفضيلات ثقافية؟",
          answer: "نعم، نقدّر أهمية التوافق الثقافي. يمكنك تحديد تفضيلاتك الثقافية لتلقي اقتراحات أكثر انسجاماً مع قيمك."
        },
        {
          title: "ما إجراءات السلامة التي تطبقونها؟",
          answer: "نعتمد عدة إجراءات تشمل الفحص بالذكاء الاصطناعي للملفات الشخصية، ومراقبة الدردشات في الوقت الفعلي، وفريق أمان مخصص يعمل على مدار الساعة. كما يمكن للمستخدمين الإبلاغ فوراً عن أي تصرفات غير لائقة."
        },
        {
          title: "هل يمكنني إيقاف ظهور ملفي الشخصي مؤقتاً؟",
          answer: "نعم، يمكنك تعليق ظهور ملفك الشخصي في أي وقت عبر إعدادات الخصوصية، مع الاحتفاظ بمحادثاتك وتطابقاتك."
        },
        {
          title: "كيف يعمل نظام المراسلة؟",
          answer: "يقدم نظام المراسلة لدينا مؤشرات الكتابة وإشعارات القراءة ومشاركة الوسائط، وجميع الرسائل مشفرة من الطرفين. كما يمكنك التحكم في من يستطيع مراسلتك عبر إعدادات الخصوصية."
        },
        {
          title: "ماذا يحدث بعد أن أتطابق مع شخص ما؟",
          answer: "بعد حدوث التطابق، يمكنك بدء محادثة عبر نظام المراسلة الآمن لدينا. سيقترح الذكاء الاصطناعي لدينا بعض المواضيع بناءً على اهتماماتكما المشتركة لكسر الجليد."
        }
      ]
    },
    appHighlights: {
      sectionTitle: "اختبر مستقبل العلاقات الهادفة",
      sectionSubtitle: "اكتشف كيف تساعدك ميزاتنا المبتكرة في العثور على شريك حياتك المثالي",
      features: {
        aiMatching: {
          title: "AI-Powered Matchmaking",
          tagline: "Smart, Accurate, and Personalized Matches",
          description: "Discover matches based on shared values, interests, and cultural preferences. Our advanced AI algorithms analyze user profiles for compatibility scoring, providing real-time recommendations tailored to your behavior and preferences.",
          whyItMatters: "Find meaningful connections effortlessly with technology that truly understands you."
        },
        security: {
          title: "Privacy & Security First",
          tagline: "Your Safety is Our Priority",
          description: "End-to-end encryption for all conversations ensures private and secure communication. Face verification tools ensure authenticity and build trust, while unique anonymity tools like image masking and incognito mode enable discreet browsing.",
          whyItMatters: "Feel secure and confident as you connect in a culturally respectful environment."
        },
        communication: {
          title: "Engaging Communication Tools",
          tagline: "Conversations Made Easy and Fun",
          description: "AI-generated icebreaker messages help you start meaningful chats. Enjoy real-time chat with features like typing indicators, read receipts, and media sharing. Smart chat moderation powered by AI maintains a respectful space.",
          whyItMatters: "Break the ice, share moments, and create genuine connections with ease."
        },
        compatibility: {
          title: "AI Marriage Compatibility Tools",
          tagline: "Helping You Plan for a Meaningful Future",
          description: "Our Marriage Readiness Assessment evaluates shared goals, values, and long-term compatibility. Family-centric matching features align with Gulf traditions, while personalized relationship insights analyze compatibility in communication style and life goals.",
          whyItMatters: "Mahra.io goes beyond dating—our tools are designed to help you build a strong foundation for a lasting partnership."
        }
      }
    },
    joinWaitlist: {
      title: {
        en: "Join Our Growing Community",
        ar: "انضم إلى مجتمعنا المتنامي"
      },
      subtitle: {
        en: "Be part of the revolution in matchmaking. Join thousands of others who have already secured their spot.",
        ar: "كن جزءاً من ثورة التوفيق بين الأزواج. انضم إلى آلاف الأشخاص الذين حجزوا مكانهم بالفعل."
      },
      peopleJoined: {
        en: "People Joined",
        ar: "شخص انضم"
      },
      averageWait: {
        en: "Average Wait Time",
        ar: "متوسط وقت الانتظار"
      },
      dailySignups: {
        en: "Daily Sign-ups",
        ar: "التسجيلات اليومية"
      },
      sliderLabel: {
        en: "Watch our community grow in real-time",
        ar: "شاهد نمو مجتمعنا في الوقت الفعلي"
      },
      joinButton: {
        en: "Join Waitlist Now",
        ar: "انضم لقائمة الانتظار الآن"
      },
      matchPotential: {
        en: "Match Potential",
        ar: "احتمالية التوافق"
      },
      matchCount: {
        en: "Potential Matches",
        ar: "التطابقات المحتملة"
      },
      compatibility: {
        en: "Compatibility Score",
        ar: "درجة التوافق"
      },
      calculating: {
        en: "AI is analyzing...",
        ar: "الذكاء الاصطناعي يحلل..."
      },
      distance: {
        en: "Distance Range",
        ar: "نطاق المسافة"
      },
      ageRange: {
        en: "Age Preference",
        ar: "تفضيلات العمر"
      }
    },
    appPreview: {
      title: {
        en: "Experience the App",
        ar: "جرب التطبيق"
      },
      screens: {
        home: {
          en: "Home",
          ar: "الرئيسية"
        },
        search: {
          en: "Search",
          ar: "البحث"
        },
        chat: {
          en: "Chat",
          ar: "المحادثات"
        },
        profile: {
          en: "Profile",
          ar: "الملف"
        }
      }
    },
    contact: {
      sectionTitle: "تواصل معنا",
      sectionSubtitle: "لديك أسئلة؟ نحن هنا للمساعدة",
      emailTitle: "راسلنا عبر البريد الإلكتروني",
      emailAddress: "Support@mahra.io",
      supportText: "فريق الدعم جاهز دائماً لمساعدتك"
    },
    footer: {
      company: {
        title: "الشركة",
        about: "من نحن",
        careers: "الوظائف",
        press: "الأخبار"
      },
      legal: {
        title: "القانونية",
        privacy: "سياسة الخصوصية",
        terms: "شروط الخدمة",
        cookies: "سياسة ملفات الارتباط"
      },
      support: {
        title: "الدعم",
        help: "مركز المساعدة",
        contact: "اتصل بنا",
        feedback: "آراء العملاء"
      },
      copyright: "© 2024 مهرة",
      rights: "جميع الحقوق محفوظة"
    },
    terms: {
      title: {
        en: 'الشروط والأحكام',
        ar: 'الشروط والأحكام'
      },
      content: {
        en: '... English terms content ...',
        ar: '... Arabic terms content ...'
      },
      backButton: {
        en: 'رجوع',
        ar: 'رجوع'
      }
    }
  }
}; 
