import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  GoogleAuthProvider, 
  signInWithPopup,
  UserCredential 
} from 'firebase/auth';
import { 
  getFirestore, 
  collection, 
  addDoc, 
  Timestamp 
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDVPnZ0sXX-8hwLEaHH7BG96wlgy4E8H4Q",
  authDomain: "mahra-54f8d.firebaseapp.com",
  projectId: "mahra-54f8d",
  storageBucket: "mahra-54f8d.firebasestorage.app",
  messagingSenderId: "940429744283",
  appId: "1:940429744283:web:a2dbbc49ffc44927600e3e",
  measurementId: "G-KE8CCP7KQK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Google Auth Provider
const googleProvider = new GoogleAuthProvider();

// Waitlist interface matching your schema
interface WaitlistEntry {
  email: string | null;
  phone?: string | null;
  provider: string;
  timestamp: Timestamp;
  name?: string | null;
  status: string;
  referral?: string | null;
  notes?: string | null;
}

// Enable Firebase logging in development
if (process.env.NODE_ENV === 'development') {
  console.log('Firebase Debug Mode Enabled');
}

// Function to handle Google Sign In
export const signInWithGoogle = async (): Promise<UserCredential> => {
  return signInWithPopup(auth, googleProvider);
};

// Function to add entry to waitlist
export const addToWaitlist = async (data: Partial<WaitlistEntry>) => {
  try {
    if (!db) throw new Error('Firebase is not initialized');
    
    console.log('Adding to waitlist:', data); // Debug log
    
    const waitlistRef = collection(db, 'waitlist');
    
    // Create entry object, removing undefined values
    const entry: Partial<WaitlistEntry> = {
      timestamp: Timestamp.now(),
      status: 'pending',
    };

    // Only add defined values
    if (data.email) entry.email = data.email;
    if (data.phone) entry.phone = data.phone;
    if (data.provider) entry.provider = data.provider;
    if (data.name) entry.name = data.name;
    if (data.referral) entry.referral = data.referral;
    if (data.notes) entry.notes = data.notes;

    console.log('Cleaned entry data:', entry); // Debug log

    const docRef = await addDoc(waitlistRef, entry);
    console.log('Document written with ID: ', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error('Error adding to waitlist:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to join waitlist: ${error.message}`);
    }
    throw new Error('Failed to join waitlist');
  }
};

export { auth, db }; 