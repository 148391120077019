import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Stack,
  useTheme,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../constants/translations';
import { GradientText } from '../GradientText';
import { Button } from '../Button';
import EmailIcon from '@mui/icons-material/Email';

interface FooterLink {
  label: string;
  href: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const Footer = () => {
  const theme = useTheme();
  const { language } = useLanguage();
  const t = translations[language].footer;
  const isRTL = language === 'ar';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const footerLinkStyle = {
    color: 'text.secondary',
    textDecoration: 'none',
    fontSize: '0.9rem',
    transition: 'all 0.2s ease',
    '&:hover': {
      color: 'primary.main',
    },
  };

  const FooterSection = ({ title, links }: { title: string; links: FooterLink[] }) => (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      alignItems: { xs: 'center', sm: 'flex-start' },
      width: '100%',
      ...(isRTL && {
        alignItems: { xs: 'center', sm: 'flex-start' },
        textAlign: 'right',
      }),
    }}>
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 600,
          mb: 2,
          color: 'text.primary',
          width: '100%',
          textAlign: { xs: 'center', sm: isRTL ? 'right' : 'left' },
        }}
      >
        {title}
      </Typography>
      <Stack 
        spacing={1.5}
        sx={{ 
          width: '100%',
          alignItems: { xs: 'center', sm: 'flex-start' },
          ...(isRTL && {
            alignItems: { xs: 'center', sm: 'flex-start' },
          }),
        }}
      >
        {links.map((link) => (
          <motion.div
            key={link.label}
            whileHover={{ x: isRTL ? -5 : 5 }}
            transition={{ duration: 0.2 }}
            style={{
              width: 'fit-content',
              alignSelf: isMobile ? 'center' : isRTL ? 'flex-start' : undefined,
            }}
          >
            <Link
              href={link.href}
              sx={{
                ...footerLinkStyle,
                display: 'block',
                textAlign: { xs: 'center', sm: isRTL ? 'right' : 'left' },
              }}
              onClick={link.onClick}
            >
              {link.label}
            </Link>
          </motion.div>
        ))}
      </Stack>
    </Box>
  );

  return (
    <Box
      component="footer"
      sx={{
        pt: { xs: 4, sm: 8 },
        pb: { xs: 3, sm: 6 },
        backgroundColor: 'background.paper',
        direction: isRTL ? 'rtl' : 'ltr',
      }}
    >
      <Container maxWidth="lg">
        {/* Main Content */}
        <Grid container spacing={{ xs: 3, sm: 8 }}>
          <Grid item xs={6} sm={4}>
            <FooterSection
              title={t.company.title}
              links={[
                { label: t.company.about, href: '#' },
                { label: t.company.careers, href: '#' },
                { label: t.company.press, href: '#' },
              ]}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FooterSection
              title={t.support.title}
              links={[
                { label: t.support.help, href: '#' },
                { label: t.support.contact, href: '#' },
                { label: t.support.feedback, href: '#' },
              ]}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: { xs: 3, sm: 4 }, opacity: 0.1 }} />

        {/* Bottom Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: { xs: 2, sm: 2 },
          }}
        >
          {/* Logo and Copyright in one row */}
          <Box 
            sx={{ 
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: 'flex-start' },
              gap: { xs: 1, sm: 2 },
              order: { xs: 2, sm: 1 },
              width: { xs: '100%', sm: 'auto' },
              ml: { xs: -4, sm: -5 },
            }}
          >
            <Box
              component="img"
              src="/images/logo/logo.png"
              alt="Mahra Logo"
              sx={{
                width: { xs: 120, sm: 120 },
                height: { xs: 120, sm: 120 },
                opacity: 0.9,
                order: isRTL ? 2 : 1,
                p: 0,
                mr: { xs: -2, sm: 0 },
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ 
                opacity: 0.7,
                textAlign: { xs: 'center', sm: isRTL ? 'right' : 'left' },
                order: isRTL ? 1 : 2,
                whiteSpace: 'nowrap',
                ml: { xs: -2, sm: -2 },
                transform: { xs: 'translateX(-12px)', sm: 'translateX(-8px)' },
              }}
            >
              {t.copyright} • {t.rights}
            </Typography>
          </Box>

          {/* Legal Links */}
          <Stack
            direction="row"
            spacing={3}
            sx={{
              order: { xs: 1, sm: 2 },
              justifyContent: 'center',
              width: '100%',
              mb: { xs: 1, sm: 0 },
            }}
          >
            {[
              { 
                label: t.legal.privacy, 
                path: language === 'ar' ? '/privacy-ar' : '/privacy'
              },
              { 
                label: isRTL ? 'الشروط والأحكام' : 'Terms of Service', 
                path: language === 'ar' ? '/terms-ar' : '/terms'
              },
              { 
                label: t.legal.cookies, 
                path: language === 'ar' ? '/cookies-ar' : '/cookies'
              }
            ].map((link) => (
              <Link
                key={link.label}
                href={link.path}
                sx={{
                  ...footerLinkStyle,
                  fontSize: '0.85rem',
                  opacity: 0.8,
                  '&:hover': {
                    opacity: 1,
                    color: 'primary.main',
                  },
                }}
                onClick={(e) => {
                  e.preventDefault();
                  window.history.pushState({ language }, '', link.path);
                  window.location.reload();
                }}
              >
                {link.label}
              </Link>
            ))}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}; 