import React from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';

interface ButtonProps extends Omit<MuiButtonProps, 'variant'> {
  variant?: 'primary' | 'secondary' | 'outline';
}

export const Button: React.FC<ButtonProps> = ({ 
  variant = 'primary',
  children,
  sx,
  ...props 
}) => {
  const getStyles = () => {
    switch (variant) {
      case 'primary':
        return {
          background: 'linear-gradient(45deg, #FF4B91 30%, #FF6B6B 90%)',
          color: 'white',
          boxShadow: '0 4px 20px rgba(255, 75, 145, 0.25)',
          '&:hover': {
            background: 'linear-gradient(45deg, #FF6B6B 30%, #FF4B91 90%)',
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 25px rgba(255, 75, 145, 0.35)',
          },
        };
      case 'secondary':
        return {
          backgroundColor: 'white',
          color: 'primary.main',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'white',
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 25px rgba(0, 0, 0, 0.12)',
          },
        };
      case 'outline':
        return {
          border: '2px solid',
          borderColor: 'white',
          color: 'white',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderColor: 'white',
            transform: 'translateY(-2px)',
          },
        };
      default:
        return {};
    }
  };

  return (
    <MuiButton
      {...props}
      sx={{
        textTransform: 'none',
        borderRadius: '100px',
        padding: '12px 24px',
        fontSize: '1rem',
        fontWeight: 600,
        transition: 'all 0.3s ease',
        ...getStyles(),
        ...sx,
      }}
    >
      {children}
    </MuiButton>
  );
}; 