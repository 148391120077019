import React, { useRef, useState, useCallback } from 'react';
import { Box, Container, Typography, Rating, Avatar, useTheme, IconButton, LinearProgress } from '@mui/material';
import { motion } from 'framer-motion';
import { SectionTitle } from '../SectionTitle';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../constants/translations';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { WaveSeparator } from '../WaveSeparator';

const TestimonialCard = ({ story, index, isActive }: any) => {
  const [expanded, setExpanded] = useState(false);
  const shortStory = story.story.slice(0, 100);

  return (
    <Box
      sx={{
        p: { xs: 2, sm: 3 },
        borderRadius: '24px',
        backgroundColor: 'background.paper',
        boxShadow: '0 4px 24px rgba(0, 0, 0, 0.06)',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        transition: 'all 0.3s ease',
        opacity: isActive ? 1 : 0.6,
        transform: isActive ? 'scale(1)' : 'scale(0.95)',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
          opacity: 1,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 2,
        }}
      >
        <Avatar
          src={`/images/testimonials/couple-${index + 1}.jpg`}
          sx={{ 
            width: { xs: 56, sm: 64 },
            height: { xs: 56, sm: 64 },
          }}
        />
        <Box>
          <Typography variant="h6" sx={{ 
            fontWeight: 600,
            fontSize: { xs: '1rem', sm: '1.25rem' },
          }}>
            {story.name}
          </Typography>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}
          >
            {story.location} • {story.age}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ flex: 1 }}>
        <Typography
          variant="body1"
          sx={{
            lineHeight: 1.8,
            fontSize: { xs: '0.875rem', sm: '1rem' },
            mb: 0.5,
          }}
        >
          {expanded ? story.story : shortStory}
          {!expanded && (
            <Box
              component="span"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 0.5,
                color: 'text.secondary',
                cursor: 'pointer',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
              onClick={() => setExpanded(true)}
            >
              ... <ExpandMoreIcon sx={{ fontSize: '1.1rem', mb: -0.3 }} />
            </Box>
          )}
        </Typography>
        
        {expanded && (
          <IconButton 
            onClick={() => setExpanded(false)}
            sx={{
              p: 0.5,
              color: 'text.secondary',
              display: 'block',
              ml: 'auto',
              mt: 0.5,
              '&:hover': {
                color: 'primary.main',
                background: 'rgba(255,75,145,0.05)',
              },
            }}
          >
            <ExpandLessIcon />
          </IconButton>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pt: 2,
          mt: 2,
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Typography 
          variant="caption" 
          color="text.secondary"
          sx={{ fontSize: { xs: '0.7rem', sm: '0.75rem' } }}
        >
          {story.matchDate}
        </Typography>
        <Rating
          value={story.rating}
          readOnly
          icon={<FavoriteIcon fontSize="small" color="primary" />}
          emptyIcon={<FavoriteBorderIcon fontSize="small" />}
          size="small"
        />
      </Box>
    </Box>
  );
};

export const Testimonials: React.FC = () => {
  const theme = useTheme();
  const { language } = useLanguage();
  const t = translations[language].testimonials;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const updateScrollProgress = useCallback(() => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      const progress = (Math.abs(scrollLeft) / (scrollWidth - clientWidth)) * 100;
      setScrollProgress(progress);
      
      // Update active index
      const itemWidth = 400; // Width of each testimonial card
      const currentIndex = Math.round(Math.abs(scrollLeft) / itemWidth);
      setActiveIndex(currentIndex);
    }
  }, []);

  const scroll = (direction: 'forward' | 'back') => {
    if (scrollContainerRef.current) {
      const isRTL = language === 'ar';
      const itemWidth = 400; // Width of each testimonial card
      const scrollValue = direction === 'forward' ? itemWidth : -itemWidth;
      const actualScroll = isRTL ? -scrollValue : scrollValue;

      scrollContainerRef.current.scrollBy({
        left: actualScroll,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Box
      component="section"
      sx={{
        py: { xs: 4, sm: 6, md: 8 },
        backgroundColor: theme.palette.background.default,
        position: 'relative',
        overflow: 'hidden',
        direction: language === 'ar' ? 'rtl' : 'ltr',
      }}
    >
      <WaveSeparator
        position="top"
        backgroundColor={theme.palette.primary.main}
        foregroundColor={theme.palette.background.default}
        pattern="curve"
        height={60}
        opacity={0.1}
      />

      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <SectionTitle
            title={t.sectionTitle}
            subtitle={t.sectionSubtitle}
            align="center"
          />
        </motion.div>

        <Box sx={{ 
          mt: { xs: 4, sm: 6 },
          position: 'relative' 
        }}>
          {/* Navigation Controls */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: { xs: 1, sm: 2 },
              mb: { xs: 3, sm: 4 },
              flexDirection: language === 'ar' ? 'row-reverse' : 'row',
              px: { xs: 2, sm: 0 },
            }}
          >
            <IconButton
              onClick={() => scroll(language === 'ar' ? 'forward' : 'back')}
              disabled={language === 'ar' 
                ? activeIndex === t.testimonials.stories.length - 1 
                : activeIndex === 0}
              sx={{
                backgroundColor: 'background.paper',
                boxShadow: theme.shadows[2],
                '&:hover': {
                  backgroundColor: 'background.paper',
                  transform: 'scale(1.1)',
                },
                transition: 'transform 0.3s ease',
              }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>

            {/* Progress Indicator */}
            <Box sx={{ flex: 1, maxWidth: 400 }}>
              <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                  height: 6,
                  borderRadius: 3,
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  transform: language === 'ar' ? 'scaleX(-1)' : 'none',
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 3,
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
              />
            </Box>

            <IconButton
              onClick={() => scroll(language === 'ar' ? 'back' : 'forward')}
              disabled={language === 'ar' 
                ? activeIndex === 0 
                : activeIndex === t.testimonials.stories.length - 1}
              sx={{
                backgroundColor: 'background.paper',
                boxShadow: theme.shadows[2],
                '&:hover': {
                  backgroundColor: 'background.paper',
                  transform: 'scale(1.1)',
                },
                transition: 'transform 0.3s ease',
              }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>

          <Box
            ref={scrollContainerRef}
            onScroll={updateScrollProgress}
            sx={{
              display: 'flex',
              gap: { xs: 2, sm: 3 },
              overflowX: 'auto',
              scrollBehavior: 'smooth',
              direction: language === 'ar' ? 'rtl' : 'ltr',
              '&::-webkit-scrollbar': { display: 'none' },
              scrollbarWidth: 'none',
              px: { xs: 2, sm: 1 },
              py: 2,
              mx: 'auto',
              maxWidth: '100%',
            }}
          >
            {t.testimonials.stories.map((story, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                style={{
                  flex: '0 0 auto',
                  width: '400px',
                  maxWidth: '85vw',
                }}
              >
                <TestimonialCard 
                  story={story} 
                  index={index} 
                  isActive={activeIndex === index}
                />
              </motion.div>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Testimonials; 