import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Add preconnect hints for external resources
const preconnectLinks = [
  'https://fonts.googleapis.com',
  'https://fonts.gstatic.com'
];

preconnectLinks.forEach(href => {
  const link = document.createElement('link');
  link.rel = 'preconnect';
  link.href = href;
  document.head.appendChild(link);
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
); 