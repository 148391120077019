import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  useTheme,
  Stack,
  Slider,
  Chip,
  Tooltip,
  Paper,
  CircularProgress,
  Grid,
  SvgIcon,
  SvgIconProps,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../Button';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../constants/translations';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PeopleIcon from '@mui/icons-material/People';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SchoolIcon from '@mui/icons-material/School';
import { WaitlistModal } from '../WaitlistModal/WaitlistModal';

interface JoinWaitlistProps {
  onJoinWaitlist?: () => void;
}

interface MatchMetric {
  icon: typeof SvgIcon;
  label: string;
  value: number;
  suffix: string;
}

export const JoinWaitlist: React.FC<JoinWaitlistProps> = ({ onJoinWaitlist }) => {
  const theme = useTheme();
  const { language } = useLanguage();
  const t = translations[language].joinWaitlist;
  const isRTL = language === 'ar';
  
  const [distance, setDistance] = useState<number>(30);
  const [ageRange, setAgeRange] = useState<number[]>([25, 35]);
  const [isCalculating, setIsCalculating] = useState(false);
  const [metrics, setMetrics] = useState({
    matchPotential: 75,
    matchCount: 120,
    compatibility: 85,
  });
  const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);

  // Simulate AI calculation
  useEffect(() => {
    setIsCalculating(true);
    const timer = setTimeout(() => {
      const distanceImpact = Math.max(0, 30 - Math.abs(30 - distance)) / 2;
      const ageImpact = Math.abs(ageRange[1] - ageRange[0]) < 10 ? 5 : 0;
      
      setMetrics({
        matchPotential: Math.min(100, 75 + distanceImpact + ageImpact),
        matchCount: Math.floor(120 * (distance / 30)),
        compatibility: Math.min(100, 85 + distanceImpact),
      });
      setIsCalculating(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [distance, ageRange]);

  const matchMetrics: MatchMetric[] = [
    { icon: AutoGraphIcon, label: t.matchPotential[language], value: metrics.matchPotential, suffix: "%" },
    { icon: PeopleIcon, label: t.matchCount[language], value: metrics.matchCount, suffix: "" },
    { icon: FavoriteIcon, label: t.compatibility[language], value: metrics.compatibility, suffix: "%" },
  ];

  const handleJoin = () => {
    if (onJoinWaitlist) {
      onJoinWaitlist();
    }
  };

  return (
    <Box
      component="section"
      sx={{
        py: { xs: 4, md: 8 },
        background: `linear-gradient(135deg, ${theme.palette.primary.main}15 0%, ${theme.palette.secondary.main}15 100%)`,
        position: 'relative',
        overflow: 'hidden',
        direction: isRTL ? 'rtl' : 'ltr',
      }}
    >
      <Container maxWidth="lg">
        <Stack 
          spacing={{ xs: 3, md: 4 }}
          alignItems="center"
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            style={{ width: '100%' }}
          >
            <Typography
              variant="h2"
              align="center"
              sx={{
                fontWeight: 700,
                mb: 2,
                fontSize: { xs: '2rem', md: '3rem' },
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {t.title[language]}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="text.secondary"
              sx={{ 
                maxWidth: '600px', 
                mx: 'auto', 
                mb: { xs: 2, md: 4 },
                fontSize: { xs: '1rem', md: '1.25rem' },
              }}
            >
              {t.subtitle[language]}
            </Typography>
          </motion.div>

          <Paper
            elevation={0}
            sx={{
              maxWidth: { xs: '100%', sm: 800 },
              width: '100%',
              p: { xs: 3, sm: 4 },
              borderRadius: { xs: '16px', md: '24px' },
              background: 'rgba(255, 255, 255, 0.95)',
              backdropFilter: 'blur(10px)',
              position: 'relative',
              overflow: 'hidden',
              boxShadow: '0 4px 24px rgba(0, 0, 0, 0.05)',
              mx: { xs: 2, sm: 'auto' },
            }}
          >
            {/* AI Animation Overlay */}
            <AnimatePresence>
              {isCalculating && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(255, 255, 255, 0.8)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1,
                  }}
                >
                  <Stack spacing={2} alignItems="center">
                    <SmartToyIcon
                      sx={{
                        fontSize: 40,
                        color: theme.palette.primary.main,
                        animation: 'pulse 1.5s infinite',
                      }}
                    />
                    <Typography>{t.calculating[language]}</Typography>
                  </Stack>
                </motion.div>
              )}
            </AnimatePresence>

            <Stack spacing={{ xs: 3, md: 3 }}>
              {/* Match Metrics */}
              <Stack spacing={2}>
                {matchMetrics.map((metric, index) => (
                  <Paper
                    key={index}
                    elevation={0}
                    sx={{
                      p: { xs: 3, md: 2 },
                      textAlign: 'center',
                      borderRadius: '16px',
                      border: '1px solid',
                      borderColor: 'divider',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      transition: 'transform 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                      },
                    }}
                  >
                    <metric.icon 
                      sx={{ 
                        color: theme.palette.primary.main, 
                        mb: 1,
                        fontSize: { xs: 32, md: 32 },
                      }} 
                    />
                    <Typography 
                      variant="h4" 
                      fontWeight="bold" 
                      gutterBottom
                      sx={{
                        fontSize: { xs: '2rem', md: '2rem' },
                      }}
                    >
                      {metric.value}{metric.suffix}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{
                        fontSize: { xs: '1rem', md: '1rem' },
                      }}
                    >
                      {metric.label}
                    </Typography>
                  </Paper>
                ))}
              </Stack>

              {/* Preference Controls */}
              <Stack 
                spacing={3}
                sx={{
                  px: { xs: 0, md: 2 },
                  py: { xs: 2, md: 2 },
                }}
              >
                <Box>
                  <Typography 
                    variant="body1" 
                    gutterBottom
                    sx={{ 
                      fontWeight: 500,
                      mb: 2,
                      color: 'text.primary',
                    }}
                  >
                    {t.distance[language]}
                  </Typography>
                  <Slider
                    value={distance}
                    onChange={(_, value) => setDistance(value as number)}
                    min={5}
                    max={100}
                    valueLabelDisplay="auto"
                    valueLabelFormat={value => `${value}km`}
                    sx={{
                      color: theme.palette.primary.main,
                      '& .MuiSlider-thumb': {
                        width: 24,
                        height: 24,
                      },
                      '& .MuiSlider-track': {
                        height: 6,
                      },
                      '& .MuiSlider-rail': {
                        height: 6,
                      },
                    }}
                  />
                </Box>

                <Box>
                  <Typography 
                    variant="body1" 
                    gutterBottom
                    sx={{ 
                      fontWeight: 500,
                      mb: 2,
                      color: 'text.primary',
                    }}
                  >
                    {t.ageRange[language]}
                  </Typography>
                  <Slider
                    value={ageRange}
                    onChange={(_, value) => setAgeRange(value as number[])}
                    min={18}
                    max={60}
                    valueLabelDisplay="auto"
                    sx={{
                      color: theme.palette.primary.main,
                      '& .MuiSlider-thumb': {
                        width: 24,
                        height: 24,
                      },
                      '& .MuiSlider-track': {
                        height: 6,
                      },
                      '& .MuiSlider-rail': {
                        height: 6,
                      },
                    }}
                  />
                </Box>
              </Stack>

              <Button
                variant="primary"
                size="large"
                onClick={() => setIsWaitlistOpen(true)}
                sx={{
                  mt: { xs: 2, md: 2 },
                  py: 2,
                  borderRadius: '12px',
                  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
                  fontSize: '1.125rem',
                  fontWeight: 600,
                }}
              >
                {language === 'ar' ? t.joinButton.ar : t.joinButton.en}
              </Button>
            </Stack>
          </Paper>
        </Stack>
      </Container>
      
      <WaitlistModal
        open={isWaitlistOpen}
        onClose={() => setIsWaitlistOpen(false)}
      />
    </Box>
  );
}; 