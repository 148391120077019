import { termsContentEn } from './content/terms-en';
import { termsContentAr } from './content/terms-ar';

interface TermsContentType {
  en: {
    en: string;
    ar: string;
  };
  ar: {
    en: string;
    ar: string;
  };
}

export const termsContent: TermsContentType = {
  en: {
    en: termsContentEn,
    ar: termsContentAr
  },
  ar: {
    en: termsContentEn,
    ar: termsContentAr
  }
}; 