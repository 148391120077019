import React, { ReactNode } from 'react';
import { Box, Typography, SxProps } from '@mui/material';

interface SectionTitleProps {
  title: string;
  subtitle?: string | ReactNode;
  align?: 'left' | 'center' | 'right';
  sx?: SxProps;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  subtitle,
  align = 'left',
  sx
}) => {
  return (
    <Box sx={{ mb: 6, ...sx }}>
      <Typography
        variant="h2"
        sx={{
          fontSize: { xs: '2rem', sm: '2.5rem' },
          fontWeight: 700,
          mb: 2,
          textAlign: align,
        }}
      >
        {title}
      </Typography>
      {typeof subtitle === 'string' ? (
        <Typography 
          variant="body1" 
          color="text.secondary"
          sx={{
            textAlign: align,
            maxWidth: '800px',
            mx: align === 'center' ? 'auto' : 'initial',
          }}
        >
          {subtitle}
        </Typography>
      ) : (
        subtitle
      )}
    </Box>
  );
}; 