import React, { useEffect, useRef, useCallback } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

interface Particle {
  x: number;
  y: number;
  vx: number;
  vy: number;
  radius: number;
}

interface ParticlesBackgroundProps {
  count?: number;
  speed?: number;
}

export const ParticlesBackground: React.FC<ParticlesBackgroundProps> = ({ 
  count = 30,
  speed = 0.5 
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const particlesRef = useRef<Particle[]>([]);
  const animationFrameRef = useRef<number>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const createParticles = useCallback((width: number, height: number) => {
    const particles: Particle[] = [];
    for (let i = 0; i < count; i++) {
      particles.push({
        x: Math.random() * width,
        y: Math.random() * height,
        vx: (Math.random() - 0.5) * speed,
        vy: (Math.random() - 0.5) * speed,
        radius: Math.random() * 2 + 1,
      });
    }
    return particles;
  }, [count, speed]);

  const updateParticle = useCallback((
    particle: Particle, 
    width: number, 
    height: number,
    isReducedMotion: boolean
  ) => {
    // Reduce calculations on mobile
    if (isReducedMotion) {
      particle.x += particle.vx * 0.5;
      particle.y += particle.vy * 0.5;
      
      // Simpler boundary checking
      if (particle.x < 0 || particle.x > width) particle.vx *= -1;
      if (particle.y < 0 || particle.y > height) particle.vy *= -1;
      return;
    }

    // Full animation for desktop
    particle.x += particle.vx;
    particle.y += particle.vy;
    
    // More complex boundary behavior for desktop
    if (particle.x < 0) {
      particle.x = width;
    } else if (particle.x > width) {
      particle.x = 0;
    }
    
    if (particle.y < 0) {
      particle.y = height;
    } else if (particle.y > height) {
      particle.y = 0;
    }
  }, []);

  const draw = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const width = canvas.width;
    const height = canvas.height;

    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';

    particlesRef.current.forEach(particle => {
      updateParticle(particle, width, height, isMobile);
      
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
      ctx.fill();
    });

    animationFrameRef.current = requestAnimationFrame(draw);
  }, [updateParticle, isMobile]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      particlesRef.current = createParticles(canvas.width, canvas.height);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    animationFrameRef.current = requestAnimationFrame(draw);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [createParticles, draw]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: 1,
      }}
    />
  );
}; 