import { privacyContentEn } from './content/privacy-en';
import { privacyContentAr } from './content/privacy-ar';

interface PrivacyContentType {
  en: {
    en: string;
    ar: string;
  };
  ar: {
    en: string;
    ar: string;
  };
}

export const privacyContent: PrivacyContentType = {
  en: {
    en: privacyContentEn,
    ar: privacyContentAr
  },
  ar: {
    en: privacyContentEn,
    ar: privacyContentAr
  }
}; 